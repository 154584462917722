<template>
  <div class="WinBox">

    <NAV :title="rootInfo.lesson" @closeLive="closeLive"></NAV>
    <div class="home">
      <div class="Live-menu">
        <div class="menuLi" @click="SelectCamera" :class="SelectMenu == 1 ? 'checkMenu' : ''">
          <button class="topbar-right-btn" title="摄像头">
            <!-- <svg v-if="SelectMenu == 1"   xmlns="http://www.w3.org/2000/svg" width="41" height="52.212" viewBox="0 0 41 52.212">
            <path id="实物-摄像头" d="M31.269,51.315h7.447a1.907,1.907,0,1,1,.086,3.719.134.134,0,0,1-.086,0H20.094a1.907,1.907,0,0,1,0-3.719H27.54V43.773a20.5,20.5,0,1,1,3.728,0ZM29.409,40.179A16.771,16.771,0,1,0,12.628,23.4,16.771,16.771,0,0,0,29.409,40.179Zm0-5.6a11.2,11.2,0,1,0,0-.048Zm0-3.719a7.456,7.456,0,1,0-7.456-7.456h0a7.456,7.456,0,0,0,7.456,7.408Z" transform="translate(-8.905 -2.859)" fill="#0076ff"/>
          </svg> -->
            <svg v-if="SelectMenu == 1" xmlns="http://www.w3.org/2000/svg" width="704" height="896"
              viewBox="0 0 704 896">
              <path id="实物-摄像头"
                d="M544,896H671.936a32,32,0,0,1,0,64H352.064a32,32,0,0,1,0-64H480V766.56C300.576,750.4,160,599.616,160,416,160,221.6,317.6,64,512,64S864,221.6,864,416c0,183.616-140.576,334.4-320,350.56ZM512,704c159.058,0,288-128.942,288-288S671.058,128,512,128,224,256.942,224,416,352.942,704,512,704Zm0-96c-106.039,0-192-85.961-192-192s85.961-192,192-192,192,85.961,192,192S618.039,608,512,608Zm0-64A128,128,0,1,0,384,416,128,128,0,0,0,512,544Z"
                transform="translate(-160 -64)" fill="#0076ff" />
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" width="704" height="896" viewBox="0 0 704 896">
              <path id="实物-摄像头"
                d="M544,896H671.936a32,32,0,0,1,0,64H352.064a32,32,0,0,1,0-64H480V766.56C300.576,750.4,160,599.616,160,416,160,221.6,317.6,64,512,64S864,221.6,864,416c0,183.616-140.576,334.4-320,350.56ZM512,704c159.058,0,288-128.942,288-288S671.058,128,512,128,224,256.942,224,416,352.942,704,512,704Zm0-96c-106.039,0-192-85.961-192-192s85.961-192,192-192,192,85.961,192,192S618.039,608,512,608Zm0-64A128,128,0,1,0,384,416,128,128,0,0,0,512,544Z"
                transform="translate(-160 -64)" fill="#999" />
            </svg>
          </button>
          <p style="font-size: 12px">摄像头</p>
        </div>
        <div class="menuLi" @click="checkKJ" :class="SelectMenu == 2 ? 'checkMenu' : ''">
          <button class="topbar-right-btn" title="课件">
            <svg id="文件夹" v-if="SelectMenu !== 2" xmlns="http://www.w3.org/2000/svg" width="41" height="42.783"
              viewBox="0 0 41 42.783">
              <path id="文件"
                d="M42.728,19.408V14.732a8.81,8.81,0,0,0-8.794-8.794h-12.8l-.38-.786A1.327,1.327,0,0,0,19.56,4.4H13.971A9.588,9.588,0,0,0,4.4,13.971V36.8a9.588,9.588,0,0,0,9.571,9.538H34.983a9.588,9.588,0,0,0,9.571-9.571V21.759a2.427,2.427,0,0,0-1.826-2.351ZM33.934,8.594a6.139,6.139,0,0,1,6.13,6.139v4.591H27.6L22.427,8.594Zm7.956,28.173a6.908,6.908,0,0,1-6.908,6.908H13.971a6.908,6.908,0,0,1-6.908-6.908V13.938A6.908,6.908,0,0,1,13.971,7.03h4.76L25.572,21.2a1.336,1.336,0,0,0,1.2.753H41.9V36.767Z"
                transform="translate(-3.977 -3.977)" fill="#999" />
              <path id="路径_38" data-name="路径 38"
                d="M34.906,46.684H13.894A10.028,10.028,0,0,1,3.9,36.69V13.86A10.028,10.028,0,0,1,13.894,3.9h5.589a1.742,1.742,0,0,1,1.573,1l.271.541H33.857a9.25,9.25,0,0,1,9.216,9.216v4.363A2.866,2.866,0,0,1,44.9,21.682V36.69a10.028,10.028,0,0,1-9.994,9.994ZM13.894,4.746a9.174,9.174,0,0,0-9.149,9.149V36.723a9.174,9.174,0,0,0,9.149,9.115H34.906a9.174,9.174,0,0,0,9.149-9.149V21.682a2.012,2.012,0,0,0-1.514-1.945l-.313-.085v-5a8.4,8.4,0,0,0-8.379-8.371H20.794l-.5-1.023a.9.9,0,0,0-.846-.516ZM34.906,44.02H13.894A7.339,7.339,0,0,1,6.563,36.69V13.86A7.339,7.339,0,0,1,13.894,6.53h5.073L25.918,20.9a.922.922,0,0,0,.846.516H42.245V37.713l-.068-.068A7.348,7.348,0,0,1,34.906,44.02ZM13.894,7.409a6.5,6.5,0,0,0-6.485,6.485V36.723a6.5,6.5,0,0,0,6.485,6.485H34.906a6.5,6.5,0,0,0,6.485-6.485V35.667h0V22.333h-14.7a1.742,1.742,0,0,1-1.564-1L18.359,7.409ZM40.41,19.669H27.262L21.673,8.094H33.857a6.578,6.578,0,0,1,6.553,6.561Zm-12.615-.846h11.77V14.655a5.733,5.733,0,0,0-5.707-5.716H23.017Z"
                transform="translate(-3.9 -3.9)" fill="#999" />
            </svg>
            <svg id="文件夹" v-else xmlns="http://www.w3.org/2000/svg" width="41" height="42.783" viewBox="0 0 41 42.783">
              <path id="文件"
                d="M42.728,19.408V14.732a8.81,8.81,0,0,0-8.794-8.794h-12.8l-.38-.786A1.327,1.327,0,0,0,19.56,4.4H13.971A9.588,9.588,0,0,0,4.4,13.971V36.8a9.588,9.588,0,0,0,9.571,9.538H34.983a9.588,9.588,0,0,0,9.571-9.571V21.759a2.427,2.427,0,0,0-1.826-2.351ZM33.934,8.594a6.139,6.139,0,0,1,6.13,6.139v4.591H27.6L22.427,8.594Zm7.956,28.173a6.908,6.908,0,0,1-6.908,6.908H13.971a6.908,6.908,0,0,1-6.908-6.908V13.938A6.908,6.908,0,0,1,13.971,7.03h4.76L25.572,21.2a1.336,1.336,0,0,0,1.2.753H41.9V36.767Z"
                transform="translate(-3.977 -3.977)" fill="#0076ff" />
              <path id="路径_38" data-name="路径 38"
                d="M34.906,46.684H13.894A10.028,10.028,0,0,1,3.9,36.69V13.86A10.028,10.028,0,0,1,13.894,3.9h5.589a1.742,1.742,0,0,1,1.573,1l.271.541H33.857a9.25,9.25,0,0,1,9.216,9.216v4.363A2.866,2.866,0,0,1,44.9,21.682V36.69a10.028,10.028,0,0,1-9.994,9.994ZM13.894,4.746a9.174,9.174,0,0,0-9.149,9.149V36.723a9.174,9.174,0,0,0,9.149,9.115H34.906a9.174,9.174,0,0,0,9.149-9.149V21.682a2.012,2.012,0,0,0-1.514-1.945l-.313-.085v-5a8.4,8.4,0,0,0-8.379-8.371H20.794l-.5-1.023a.9.9,0,0,0-.846-.516ZM34.906,44.02H13.894A7.339,7.339,0,0,1,6.563,36.69V13.86A7.339,7.339,0,0,1,13.894,6.53h5.073L25.918,20.9a.922.922,0,0,0,.846.516H42.245V37.713l-.068-.068A7.348,7.348,0,0,1,34.906,44.02ZM13.894,7.409a6.5,6.5,0,0,0-6.485,6.485V36.723a6.5,6.5,0,0,0,6.485,6.485H34.906a6.5,6.5,0,0,0,6.485-6.485V35.667h0V22.333h-14.7a1.742,1.742,0,0,1-1.564-1L18.359,7.409ZM40.41,19.669H27.262L21.673,8.094H33.857a6.578,6.578,0,0,1,6.553,6.561Zm-12.615-.846h11.77V14.655a5.733,5.733,0,0,0-5.707-5.716H23.017Z"
                transform="translate(-3.9 -3.9)" fill="#0076ff" />
            </svg>
          </button>
          <p style="font-size: 12px">课件</p>
        </div>
        <div class="menuLi" @click="ipcShareDP" :class="SelectMenu == 3 ? 'checkMenu' : ''">
          <button class="topbar-right-btn" title="屏幕共享">
            <svg v-if="SelectMenu !== 3" xmlns="http://www.w3.org/2000/svg" width="45.261" height="40.722"
              viewBox="0 0 45.261 40.722">
              <g id="视频" transform="translate(0.02)">
                <path id="路径_40" data-name="路径 40"
                  d="M10.928,41.459A8.221,8.221,0,0,1,2.71,33.226V12.81a8.239,8.239,0,0,1,8.23-8.23H39.732a8.25,8.25,0,0,1,8.238,8.227V33.179a8.23,8.23,0,0,1-8.241,8.28H37.342a2,2,0,1,1,0-4h2.39A4.23,4.23,0,0,0,43.971,33.2V12.81a4.244,4.244,0,0,0-4.238-4.23H10.94a4.234,4.234,0,0,0-4.23,4.23V33.229a4.221,4.221,0,0,0,4.227,4.23h1.936a2,2,0,0,1,0,4H10.928Z"
                  transform="translate(-2.73 -4.58)" fill="#999" />
                <path id="路径_41" data-name="路径 41"
                  d="M28.4,37.811a2.082,2.082,0,0,1,.246.237l6.8,7.908a1.863,1.863,0,0,1-.193,2.636,1.907,1.907,0,0,1-1.213.439H19.871A1.845,1.845,0,0,1,18,47.194a1.9,1.9,0,0,1,.439-1.2l6.809-7.908a2.223,2.223,0,0,1,3.137-.237Z"
                  transform="translate(-4.343 -8.309)" fill="#999" />
              </g>
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" width="45.261" height="40.722" viewBox="0 0 45.261 40.722">
              <g id="视频" transform="translate(0.02)">
                <path id="路径_40" data-name="路径 40"
                  d="M10.928,41.459A8.221,8.221,0,0,1,2.71,33.226V12.81a8.239,8.239,0,0,1,8.23-8.23H39.732a8.25,8.25,0,0,1,8.238,8.227V33.179a8.23,8.23,0,0,1-8.241,8.28H37.342a2,2,0,1,1,0-4h2.39A4.23,4.23,0,0,0,43.971,33.2V12.81a4.244,4.244,0,0,0-4.238-4.23H10.94a4.234,4.234,0,0,0-4.23,4.23V33.229a4.221,4.221,0,0,0,4.227,4.23h1.936a2,2,0,0,1,0,4H10.928Z"
                  transform="translate(-2.73 -4.58)" fill="#0076ff" />
                <path id="路径_41" data-name="路径 41"
                  d="M28.4,37.811a2.082,2.082,0,0,1,.246.237l6.8,7.908a1.863,1.863,0,0,1-.193,2.636,1.907,1.907,0,0,1-1.213.439H19.871A1.845,1.845,0,0,1,18,47.194a1.9,1.9,0,0,1,.439-1.2l6.809-7.908a2.223,2.223,0,0,1,3.137-.237Z"
                  transform="translate(-4.343 -8.309)" fill="#0076ff" />
              </g>
            </svg>
          </button>
          <p style="font-size: 12px">屏幕共享</p>
        </div>

      </div>
     
      <div class="videoArea" id="videoArea" ref="videoArea">
        <!-- <div v-if="SelectMenu == 1" class="wraper" id="CameraHome">
          <div v-if="!CameraStatus || !ExistCamera" id="CameraOff" class="Blur">
            <svg t="1684380215107" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="2623" width="200" height="200">
              <path
                d="M836.266667 315.733333c-21.333333 4.266667-38.4 25.6-34.133334 51.2 8.533333 17.066667 8.533333 38.4 8.533334 59.733334 0 166.4-132.266667 298.666667-298.666667 298.666666-21.333333 0-38.4 0-59.733333-4.266666-21.333333-4.266667-46.933333 8.533333-51.2 34.133333-4.266667 21.333333 8.533333 46.933333 34.133333 51.2 12.8 4.266667 21.333333 4.266667 34.133333 4.266667v85.333333H320c-25.6 0-42.666667 17.066667-42.666667 42.666667s17.066667 42.666667 42.666667 42.666666h384c25.6 0 42.666667-17.066667 42.666667-42.666666s-17.066667-42.666667-42.666667-42.666667H554.666667v-89.6c192-21.333333 341.333333-183.466667 341.333333-379.733333 0-25.6-4.266667-51.2-8.533333-76.8-4.266667-25.6-25.6-38.4-51.2-34.133334zM230.4 661.333333c8.533333 0 17.066667-4.266667 25.6-8.533333 21.333333-12.8 25.6-38.4 12.8-59.733333C230.4 546.133333 213.333333 486.4 213.333333 426.666667c0-166.4 132.266667-298.666667 298.666667-298.666667 59.733333 0 115.2 17.066667 166.4 51.2 21.333333 12.8 46.933333 8.533333 59.733333-12.8 12.8-21.333333 8.533333-46.933333-12.8-59.733333-64-42.666667-136.533333-64-213.333333-64-213.333333 0-384 170.666667-384 384 0 76.8 21.333333 149.333333 64 213.333333 8.533333 12.8 21.333333 21.333333 38.4 21.333333z"
                p-id="2624"></path>
              <path
                d="M529.066667 341.333333c21.333333 4.266667 46.933333-8.533333 51.2-34.133333 4.266667-21.333333-8.533333-46.933333-34.133334-51.2H512c-93.866667 0-170.666667 76.8-170.666667 170.666667 0 12.8 0 25.6 4.266667 38.4 4.266667 21.333333 21.333333 34.133333 42.666667 34.133333h8.533333c21.333333-4.266667 38.4-29.866667 34.133333-51.2 0-8.533333-4.266667-12.8-4.266666-21.333333 0-51.2 51.2-93.866667 102.4-85.333334zM968.533333 55.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0l-853.333333 853.333333c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 17.066667 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8l853.333333-853.333333c17.066667-17.066667 17.066667-42.666667 0-59.733333z"
                p-id="2625"></path>
            </svg>
            <p style="color: #c6c6c6;">无可用视频输入设备，请选择课件或屏幕共享。</p>
            <p></p>
          </div>
        </div>       -->
        <!-- 画板 -->
        <div class="wraper" ref="wraper" v-show="CoursewareCount > 0 && SelectMenu == 2">
         
          <div class="controlPanel">
            <div :class="[initIdx == idx ? 'contro-item active' : 'contro-item']" v-for="(item, idx) in toolsArr"
              :key="idx" @click="handleTools(item, idx)"
              @mouseenter="addHoverEffect(idx,item)"
              @mouseleave="removeHoverEffect()"
              >
              <img :src="item.icon" :title="item.tip"/>
            </div>
            <el-color-picker v-show="SelectMenu == 2 && CoursewareCount > 0" class="colorPicker" @change="changeColor"
              v-model="drawColor" size="mini"></el-color-picker>
              <div class="controlPanelSize" @mouseenter="addHoverEffect" :style="{ top: PanelTop }" v-show="PanelSizeHover">
              <div @click="changePliceSize(2)" class="SizeBox">
                <div class="s10 size" ></div>
              </div>
              <div  @click="changePliceSize(4)" class="SizeBox">
                <div class="s16 size"></div>
              </div>
              <div  @click="changePliceSize(8)" class="SizeBox">
                <div class="s20 size"></div>
              </div>
            </div>
          </div>
          
        
          <div class="fastboard-bottom-right">
            <div class="fastboard-page-control light">
              <button class="fastboard-page-control-btn prev light" @click.stop="PreviousFastboard()">
                <svg fill="none" viewBox="0 0 30 30" class="fastboard-icon light">
                  <path d="m14 8-2 2-2 2 2 2 2 2" class="fastboard-icon-stroke-color" stroke="#5D6066"
                    stroke-linejoin="round" stroke-width="1.25"></path>
                </svg>
              </button>
              <!-- <span class="fastboard-page-control-text light" v-if="!AgoraPerview">{{
                defaultwhiteboardIndex + 1 + "/" + (CoursewareList?.length || 0)
              }}</span>
              <span class="fastboard-page-control-text light" v-else>{{
                defaultwhiteboardIndex + "/" + (slideCount || 0)
              }}</span> -->
              <div>
                <el-input v-model="defaultwhiteboardIndex" :min="1"  type="number"
                @change="ChangeWhiteIndex()"></el-input>
              </div>
             <span>/{{ AgoraPerview==false ? (CoursewareList?.length || 0) : (slideCount || 0)}}</span>
              
              <button class="fastboard-page-control-btn next light" @click.stop="nextFastboard()">
                <svg fill="none" viewBox="0 0 24 24" class="fastboard-icon light">
                  <path d="m10 8 2 2 2 2-2 2-2 2" stroke="#5D6066" stroke-linejoin="round" stroke-width="1.25"
                    class="fastboard-icon-stroke-color"></path>
                </svg>
              </button>
            </div>
          </div>

        </div>
        <div class="canvas-wraper">
            <canvas id="canvas" ref="canvas"></canvas>
        </div>
        <div id="anchor"></div>
        <!-- 屏幕共享 -->
        <div class="wraper"
        :class="shareDPStatus ? 'z2' : 'z4'"
        ref="ShareHome" id="ShareHome" v-show="SelectMenu == 3">
          <el-empty description="屏幕共享已结束,正在推流课件/摄像头">
            <el-button type="primary" @click="ipcShareDP"> 共享屏幕</el-button>
          </el-empty>

        
        </div>

        <div class="showTips" v-show="SelectMenu==2">
          <img src="../assets//img/play.png" alt="" />
          正在演示课件

          <el-button style="margin-left: 20px" size="mini" type="primary " @click.stop="ShowPPTList()">课件管理</el-button>
        </div>
        <div id="window-list">
          <el-button round class="changeShareDP"
           size="mini" type="primary " 
            v-if="shareDPStatus"
            @click.stop="getWindows()"
          >切换屏幕共享</el-button>
          <div class="flexcenter">
            <div class="mikeSvg windowSvg" @click="mute">

              <div class="redLine" v-if="!AudioState"></div>
              <img src="../assets/img/mike.svg" alt="">
            <p>静音</p>
            </div>
            <div class="cameraSvg windowSvg" @click="closeCamera">
              <div class="redLine" v-if="!CameraStatus"></div>
              <img src="../assets/img/camera.svg" alt="">
              <p>关闭视频</p>
            </div>
            <div class="TransportBox" v-if="liveStatus">
              <span v-if="TransportData?.sendBitrate">上行:{{ TransportData?.sendBitrate }} /kbps</span>
              <span v-if="TransportData?.sendBitrate">延迟:{{ (TransportData?.rtt).toFixed(1) }} ms</span>
              <div  class="WeakTips" v-if="WeakTips">当前网络不佳</div>
            </div>

          </div>
          <!-- @click="testWS" -->
          <div class="liveTitle">
            当前直播间:{{ rootInfo.lesson }}
          </div>
          <div class="flexcenter">
            <!-- showWindowSetting -->
            <div class="settingSvg windowSvg" @click="showWindowSetting">
              <img src="../assets/img/setting.svg" alt="">
              <p>设置</p>
            </div>

            <div>
              <el-button v-if="!liveStatus" type="primary" round @click="startNew">开始直播</el-button>
              <el-button v-else type="danger" round @click="ENDLiveCheck">结束直播</el-button>
            </div>
          </div>

        </div>
      </div>
      <!-- 右边摄像头和聊天室区域 -->
      <el-collapse-transition>
        <div class="right" ref="right" v-show="!fold">
          <div class="ChatRoom">
            <liveChat :liveDetail="liveDetail" @Send101Success="Send101Success"></liveChat>
            <el-button type="danger" size="mini" class="closeLianmai"
            @click.stop="closeLianmai()" v-if="lianmai.state"
            >结束连麦</el-button>

          </div>
        </div>
      </el-collapse-transition>
      <el-dialog title="选择共享内容" :visible.sync="shareDPVisible" class="showDP_dialog" width="70%">
        <div class="dpshow">
          <ul>
            <li class="shareDPLi" @click="addCheckClass(index)" v-for="(item, index) in shareDPList"
              :class="{ DPactive: selectedIndex == index }" :key="index">
              <img :src="item?.thumbnail?.toDataURL()" alt="" />
              <span class="dpname">{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="CancelDPVisible">取 消</el-button> -->
          <div class="shareJudge">
            <div v-if="platform == 'win32'">
              <el-checkbox v-model="isAuxiliaryAudio">同时共享窗口声音</el-checkbox>
            </div>
            <!-- <div v-if="platform == 'win32'">
              <el-checkbox disabled v-model="isSmoothness">视频流畅度优先</el-checkbox>
            </div> -->
            <div style="margin-left: 10%">
              <el-button @click="refreshDPList">刷新窗口</el-button>
            </div>
          </div>
          <el-button type="primary" @click="choiceDP()" :disabled="selectedIndex == -1">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog :visible.sync="CloseLivePage" width="30%" :before-close="handleClose">
        <div class="CloseLiveBox">
          <!-- <el-popconfirm
            @confirm="ENDLive"
            @cancel="handleClose"
            title="请再次确认是否结束直播"
          >
            <div class="goback" slot="reference">
              <p>结束直播</p>
              <span>结束后,无法再次开始直播</span>
            </div>
          </el-popconfirm> -->
          <!-- @click="ENDLive" -->
          <div class="close" @click="goback">
            <p>离开直播</p>
          </div>
        </div>
      </el-dialog>

      <upload :CoursewareID="CoursewareID" :roomId="rootInfo.lessonId" :CoursewareListLength="CoursewareListLength"
        :Courseware="Courseware" :lessonId="rootInfo.lessonId" @replaceCourseware="getCoursewareList"
        @destroyboard="destroyboard" ref="UploadCourseware"></upload>
      
      <div v-if="SelectMenu == 2 && CoursewareCount == 0">
        <img class="MissingCourseware" src="../assets/img/MissingCourseware.png" alt="" />
        <el-button class="CoursewareManagement" @click="ShowPPTList()" type="primary">添加课件</el-button>
      </div>
      
      <EquipmentTestingVue @hidden="TestingComplete"></EquipmentTestingVue>
      <setting ref="Setting" @BeautyDone="GetBeautyCanvas" @CloseBeauty="CloseBeauty" :AudioStream="AudioStream"
        @StopRNNoise="StopRNNoise" @RNNoiseStream="RNNoiseStream" @checkLiveprofile="checkLiveprofile"
        @DetermineMode="DetermineMode" @ChangeHomeCamera="ChangeHomeCamera"
        @changeMicrophoneVolume="changeMicrophoneVolume" @ChangePushType="ChangePushType"></setting>
        <div id="test111">
        <video ref="cameraStream" 
          width="1920" height="1080"
          id="cameraStream"  autoplay muted>
        </video>
      </div>
      <!-- width="1920" height="1080" -->
      <video  ref="playerBox" id="playerBox" autoplay muted></video>

      <P2P  class="P2P" @RemotePublishStreamAudio="RemotePublishStreamAudio"
      @RemotePublishStreamVideo="RemotePublishStreamVideo"
      :audioStream="AudioStream"
      :LocalVideoStream="whiteboardStream"
      :roomId="liveDetail.roomId"
      @handleUserUnpublishVideo="handleUserUnpublishVideo"
      @closeLianmai="closeLianmai" ref="P2P"></P2P>
      <!-- <video src="./33.mp4" muted loop autoplay id="RemoteVideo"
      width="1920" height="1080" style="position: fixed;z-index: -1;top: -127vh;"></video> -->
    </div>
  </div>
</template>

<script>
// import WindowSetting from "@/components/WindowSetting.vue";
import setting from "@/components/setting.vue";
import upload from "@/components/upload.vue";
// import HRTC from "../sdk/lib/hrtc";
// import HRTC from "../newSDK/lib/hrtc";
// import HRTC from "../sdk/v3/package/lib/HWLLSPlayer";
import { fabric } from "fabric";
import "../utils/eraser_brush.mixin.js";
const { ipcRenderer } = window.require("electron");
import { ixunkeAxios } from "@/assets/js/axios.js";
// import moment from "moment";
import liveChat from "./live-chat-room/live-chat-room.vue";
const Store = window.require("electron-store");
const store = new Store();
import EquipmentTestingVue from "../components/EquipmentTesting.vue";
import NAV from '../assets/common/nav.vue';
// import axios from "axios"
// import { RETRY } from "xgplayer/es/events.js";
var PeerConnection = window.RTCPeerConnection ||
  window.mozRTCPeerConnection ||
  window.webkitRTCPeerConnection;
import { Slide } from "@netless/slide";

import P2P from './p2p/index.vue'
export default {
  name: "Home",

  components: {
    // WindowSetting,
    setting,
    liveChat,
    upload,
    EquipmentTestingVue,
    NAV,
    P2P,
  },
  provide() {
    return {
      lianmai: this.lianmai,
    };
  },
  data() {
    return {
      P2PComponent:null,
      totalSeconds: 0,
      intervalId: null,

      BitrateintervalId: null, //统计字节定时器
      lianmai: {
        state: false,
        switch: false,
      },

      client: "",
      _playerCanvas: "",
      currentTool: "",
      oldTool:null,
      done: false,
      fabricObj: null,
      initIdx: 0,
      lastTime: Date.now(),
      fabricM1:null,

      PanelSizeHover:false,
      PanelTop:0,
      PanelHoverIndex:0,//x
      toolsArr: [
        {
          name: "pencil",tip:'画笔',
          icon: require("../assets/img/Editorial/icon-pencil.png"),
          
        },
        {
          name: "line",tip:'直线',
          // icon: "icon-line",
          icon: require("../assets/img/Editorial/icon-line.png"),
        },
        {
          name: "arrow",tip:'箭头',
          // icon: "icon-arrow",
          icon: require("../assets/img/Editorial/icon-arrow.png"),
        },

        {
          name: "dashedline",tip:'虚线',
          // icon: "icon-xuxian",
          icon: require("../assets/img/Editorial/icon-xuxian.png"),
        },
        {
          name: "color",
          // icon: require('../assets/img/Editorial/icon-color.png'),
        },
        {
          name: "text",tip:'文字',
          // icon: "icon-ziti",
          icon: require("../assets/img/Editorial/icon-ziti.png"),
        },
        {
          name: "rectangle",tip:'矩形',
          // icon: "icon-juxing",
          icon: require("../assets/img/Editorial/icon-juxing.png"),
        },
        {
          name: "circle",tip:'圆形',
          // icon: "icon-yuanxing",
          icon: require("../assets/img/Editorial/icon-yuanxing.png"),
        },
        {
          name: "equilaenlargeteral", tip:'放大',//放大
          // icon: "icon-sanjiaoxing",
          icon: require("../assets/img/Editorial/icon-enlarge.png"),
        },
        {
          name: "narrow", tip:'缩小',//缩小
          // icon: "icon-tuoyuanxing",
          icon: require("../assets/img/Editorial/icon-narrow.png"),
        },
        
        {
          name: "remove",tip:'删除',
          // icon: "icon-remove",
          icon: require("../assets/img/Editorial/icon-remove.png"),
        },
        {
          name: "undo",tip:'上一步',
          // icon: "icon-huitui",
          icon: require("../assets/img/Editorial/icon-huitui.png"),
        },
        {
          name: "camera",tip:'选择',
          // icon: "icon-xiangqian",
          icon: require("../assets/img/Editorial/icon-cam.png"),
        },
        {
          name: "reset",tip:'重置',
          // icon: "icon-reset",
          icon: require("../assets/img/Editorial/icon-reset.png"),
        },
        // {
        //   name: "hide",
        //   // icon: "icon-reset",
        //   icon: require("../assets/img/Editorial/icon-hide.png"),
        // },
      ],
      mouseFrom: {},
      mouseTo: {},
      moveCount: 1,
      doDrawing: false,
      fabricHistoryJson: [],
      mods: -1,
      drawingObject: null, //绘制对象
      drawColor: "#E34F51",
      drawWidth: 2,
      imageBase64: "",
      m1Top:844.91,
      m1Left:1498.15,
      fabricM3:null, //屏幕共享fabric对象

      zoom: window.zoom ? window.zoom : 1,
      whiteboardStream: "", //白板流
      whiteboardRecorder: "", //白板摄像流
      whiteboardVideoSource: "", // 白板推流源

      CanvasRenderingContext2D: "",
      canvasEl: "",
      isStopDraw: false,

      shareDPList: [], //窗口list
      shareDPVisible: false, //选择窗口状态
      selectedIndex: -1,
      shareDPStream: null, // 分享流

      rootInfo: {
        liveType: "",
        lesson: "",
      }, // 直播间信息
      host: "",
      liveDetail: {}, //直播间详情参数

      CameraStatus: false, //摄像头状态
      AudioState: true, //音频状态
      muteFlag: false, //华为云是否静音

      Videomerger: null, //混流后视频媒体流
      AuxiliaryAudioStream: null, //屏幕分享背景音频媒体流
      liveStatus: false, //直播状态

      fold: false, //右侧折叠状态
      ENDvisible: false, //结束确认框
      CoursewareCount: 0, //课件数量
      CoursewareList: [], //课件图片list

      defaultwhiteboardImg: "", //课件当前渲染图片src
      defaultwhiteboardIndex: 1,

      widthPercentage: "",
      HeightPercentage: "",

      LiveTimer: null,
      LiveDuration: null,
      CoursewareID: null,
      CoursewareListLength: null,
      Courseware: "", //课件列表
      endTime: "", //直播结束时间
      LiveStartTime: 0, //推流成功时间
      tiSelectMenumeDifference: null, //直播时长
      timer: null, //结束直播
      SelectMenu: 1, // 选择的菜单
      shareDPStatus: false, // 共享屏幕状态

      CloseLivePage: false, //结束直播返回首页状态
      timeDifference: null,

      ExistCamera: true, //是否存在可以摄像头

      isAuxiliaryAudio: false, //共享声音状态
      isSmoothness: true, // 流畅度优先状态
      combinedSource: null, // 共享屏幕和音频合并媒体流
      mergeAudioTrack: null, // 共享屏幕和音频合并音轨

      DeviceConfig: {}, //串流设备设置
      profile: [
        {
          width: 960,
          height: 540,
          videoBitrate: 1000,
          videoFramerate: 25,
          videoGop: 45,
          audioSampleRate: 32000,
          audioBitrate: 40,
          audioChannels: 2,
          template: 0,
        },
        {
          width: 1280,
          height: 720,
          videoBitrate: 1500,
          videoFramerate: 25,
          videoGop: 30,
          audioSampleRate: 32000,
          audioBitrate: 40,
          audioChannels: 2,
          template: 1,
        },
        {
          width: 1920,
          height: 1080,
          videoBitrate: 2400,
          videoFramerate: 25,
          videoGop: 30,
          audioSampleRate: 32000,
          audioBitrate: 48,
          audioChannels: 2,
          template: 1,
        },
        {
          width: 1920,
          height: 1080,
          videoBitrate: 8000,
          videoFramerate: 30,
          videoGop: 30,
          audioSampleRate: 32000,
          audioBitrate: 48,
          audioChannels: 2,
          template: 1,
        },
      ],
      profileNmae: ["标清", "高清", "超清","蓝光"],
      profileIndex: 1, //默认视频输出配置
      liveGoods: [], //直播带货商品列表
      CameraMirrorMode:false,
      VideomergerStream: null, //本地混流后的stream

      //腾讯云sdk推流实例
      livePusher: null,
      ID1: null, //腾讯摄像头流id
      ID2: null, //画板流id
      ID3: null, //屏幕分享流id

      lengthWidthRatio: null, //摄像头长宽比
      localTrackWidth:1920,
      localTrackHeight:1080,
      // canvas回退
      canvas: null,
      isLoadCanvas: false,
      canvasState: [],
      fabricTargetList: [],

      FabricUP: false,

      RemoteStream: null, //远端流对象
      LocalCameraStream:null, //本地音频流

      AudioStream: null,
      BeautyFlag: false, //是否开启美颜
      RNNoiseFlag: false, //是否开启降噪

      loadSkeleton: true, //骨架屏显示状态

      left: 0.783,
      top: 0.786,

      platform: "", //运行系统OS

      RemoteStreamhasVideo: true, //远端流是否包含视频
      RemoteStreamhasAudio: true, //.....

      TransportData: {
        sendBitrate: '',
        rtt: ''
      }, //实时统计网络情况
      bitrateValues: [],//用于存储sendBitrate值
      bitrateCount: 0, //统计计数器
      WeakTips: false, //网络不佳提示
      liveType: null, //推流方式 默认华为云，备用SRS
      MicrophoneVolume:200,  //麦克风音量
      ShareScreenAudio:0,//Windows共享虚拟声卡音量
      fabricObjbackgroundImagescale:true, //默认背景图是否缩放

      TXPushVideoBitrateList:[], //腾讯云推流视频码率
      TXPushAudioBitrateList:[], //腾讯云推流音频码率

      startReady:false,

      FabricBackgroundImageSize:{},//初始化fabric课件图片的尺寸
      BackgroundImageProportion:1, //fabric课件图片的放大比例
      slideCount:0,
      AgoraPerview:false
    };
  },
  async created() {
    this.platform = store.get('platform');
    const os = window.require('os');
    store.set('OSVersion',os.release())
    console.log(os.release(),'os.release()');
    // const StoreUrlData = await store.get('UrlQueryData')
    // console.log(StoreUrlData);
    // const UrlQueryData = this.parseQueryString(StoreUrlData);
    this.loadSkeleton = true;
    document.title = "​迅课直播助手";
    this.host = store.get("host");
    store.set("GUIRouter", "live");
    console.log(this.$route.query,'----query----');
    this.rootInfo = this.$route.query;
    // this.SelectMenu = 1;

    // url参数形式唤起网页
   
    if (this.rootInfo?.liveType) {
      // console.log(this.rootInfo.liveType);
      this.getLiveInfoEvent();
      this.getCoursewareID();
      this.CameraStatus = true;
    }

    if (this?.liveDetail?.roomId) {
      store.set('roomId', this.liveDetail.roomId)
    }
    this.PushType = store.get('PushType')
    // this.openCamera(true);
    // 主进程主动唤起隐藏窗口方法

  },
  async beforeDestroy() {
    console.log('beforeDestroy!!!!!!!!!!!!!!!!!!!!!!')
    document.onkeydown = null;
    try {
      this.livePusher.stopPush()
    } catch (error) {
      console.log('stopPush error',error)
    }

    try {
      this.stopRTC()
    } catch (error) {
      console.log('stopRTC error',error)
    }


    this.liveDetail = null;
    ipcRenderer.removeAllListeners();
    try {
      clearInterval(this.intervalId);
      this.intervalId = null;
    } catch (error) {
      console.log('clearInterval error',error)
    }
    try {
      this.fabricObj?.dispose();

    } catch (error) {
      console.log('dispose fabricObj error',error)
    }
    try {
      clearInterval(this.BitrateintervalId);
    } catch (error) {
      console.log('clearInterval error',error)
    }
    try {
      clearInterval(this.checkvideo1El);
    } catch (error) {
      console.log('clearInterval error',error)
    }
    try {
      if (this.lianmai.state) {
        await this.closeLianmai()
      }
    } catch (error) {
      console.log('closeLianmai error',error)
    }

  },
  computed: {
    canvasWidth() {
      return window.innerWidth;
    },
    formattedTime() {
      const hours = Math.floor(this.totalSeconds / 3600).toString().padStart(2, '0');
      const minutes = Math.floor((this.totalSeconds % 3600) / 60).toString().padStart(2, '0');
      const seconds = (this.totalSeconds % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
  },
  async mounted() {
    this.platform = store.get('platform');
    this.widthPercentage = 0.22;
    this.HeightPercentage = 0.39;
    try {
      this.MicrophoneVolume = store.get('MicrophoneVolume')||200
    } catch (error) {
      console.log('获取默认麦克风音量失败');
    }
    try {
      this.profileIndex = store.get('profileIndex')||1;
    } catch (error) {
      this.profileIndex = 1
    }
    this.initCanvas()
    // 监听主进程发送的桌面源
    ipcRenderer.on("desktopSources", (event, sources) => {
      // 处理获取到的桌面源
      console.log("Desktop sources:", sources);
      this.shareDPVisible = true;
      this.shareDPList = sources.filter((obj) => obj.name !== "Ixunke-live");
      // console.log(this.shareDPList);
    });

    // 监听主进程发送的获取桌面源错误
    ipcRenderer.on("desktopSourcesError", (event, errorMessage) => {
      // 处理获取桌面源时的错误
      console.error("Error getting desktop sources:", errorMessage);
    });

    // 监听主进程发送结束屏幕共享
    ipcRenderer.on("EndSharingScreen", (event, errorMessage) => {
      // 处理获取桌面源时的错误
      this.EndSharingScreen();
    });

    // 监听主进程发送结束屏幕共享
    ipcRenderer.on("hiddenRight", (event, errorMessage) => {
      // 处理获取桌面源时的错误
      this.$refs.right.style.display = "none";
    });
    ipcRenderer.on("showRight", (event, errorMessage) => {
      // 处理获取桌面源时的错误
      this.$refs.right.style.display = "flex";
    });
    this._stream = new MediaStream();
    this.DeviceConfig = await store.get("device");
    await this.attachAudioStream();
    // console.log('加载完成');  
   
    document.onkeydown = showkey;
    const _this = this;
    function showkey(event) {
      var key = event.keyCode;
      key = event.keyCode;
      if(_this.SelectMenu!==2){
        return
      }
        switch (key) {
            case 37://"按了←键！"
                _this.PreviousFastboard()
                break;
            case 38://"按了↑键！"
                _this.PreviousFastboard()
                break;
            case 39://"按了→键！"
                _this.nextFastboard()
                break;
            case 40://"按了↓键！"
                _this.nextFastboard()
                break;
        }
     
    }
    // ipcRenderer.on('setUrlData', async(event, messageContent) => {
    //   // 处理主进程发送的消息
    //   this.loadSkeleton = true;
    //   console.log(messageContent)
    //   console.log('接受到setUrlData消息');
    //   try {
    //     this.rootInfo  = this.parseQueryString(messageContent.arg);
    //     console.log(this.rootInfo);
    //     store.set('rootInfo',this.rootInfo)
    //     await this.getLiveInfoEvent();
    //     await this.getCoursewareID();
    //     this.CameraStatus = true;
    //     this.loadSkeleton = false;
    //   } catch (error) {
    //       console.log('解析参数失败');
    //   }

    // })
    // let tmprootInfo = store.get('rootInfo')||''
    // if(tmprootInfo){
    //     this.rootInfo  = tmprootInfo
    //     await this.getLiveInfoEvent();
    //     await this.getCoursewareID();
    //     this.CameraStatus = true;
    //     this.loadSkeleton = false;
    // }
    // if(this.rootInfo?.liveType){
    //   this.loadSkeleton = false;
    // }
    // this.aspectRatioChange()


  },
  methods: {
    changeMicrophoneVolume(e){
      console.log(e);
      this.MicrophoneVolume = e;
      if(this.gainNode2){
        console.log(this.source2);
        console.log('调整音量');
        this.gainNode2.gain.value = e / 100;
      }
    },

     // 结束连麦
    async closeLianmai() {
      // this.$refs.P2P.leaveRoom()
      this.$message.success('连麦已结束')
      
      this.fabricObj.remove(this.fabricM5);
      this.fabricM5 = null;
      this.lianmai.state = false;
      this.m1Top =844.91 ;
      this.m1Left = 1498.15;
      this.remoteAudioTrack = null;
      if(this.source3){
        try {
          this.source3.disconnect(this.destination);
          this.source3.mediaStream.getTracks().forEach(track => track.stop());
          this.source3 = null;
        } catch (error) {
          
        }
      
      }
    
      if(this.SelectMenu==1){
        this.revertM1Potion()
      }else{
        try {
          this.changeM1Potion()
        } catch (error) {
          
        }
      }
      const backgroundImage = this.fabricObj.backgroundImage;
      if(backgroundImage){
        let {scaleX,scaleY} = backgroundImage;
       
          backgroundImage.top = 0;
          backgroundImage.left = 0;
          backgroundImage.scaleX = scaleX/0.85;
          backgroundImage.scaleY = scaleY/0.85;
          this.fabricObj.setBackgroundColor('#000');
          this.fabricObjbackgroundImagescale = true; 
      }
      if(this.fabricM9){
        let {scaleX,scaleY} = this.fabricM9;
        this.fabricM9.top = 0;
        this.fabricM9.left = 0;
        this.fabricM9.scaleX = scaleX/0.85;
        this.fabricM9.scaleY = scaleY/0.85;
        this.fabricObj.setBackgroundColor('#000');
        this.fabricObjbackgroundImagescale = true; 
      }
      if(this.fabricM3){
        this.fabricM3.set({
          scaleX:1,
          scaleY:1,
          width: 1920,
          height: 1080,
          top:0,
          left:0,
        })
      }
    },
    //主播端发送连麦邀请成功
    Send101Success(){
      this.$refs.P2P.initRTC();
    },
    async ChangeHomeCamera(e){
      console.log(e);
      let AudioValue = store.get('device')[1]
      await store.set("device",{0:e,1:AudioValue});
      await this.TestingComplete();
     
      if(this.SelectMenu==1){
        this.revertM1Potion()
      }else{
        try {
          this.changeM1Potion()
        } catch (error) {
          
        }
      }
    },
    changePliceSize(size){
      console.log(this.initIdx);
      if(this.initIdx!==this.PanelHoverIndex){
        console.log('不是实时');
        console.log(this.PanelHoverIndex);
        let name = this.toolsArr[this.PanelHoverIndex].name
        console.log(this.toolsArr[this.PanelHoverIndex].name);
        this.handleTools({ name:name }, this.PanelHoverIndex);
      }
      // console.log(size);
      this.fabricObj.freeDrawingBrush.width = size;
      this.drawWidth = size;
      this.PanelSizeHover= false;
    },
    addHoverEffect(index,item){
      // console.log(index,item);
      if(index&&item){
        this.PanelHoverIndex = index;
        this.PanelTop = index*38+'px';
        // this.currentTool = item.name;
      }
      if(index==8||index==9||index==10||index==11||index==12||index==13){
        return
      }
      this.PanelSizeHover= true;
    },
    removeHoverEffect(){
      this.PanelSizeHover= false;
    },
    // 修改背景图长宽
    changeFabricBgPotion() {
      if (this.SelectMenu == 2 && this.fabricM9) {
        // console.log(this.fabricM9, '存在m9');
        try {
          let {scaleX, scaleY} = this.fabricM9;
          if (this.fabricObjbackgroundImagescale) {
            console.log(scaleX, scaleY, 'scaleX,scaleY');
            this.fabricM9.top = 162;
            this.fabricM9.left = 144;
            this.fabricM9.scaleX = scaleX * 0.85;
            this.fabricM9.scaleY = scaleY * 0.85;
            this.fabricObj.setBackgroundColor('#000');
            this.fabricObjbackgroundImagescale = false;
          }
        } catch (error) {
          // Error handling
        }
      } else if (this.SelectMenu == 3 && this.fabricM3) {
        // console.log(this.fabricM3, '存在m3');
        try {
          let {scaleX, scaleY} = this.fabricM3;
          if (this.fabricObjbackgroundImagescale) {
            console.log(scaleX, scaleY, 'scaleX,scaleY');
            this.fabricM3.top = 162;
            this.fabricM3.left = 144;
            this.fabricM3.scaleX = scaleX * 0.85;
            this.fabricM3.scaleY = scaleY * 0.85;
            this.fabricObj.setBackgroundColor('#000');
            this.fabricObjbackgroundImagescale = false;
          }
        } catch (error) {
          // Error handling
        }
      } else {
        // Original fallback behavior for other cases
        const backgroundImage = this.fabricObj.backgroundImage;
        try {
          let {scaleX, scaleY} = backgroundImage;
          if (this.fabricObjbackgroundImagescale) {
            console.log(scaleX, scaleY, 'scaleX,scaleY');
            backgroundImage.top = 162;
            backgroundImage.left = 144;
            backgroundImage.scaleX = scaleX * 0.85;
            backgroundImage.scaleY = scaleY * 0.85;
            this.fabricObj.setBackgroundColor('#000');
            this.fabricObjbackgroundImagescale = false;
          }
        } catch (error) {
          // Error handling
        }
      }
    },
    async handleUserUnpublishVideo(){
      console.log('远端流关闭摄像头');
      await this.addP2PM6();
    },
    async RemotePublishStreamVideo(){
      console.log('远端流开启摄像头');
      this.lianmai.state = true;
      await this.addP2PM5();
      if(this.SelectMenu==1){
        this.revertM1Potion()
        return
      }
      // if(this.CameraStatus){
        console.log('修改m1位置');
        if(this.fabricM1){
          // console.log(this.fabricM1);
          console.log('----this.fabricM1------')
          this.fabricM1.set('opacity', 1);
          this.fabricM1.set({
            scaleX: 0.15,
            scaleY: 0.15,
            width: 1920,
            height: 1080,
            top:0,
            left:637,
            selectable:false,
          })
          this.fabricM1.moveTo(0)

        }
        if(this.fabricM5){
            this.fabricM5.set({
              scaleX: 0.6,
              scaleY:0.45,
              width: 1920,
              height: 360,
              left: 926,
              top:0
            })
        }
      // }  
      if(this?.fabricM3?.width==1920){
        this.fabricM3.set({
          scaleX: this?.fabricM3.scaleX*0.85,
          scaleY:  this?.fabricM3.scaleY*0.85,
          width: 1920,
          height: 1080,
          top:162,
          left:144,
        })
       
      }
      // 修改背景图长宽
      console.log('修改背景图长宽1');
      this.changeFabricBgPotion();
      this.fabricObj.setBackgroundColor('#000');
      this.fabricObj.renderAll();
    },
    // 远端流加入成功
    async RemotePublishStreamAudio(stream){
      console.log('远端流新增麦克风');
      console.log(this.source3,'---this.source3--')
      
      console.log(stream);
      console.log("观众接受连麦")
      this.lianmai.state = true;
      if(this.remoteAudioTrack){
        console.log('远端音频流已存在');
        return
      }
      this.remoteAudioTrack = stream;

      this.source3 = this.audioContext.createMediaStreamSource(new MediaStream([stream]));

      let gainNode = this.audioContext.createGain();
      gainNode.gain.value = (this.MicrophoneVolume/100);
      this.source3.connect(gainNode).connect(this.destination);
      const newTrack = this.destination.stream.getAudioTracks()[0];
      try {
        this.audioSender.replaceTrack(newTrack);
      } catch (error) {
        
      }  
      try {
        this.livePusher.stopCustomCapture(this.TXAudioID)
        this.TXAudioID = null;
        this.TXAudioID = this.livePusher.startCustomCapture(this.destination.stream)
        // this.videoEffectManager.setLayout([{
        //     streamId: this.TXVideoID,
        //     x: this.profile[this.profileIndex].width/2,
        //     y: this.profile[this.profileIndex].height/2,
        //     width: this.profile[this.profileIndex].width,
        //     height: this.profile[this.profileIndex].height,
        //     zOrder: 2
        // },{
        //     streamId: this.TXAudioID,
        //     x: 0,
        //     y: 0,
        //     width: 0,
        //     height: 0,
        //     zOrder: 1
        // }]);
      } catch (error) {
        console.log(error,'腾讯云混合远端音频流失败');
      }
  
    },

    // 连麦远端流无图像
    addP2PM6(){
      var _this = this;
      let path = require('../assets/img/lianmai/UserDefault.png');
      this.fabricObj.remove(this.fabricM5)
      fabric.Image.fromURL(path, function (img) {
        let imageWidth = img.width;
        let imageHeight = img.height;
        console.log(imageWidth,imageHeight);
        img.set({
            left: 960,
            top: 0,
            width:1920,
            height:1080,
            scaleX:2,
            scaleY:2,
            id:'m5',
            // height: scaledHeight,
            objectCaching: false,
        });
        console.log(img);
        if(_this.SelectMenu!==1){
          img.set({
            scaleX: 0.6,
            scaleY:0.45,
            width: 1920,
            height: 360,
            left: 926,
            top:0
          })
        }
        _this.fabricM5 = img;
        _this.fabricObj.add(img);
        _this.fabricObj.renderAll();              // pngImage.setControlVisible('mtr', false) //隐藏旋转角                    
      });
     
    },
    // 连麦远端流
    addP2PM5(){
      // P2PDomID
      this.fabricObj.remove(this.fabricM5)
      let dom = document.getElementById('RemoteVideo');
      let reVideo = dom.childNodes[0];
      reVideo.width = "960";
      reVideo.height = "1080";
      this.P2PDomID = dom.childNodes[0].id;
      console.log(this.P2PDomID);
      // this.P2PDomID = 'RemoteVideo';
      var video1El = document.getElementById(this.P2PDomID);
      console.log(video1El);
      var scaleX = 1;
      var scaleY = 1;
      var left = 960;
      var top = 0;
      var width = 960;
      var height = 1080;
      if(this.SelectMenu !== 1){
        console.log('SelectMenu 不等于1')
        scaleX = 0.6;
        scaleY = 0.25;
        width = 1920;
        height = 1080;
        left = 926;
        top = 0;
      }
      console.log(width,height)
      console.log(scaleX,scaleY)
      var video1 = new fabric.Image(video1El, {
          scaleX: scaleX,
          scaleY: scaleY,
          width: width,
          height: height,
          left:left,
          top:top,
          objectCaching: false,
          selectable:false,
          id:'m5',
          hoverCursor:'default',
          transparentCorners: false,  //实心角
          cornerColor: 'red',  //角颜色
          cornerStrokeColor: 'red', //角边框颜色
          cornerSize: 8, //角大小
          borderColor: 'red', //辅助线颜色
          padding: -2,
          borderScaleFactor: 2, //辅助线宽度
          backgroundColor:'black'
      });
      video1.setControlVisible('mtr', false) //隐藏旋转角
      video1.moveTo(0)
      console.log(video1);
      this.fabricM5 = video1;
      this.fabricObj.add(video1);
      this.fabricObj.renderAll()
      if (!this.animationId) {
          // console.log('不存在动画，开始渲染');
          this.fabricRender();
      }
    },
    addP2PM4(){
      var video1El = document.getElementById(this.P2PDomID);
      // console.log(video1El);
      this.SelectMenu = 1
      var scaleX = 1;
      var scaleY = 1;
      var left = 0;
      var top = 0;
      if(this.SelectMenu !== 1){
        scaleX = 0.22;
        scaleY = 0.22;
        left = this.m1Left;
        top = this.m1Top;
      }
      var video1 = new fabric.Image(video1El, {
          left: 0,
          top: 0,
          // scaleX:1,
          // scaleY:1,
          width:540,
          height:320,
          objectCaching: false,
          selectable:false,
          id:'m4',
          hoverCursor:'default',
          transparentCorners: false,  //实心角
          cornerColor: 'red',  //角颜色
          cornerStrokeColor: 'red', //角边框颜色
          cornerSize: 8, //角大小
          borderColor: 'red', //辅助线颜色
          padding: -2,
          borderScaleFactor: 2, //辅助线宽度
      });
      video1.setControlVisible('mtr', false) //隐藏旋转角
      video1.moveTo(0)
      console.log(video1);
      this.fabricM4 = video1;
      
      
      this.fabricObj.add(video1);
  
      this.fabricObj.renderAll()
      console.log(this.fabricObj?.getObjects());
      if (!this.animationId) {
          // console.log('不存在动画，开始渲染');
          this.fabricRender();
      }
      this.changeFabricBg()
    },
    //设置主播无摄像头默认画面
    async addP2PM0(){
      var _this = this;
      _this.fabricObj.remove(this.fabricM1)
      let path = require('../assets/img/TeacherDefault.png')
      fabric.Image.fromURL(path, function (img) {
        let imageWidth = img.width;
        let imageHeight = img.height;
        console.log(imageWidth,imageHeight);
        let width = 1920;
        let height = 1080;
        let scaleX = 1;
        if(_this.lianmai.state){
          width = 1920/2;
          height = 1080;
          scaleX = 0.5;
        }
        img.set({
            left: 0,
            top: 0,
            width:1920,
            height:1080,
            scaleX:scaleX,
            scaleY:1,
            id:'m1',
            // height: scaledHeight,
            objectCaching: false,
            backgroundColor:'red'
        });
        if(_this.SelectMenu!==1){
          img.set({
            scaleX: 0.15,
            scaleY: 0.15,
            width: 1920,
            height: 1080,
            top:0,
            id:'m1',
            left:637,
            backgroundColor:'red'
          })
        }
        console.log(img);
        _this.fabricM1 = img;
        if(_this.SelectMenu!==1 &&!_this.lianmai.state){
          img.set('opacity', 0);
        }
        _this.fabricObj.add(img);
        _this.fabricObj.renderAll();              // pngImage.setControlVisible('mtr', false) //隐藏旋转角                    
      });
      console.log('修改背景图长宽2');
      if(this.lianmai.state){
        this.changeFabricBgPotion()
      }
      
    },
    async stopRTC() {
      try {
        this.pc.close()
        this.pc = null;
      } catch (error) {

      }

    },
    async stopFFmpeg(){
      if (this.ffmpegProcess) {
        console.log('停止 ffmpeg 服务')
        this.intentionalStop = true; // 设置主动停止标志

        this.ffmpegProcess.kill('SIGINT'); // 发送 SIGINT 信号以终止进程
        console.log('FFmpeg process terminated.');
        this.ffmpegProcess = null; // 清除 ffmpeg 实例引用
      }
    }, 
 
    async ffmpegPush(){
      console.log('ffmpegPush');
      const ffmpeg = window.require('fluent-ffmpeg');
      const _this = this;
      const platform = this.platform;
      if(!platform||platform==undefined||platform==null){
        platform = store.get('platform')
      }

      if(platform=='win32'){
        ffmpeg.setFfmpegPath('C:\\Program Files\\Ixunke\\live\\resources\\xunkeLivePusher.exe');
      }else{
        const path = window.require('path')

        const macOSAppPath = store.get("macOSAppPath")
        const arch = store.get('arch');
        var ffmpegPath;
        if(arch=='arm64'){
           ffmpegPath = path.join(macOSAppPath, './mediaserve/arm/xunkeLivePusher');
        }else{
           ffmpegPath = path.join(macOSAppPath, './mediaserve/x64/xunkeLivePusher');
        }
        const _this = this;
        ffmpeg.setFfmpegPath(ffmpegPath);
      }
      // rtsp://127.0.0.1/live/test
      // rtsp://127.0.0.1:18554/mystream
      // 保存 ffmpeg 实例
      // :22554
      this.ffmpegProcess = ffmpeg()
      .input('rtsp://127.0.0.1:22554/live/test')
      .outputOptions([
          '-c copy',       // 视频和音频编解码器设置为直接复制
          '-c:a aac',      // 使用 AAC 音频编码器
          '-ar 44100',     // 将音频采样率设置为 44100 Hz
          '-f flv'         // 指定输出格式为 FLV，适用于 RTMP 流
      ])
      // rtmp://push-hw.cdn.ixunke.cn/ixunke/${this.liveDetail.roomId}
      // rtmp://livepush-tx.cdn.ixunke.cn/ixunke/${this.liveDetail.roomId}
      // console.log(this.liveDetail.encryptPushUrl);
      .output(`rtmp://livepush-tx.cdn.ixunke.cn/ixunke/${this.liveDetail.roomId}`)
      // this.ffmpegProcess = ffmpeg()
      // .input('rtsp://127.0.0.1:22554/live/test')
      // .outputOptions([
      //     '-c:v copy',     // 复制视频流
      //     '-c:a aac',      // 将音频转为 AAC（最常用且兼容性最好）
      //     '-b:a 128k',     // 音频码率 128kbps
      //     '-ar 44100',     // 采样率 44.1kHz
      //     '-strict experimental', // 某些情况下需要此参数
      //     '-f mpegts'      // MPEG-TS 容器
      // ])
      // .output('srt://livepush-tx.cdn.ixunke.cn:9000?streamid=#!::h=livepush-tx.cdn.ixunke.cn,r=ixunke/' + this.liveDetail.roomId + ',m=publish')
      .on('start', function(commandLine) {
          console.log('ffmpegPush start!!!!!!!!!!');
          console.log('Spawned Ffmpeg with command: ' + commandLine);
          _this.liveStatus = true;
          _this.liveType = 'SRS';
          // _this.getTransportStatsSRS();
          _this.restoreFfmpeg = false;
          _this.$message.closeAll();

      })
      .on('progress', function(progress) {
        // console.log(progress);
          // console.log('Processing: ' + progress.percent + '% done');
      })
      .on('end', function() {
          console.log('Processing finished!');
      })
      .on('error', function(err) {
        console.log('ffmpeg error',err)
          if (!_this.intentionalStop) { // 仅在非主动停止的情况下进行恢复
            _this.liveStatus = false;
            // _this.$message.error("推流异常中止，正在恢复直播推流");
            ipcRenderer.send('showNotification',{
              title:'推流异常中止',
              body:'正在尝试重新恢复'
            })
            _this.stopFFmpeg().then(() => {
              setTimeout(() => {
                _this.ffmpegPush();
              }, 1000);
            }).catch(error => {
              console.error('Error stopping FFmpeg:', error);
              setTimeout(() => {
                _this.ffmpegPush();
              }, 1000);
            });

            // _this.$alert('推流异常中止，请尝试重新恢复', '提示', {
            //   confirmButtonText: '确定',
            //   callback: () => {
            //     setTimeout(() => {
            //       _this.ffmpegPush()
            //     }, 2000);
            //   }
            // })
            // _this.stopFFmpeg();
            _this.restoreFfmpeg = true;
         
              // console.log('切换到udp推流')
              // try {
              //   _this.stopRTC()
              // } catch (error) {
                
              // }
              // _this.publishTest(true)
              return
            
          }
          _this.intentionalStop = false; // 重置主动停止标志
          })
      .run();
    
    },
    // 初始化连麦
    async initP2P(){
      // if (!this.P2PComponent) {
      //   // 动态导入 P2P 组件
      //   this.P2PComponent = () => import('./p2p/index.vue');
      // }
    },
    async testWSMeat() {
      const that = this;

      that.pc = await new PeerConnection(null);

      that.pc.addTransceiver("audio", { direction: "sendonly" });
      that.pc.addTransceiver("video", { direction: "sendonly" });
      // this.LocalCameraStream
      // this.whiteboardStream
      var track1 = this.whiteboardStream.getVideoTracks()[0];
      

      console.log(track1);
      var audioTrack;
      try {
        audioTrack = this.AudioStream.getTracks()[0];
      } catch (error) {
        console.log(error);
        console.log('没有音频输入设备');
        this.$notify({
          title: '开启直播失败',
          message: '未检测到可用麦克风，请插入设备后重新进入',
          type: 'error',
          position:'bottom-right'
        });
        return
      }
      console.log(audioTrack);
      if (this.mergeAudioTrack) {
        console.log("存在混合音频流");
        audioTrack = this.mergeAudioTrack;
      }
      let { width, height } = this.profile[this.profileIndex]

      const constraints = {
        width: width ,
        height: height ,
      };
      track1.applyConstraints(constraints)
      .then(() => {
        console.log('Video constraints applied successfully');
        console.log(track1.getSettings());

      }).catch((e) => {
      console.error('Failed to apply video constraints:', e);
      });
      that.pc.addTrack(audioTrack);
      that.pc.addTrack(track1);
     
      this.senders = that.pc.getSenders();
      let sender = this.senders.find(s => s.track && s.track.kind == 'video');
      this.audioSender = this.senders.find(s => s.track && s.track.kind == 'audio');
      this.videoSender = sender;
      let offer = await that.pc.createOffer();
      const setAudioBitrate = (section, bitrate, voice) => {
        let opusPayloadFormat = '';
        let lines = section.split('\r\n');
        console.log(111);
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
            opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0];
            break;
          }
        }

        if (opusPayloadFormat === '') {
          return section;
        }

        for (let i = 0; i < lines.length; i++) {
          if (lines[i].startsWith('a=fmtp:' + opusPayloadFormat + ' ')) {
            if (voice) {
              lines[i] = 'a=fmtp:' + opusPayloadFormat + ' minptime=10;useinbandfec=1;maxaveragebitrate='
                + (parseInt(bitrate) * 1024).toString();
            } else {
              lines[i] = 'a=fmtp:' + opusPayloadFormat + ' maxplaybackrate=48000;stereo=1;sprop-stereo=1;maxaveragebitrate='
                + (parseInt(bitrate) * 1024).toString();
            }
          }
        }

        return lines.join('\r\n');
      };
      const setCodec = (section, codec) => {
        const lines = section.split('\r\n');
        const lines2 = [];
        const payloadFormats = [];
        console.log(222);
        for (const line of lines) {
          if (!line.startsWith('a=rtpmap:')) {
            lines2.push(line);
          } else {
            if (line.toLowerCase().includes(codec)) {
              payloadFormats.push(line.slice('a=rtpmap:'.length).split(' ')[0]);
              lines2.push(line);
            }
          }
        }

        const lines3 = [];
        let firstLine = true;

        for (const line of lines2) {
          if (firstLine) {
            firstLine = false;
            lines3.push(line.split(' ').slice(0, 3).concat(payloadFormats).join(' '));
          } else if (line.startsWith('a=fmtp:')) {
            if (payloadFormats.includes(line.slice('a=fmtp:'.length).split(' ')[0])) {
              lines3.push(line);
            }
          } else if (line.startsWith('a=rtcp-fb:')) {
            if (payloadFormats.includes(line.slice('a=rtcp-fb:'.length).split(' ')[0])) {
              lines3.push(line);
            }
          } else {
            lines3.push(line);
          }
        }

        return lines3.join('\r\n');
      };
      const editOffer = (sdp) => {
        console.log(333);
        const sections = sdp.split('m=');
        for (let i = 0; i < sections.length; i++) {
          if (sections[i].startsWith('video')) {
            sections[i] = setCodec(sections[i],'h264/90000');
          } else if (sections[i].startsWith('audio')) {
            sections[i] = setAudioBitrate(setCodec(sections[i], 'opus/48000'), 32, true);
          }
        }
        return sections.join('m=');
      };
      var EdSdp = editOffer(offer.sdp);

      await that.pc.setLocalDescription(offer);

      const setVideoBitrate = (section, bitrate) => {
        console.log(555);
        let lines = section.split('\r\n');
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].startsWith('c=')) {
            lines = [...lines.slice(0, i+1), 'b=TIAS:' + (parseInt(bitrate) * 1024).toString(), ...lines.slice(i+1)];
            break;
          }
        }
        return lines.join('\r\n');
      };

      const editAnswer = (sdp) => {
        console.log(444);
        let { videoBitrate } = (this.profile[this.profileIndex]);
        console.log(videoBitrate);
      
        const sections = sdp.split('m=');
        for (let i = 0; i < sections.length; i++) {
          if (sections[i].startsWith('video')) {
            sections[i] = setVideoBitrate(sections[i],videoBitrate );
          }
        }
        return sections.join('m=');
      };
      
      try {
        const url = 'http://127.0.0.1:22380/index/api/webrtc?app=live&stream=test&type=push';
        const response = await ipcRenderer.invoke('post-webrtc', url, EdSdp);

        console.log(response);
        console.log('-----------------');

        let sdp = await editAnswer(response.sdp);
        console.log(sdp);

        if (response) {
          await that.pc.setRemoteDescription({
            type: 'answer',
            sdp,
          });
          this.startReady = false;
          // ...
        } else {
          this.startReady = false;
          console.log('本地rtc服务失败');
          that.rtcToRtsp();
        }
      } catch (error) {
        console.error('推流异常', error);
        that.stopRTC();
        // ...
      }
      that.pc.onconnectionstatechange = function (event) {
        if (that.pc.connectionState === 'connected') {
          console.log('Connection state is connected');
        } else if (that.pc.connectionState === 'failed') {
          console.error('Connection state is failed');
        }
      };
    },
    async rtcToRtsp(){
      await this.stopRTC();
      if(this.ReconnectCount<5){
        this.ReconnectCount++;
        setTimeout(() => {
          console.log('开始重连rtc');
           this.testWSMeat();
        }, 1000);
      }else{
        console.log('重连5次rtc失败');
        return
      }
    },
    async TXPusherFUN(flag=true){
      try {
          // this.livePusher.getVideoEffectManager().enableMixing(true);

        } catch (error) {
          this.$message.error(error);

        }
        // this.videoEffectManager = this.livePusher.getVideoEffectManager();
        // console.log(this.videoEffectManager)
        
        //设置视频的分辨率
        this.livePusher.setProperty('setVideoResolution', {
          width: this.profile[this.profileIndex].width,
          height: this.profile[this.profileIndex].height,
        })
        // await this.livePusher.setVideoQuality('720p')
        //设置视频的帧率
        this.livePusher.setProperty('setVideoFPS', this.profile[this.profileIndex].videoFramerate)
        //视频的码率
        this.livePusher.setProperty('setVideoBitrate', this.profile[this.profileIndex].videoBitrate)
        //设置音频的采样率
        this.livePusher.setProperty('setAudioSampleRate', this.profile[this.profileIndex].audioSampleRate)
        //设置音频的码率
        this.livePusher.setProperty('setAudioBitrate', this.profile[this.profileIndex].audioBitrate)
        
        if(flag){
          this.$message.success('正在开启直播')
        }
        this.TXLivePusherPushStatus = true;
        console.log(this.whiteboardStream);
 
        
        this.TXVideoID = await this.livePusher.startCustomCapture(this.whiteboardStream);
        this.TXAudioID = await this.livePusher.startCustomCapture(this.AudioStream)
        // this.videoEffectManager.setMixingConfig({
        //   videoWidth: 1920,
        //   videoHeight: 1080,
        //   videoFramerate: 20
        // });
        // this.videoEffectManager.setLayout([{
        //     streamId: this.TXVideoID,
        //     x: this.profile[this.profileIndex].width/2,
        //     y: this.profile[this.profileIndex].height/2,
        //     width: this.profile[this.profileIndex].width,
        //     height: this.profile[this.profileIndex].height,
        //     zOrder: 2
        // },{
        //     streamId: this.TXAudioID,
        //     x: 0,
        //     y: 0,
        //     width: 0,
        //     height: 0,
        //     zOrder: 1
        // }]);
       
        this.livePusher.startPush(`webrtc://livepush-tx.cdn.ixunke.cn/ixunke/${this.liveDetail.roomId}`).then(res=>{
          console.log(res);
          this.liveStatus = true;
          this.$message.closeAll();
          this.TXLivePusherPushStatus = false;
          this.startReady = false;
          if(flag){
            this.$message.success('推流成功！！！')
          }
          this.livePusherObserverEvent()
        }).catch(err=>{
          console.log(err);
          this.TXLivePusherPushStatus = false;
          this.$message.error('推流失败,请尝试返回首页重新进入再次尝试')
          this.startReady = false;
        })
        return
    }, 
    // 计算平均值的方法
    calculateAverage(arr) {
      if (arr.length === 0) return 0;
      const sum = arr.reduce((acc, curr) => acc + curr, 0);
      return sum / arr.length;
    },

    // 检查并更新码率列表
    updateBitrateList(list, newValue) {
      list.push(newValue);
      if (list.length > 5) {
        list.shift(); // 移除最早的一个数据
      }
      return list;
    },
    // 检查码率是否正常
    checkBitrate() {
      const audioAvg = this.calculateAverage(this.TXPushAudioBitrateList);
      const videoAvg = this.calculateAverage(this.TXPushVideoBitrateList);

      if (audioAvg < 100) {
        throw new Error(`音频码率异常：当前平均值 ${audioAvg.toFixed(2)} 小于 100`);
      }

      if (videoAvg < 200) {
        throw new Error(`视频码率异常：当前平均值 ${videoAvg.toFixed(2)} 小于 200`);
      }
    },
    async livePusherObserverEvent() {
      this.livePusher.setObserver({
        onWarning: (code, msg) => {
          console.log(code, msg)
        },
        onPushStatusUpdate: async (status, msg) => {
          console.log(status, msg)
          if(status == 0){
            this.RestartLive()
          }
        },
        onStatisticsUpdate: (data) => {   
          // // 更新音频码率列表
          this.TransportData.sendBitrate = (data.video.bitrate/1000).toFixed(2);
          this.TransportData.rtt = data.video.packetSendDelay;
          console.info(data)
          
        },
      });
    },
    async RestartLive(){
      // ipcRenderer.send('showNotification', {
      //   title: '推流异常中止',
      //   body: '正在重新恢复'
      // })
      try {
        await this.livePusher.stopCustomCapture()
        await this.livePusher.stopPush();
        this.livePusher = null;
        console.log('停止直播成功')
      } catch (error) {
        console.log(error);
        console.log('停止SDK直播失败');
      }
      this.livePusher = await new TXLivePusher();
       this.TXPusherFUN(false);
    },
    async startNew(){
      this.livePusher = new TXLivePusher();
      console.log(this.livePusher)
      let PushType = store.get('PushType') || 0;
      PushType = 1;
      this.PushType = 1;
      const _this = this;
      if(this.startReady){
        this.$message.error('正在推流中，请勿重复点击');
        return
      }
      this.startReady = true;
      if(this.platform!=='win32'){
        PushType = 1;
      }
      // console.log(PushType,'PushType!!!!!!!!!!!!!!!!!!!');.
      console.log(PushType);
      if(PushType==1){
        this.TXPusherFUN();
        return
      }
      console.log('===============================');
      console.log( this.PushType);
      console.log('===============================');
      if(!this.pc){
        try {
          await this.testWSMeat();
        } catch (error) {
          this.TXPusherFUN();
        }
      }
      if(this.PushType==0){
        this.ffmpegPush();
        console.log('setRemoteDescription success');
        this.$message.closeAll();
        this.$message.success("已开始直播");
        this.liveStatus = true;
        this.liveType = 'SRS';
        // this.getTransportStatsSRS();
        return
      }else{
        try {
          this.stopRTC()
        } catch (error) {
          this.TXPusherFUN();
        }
        this.publishTest(true)
      }

    },
    async closeMediamtx(){
      return
      if(this.mediamtxServer){
        console.log('结束本地流媒体服务');
        this.mediamtxServer.kill();
        this.mediamtxServer = null;
      }
    },
    //统计网络延迟数据--srs
    getTransportStatsSRS() {
      const _this = this;
      let lastResultForStats = null;
      _this.bitrateValues = []; // 用于存储sendBitrate值
      _this.bitrateCount = 0; // 用于计数
      _this.BitrateintervalId = setInterval(() => {
        _this.pc.getStats().then(res => {
          let outboundReport;
          let candidatePair;
          
          res.forEach(report => {
            if (report.type === 'outbound-rtp' && report.kind === 'video') {
              outboundReport = report;
            }
            if (report.type === 'candidate-pair') {
              candidatePair = report;
            }
          });
          if (outboundReport) {
            const now = outboundReport.timestamp;
            const bytes = outboundReport.bytesSent;
            if (lastResultForStats) {
              const lastStats = lastResultForStats.get(outboundReport.id);
              const bytesDiff = bytes - lastStats.bytesSent;
              const timeDiff = (now - lastStats.timestamp) / 1000;
              const bitrate = (bytesDiff / timeDiff / 1024 * 8).toFixed(2);
              _this.TransportData.sendBitrate = bitrate;
              _this.bitrateValues.push(parseFloat(bitrate));
              if (++_this.bitrateCount % 10 === 0) {
                const averageBitrate = _this.bitrateValues.reduce((a, b) => a + b, 0) / _this.bitrateValues.length;
                if (averageBitrate < 80) {
                  _this.WeakTips = true;
                } else {
                  if (_this.TransportData.rtt <= 1500) {
                    _this.WeakTips = false;
                  }
                }
                _this.bitrateValues = [];
              }
            }
          }
          if (candidatePair) {
            const latency = Math.round(candidatePair.currentRoundTripTime * 1000); // Convert to milliseconds
            _this.TransportData.rtt = latency;
            // 检查rtt是否大于1500ms
            if (latency > 1500) {
              _this.WeakTips = true;
            }
          }

          lastResultForStats = res;
        });
      }, 1000);

    },
    // 处理url参数
    parseQueryString(queryString) {
      const query = {};
      const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }

      return query;
    },
    async RNNoiseStream(stream) {
      // console.log(stream.getAudioTracks()[0]);
      // const RNNoiseTrack = stream.getAudioTracks()[0];
      // 延迟两秒remove
      setTimeout(() => {
        // if(!this.HRTCstream){return}
        
        
      }, 2000);
    },
    async ChangePushType(PushType){
      console.log(PushType,'+++++++++++++++++++++')
      this.PushType = PushType;
      if(PushType==0){
        try {
          await this.stopRTC();

        } catch (error) {
          
        }
       
        this.btnStartRecordClicked()
      }else{
        await this.stopRTC();
        this.initClientEvent()
      }
    },
    async StopRNNoise() {

    },
    //关闭美颜
    async CloseBeauty() {
      this.$refs.cameraStream.srcObject = this.LocalCameraStream;
      this.$refs.cameraStream.play();
      this.BeautyFlag = false;
    },
    async GetBeautyCanvas() {

      let tmpCanvas = document.getElementById("BeautyCanvas"); // console.log(tmpCanvas.captureStream(30));
      if (tmpCanvas) {
        this.BeautyFlag = true;
        this.$refs.cameraStream.srcObject = await tmpCanvas.captureStream(30);
        await this.$refs.cameraStream.play();        
      }
    },
    //修改摄像头位置
    DetermineMode() {
      this.widthPercentage = store.get("widthPercentage") || 0.22;
      this.HeightPercentage = store.get("HeightPercentage") || 0.39;
      this.top = store.get("top") || 0;
      this.left = store.get("left") || 0;
      console.log(
        this.widthPercentage,
        this.HeightPercentage,
        this.top,
        this.left
      );
      if (this.CameraStatus) {
        console.log("混合摄像头");
        this.mixCamera();
      }
    },
    // 销毁画板
    destroyboard() {
      this.CoursewareCount = 0;
      
      this.fabricObj.setBackgroundImage(null);
      this.fabricObj.renderAll();
      this.defaultwhiteboardImg = null;
      return
    },
    // 结束连麦
    changeColor() {
      console.log(this.drawColor);
      this.fabricObj.freeDrawingBrush.color = this.drawColor;
    },
    // 显示课件列表
    ShowPPTList() {
      this.$refs.UploadCourseware.checkdialogVisible();
    },
    async checkLiveprofile(index) {
      this.profileIndex = index;
      console.log(this.profileIndex);
      if(this.PushType==0){
        await this.stopRTC();
        await this.testWSMeat();
      }
      let t = this.profileNmae[index];
      this.$message.success(`切换清晰度为${t}`)
    },
    // 取消静音
    Unmute() {
      this.$message.closeAll();
      this.AudioStream.getTracks()[0].enabled = true;
      if (this.SelectMenu == 3 && this.isAuxiliaryAudio) {
        console.log('正在共享屏幕声音');

        this.AudioStream.getTracks()[0].enabled = true;
        this.$message.success("已解除麦克风静音");
        this.AudioState = true;
        return
      }
      try {       
          this.whiteboardStream.getAudioTracks()[0].enabled = true;
      } catch (error) {
        console.log(error);
      }
     
        this.muteFlag = false;
        console.log('华为取消静音');
        this.AudioState = true;
        try {
          this.audioSender.replaceTrack(this.mergeAudioTrack);
        } catch (error) {
          console.log(error);
        }
        try {
          this.audioSender.track.enabled = true;
          console.log('webrtc取消静音！');
        } catch (error) {
          console.log(error);
          console.log('webrtc error');

        }
        return
      
      try {
        this.mergeAudioTrack.enabled = true;
      } catch (error) {

      }
      try {
        this.AudioStream.getTracks()[0].enabled = true;
      } catch (error) {

      }

      this.AudioState = true;
      this.$message.success("已取消静音");
    },
    // 静音
    mute() {
      this.$message.closeAll();
      this.AudioStream.getTracks()[0].enabled = false;
      try {
          this.whiteboardStream.getAudioTracks()[0].enabled = false;
      } catch (error) {
        console.log(error);
      }
      if (this.SelectMenu == 3 && this.isAuxiliaryAudio && this.AudioState) {
        console.log('正在共享屏幕声音');
        this.AudioStream.getTracks()[0].enabled = false;
        this.$message.success("已开启麦克风静音");
        this.AudioState = false;
        return
      }
      if (!this.AudioState) {
        this.Unmute()
        return
      }
    
      this.muteFlag = true
      console.log('华为云已经静音');
      this.AudioState = false;
      
      try {
        if(this.lianmai.state){
          this.AudioStream.getTracks()[0].enabled = false;
          if(this.audioSender){
            this.audioSender.replaceTrack(this.remoteAudioTrack);
          }
         
        }else{
          this.AudioStream.getTracks()[0].enabled = false;
          if(this.audioSender){
            this.audioSender.track.enabled = false;
          }
         
        }
        console.log('webrtc本地麦克风已经静音');
      } catch (error) {
        console.log(error);
        console.log('麦克风静音失败');
      }
    },
    // 结束直播清空内存
    async LeaveLive() {
      try {
       
        this.AudioStream.getAudioTracks().forEach(track => {
          // console.log(track);
          track.stop()
        })
        this.AudioStream = null;
        
        this.LocalCameraStream.getTracks().forEach((track) => {
          track.stop();
        });
        this.LocalCameraStream = null;
        console.log(this.LocalCameraStream);
        this._stream.getTracks().forEach(track => {
          track.stop()
        })

      } catch (error) {
        console.log(error);
      }
      try {
        await this.btnStopRecordClicked(false)
      } catch (error) {
        console.error(error);
      }

      try {
        cancelAnimationFrame(this.animationId ?? 0);
      } catch (error) {
        
      }
      // 关闭本地流媒体服务
      try {
          await this.closeMediamtx()
        } catch (error) {
          console.log(error);
        }
        // 关闭推流
        try {
          await this.stopFFmpeg();
        } catch (error) {
          
        }
      this.$router.replace({
        name: "course"
      })

    },

    async closeLive() {
      // console.log(this.liveStatus);
      if (this.liveStatus) {
        this.CloseLivePage = true;
        console.log("正在直播");
        return false;
      } else {
        console.log("开始退出页面");
        await this.LeaveLive()
      }
      ipcRenderer.send('destoryIMView')
    },
    // 开启摄像头
    async TestingComplete(value) {
      try {
        this.DeviceConfig = store.get("device");
        console.log(this.DeviceConfig);
        if(this.DeviceConfig[0]==''){
          this.CameraStatus = false;
          console.log('不存在摄像头');
          this.addP2PM0()
          return
        }
      } catch (error) {
        this.DeviceConfig = {};
      }
      // console.log(this.DeviceConfig, "-------------");

      // 开启摄像头
      if (this.DeviceConfig[0]) {     
        await this.openCamera(true, this.DeviceConfig[0]);
        try {
          this.$refs.cameraStream.pause();
          this.$refs.cameraStream.srcObject = null;
          console.log('pause');
        } catch (error) {
          
        }
  
          try {
          this.$refs.cameraStream.srcObject = this.LocalCameraStream;
          this.$refs.cameraStream.play();
        } catch (error) {
          
        }
    
       
      }

      setTimeout(() => {
        if(this.PushType==0){
          console.log('开始rtc--->rtsp前置');
          this.btnStartRecordClicked()
        }else{
          console.log('华为云推流,不需要前置');
        }
        
      }, 300);
    },
    async ENDLive() {
      if (this.SelectMenu == 3) {
        await this.EndSharingScreen();
      }

      await this.ENDLiveCheck();
      // this.$router.push({ name: "course" });
      ipcRenderer.send("closeLiveWin");
    },
    async goback() {
      console.log(this.PushType,'this.PushType')
      
      try {
        await this.livePusher.stopCustomCapture()
        await this.livePusher.stopPush();
        this.livePusher = null;
        console.log('停止直播成功')
      } catch (error) {
        console.log(error);
        console.log('停止SDK直播失败');
      }
      
   
      if (this.SelectMenu == 3) {
        await this.EndSharingScreen();
      }
      try {
        this.client.leave(this.liveDetail.roomId);
      } catch (error) { }
      try {
        if (this.lianmai.state) {
          await this.closeLianmai()
        }
      } catch (error) {

      }

      await this.LeaveLive();

    },
    handleClose() {
      this.CloseLivePage = false;
    },
    // 菜单列表选择摄像头
    SelectCamera() {
      this.SelectMenu = 1;
    },
    //计算已经开始多久
    startTimer() {
      // 获取当前时间
      let currentTime = new Date();
      // 计算时间差
      let timeDiff = currentTime.getTime() - this.LiveStartTime;

      // 计算小时数，取整并补零
      const hours = Math.floor(timeDiff / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");

      // 计算分钟数，取整并补零
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0");

      // 计算秒数，取整并补零
      const seconds = Math.floor((timeDiff / 1000) % 60)
        .toString()
        .padStart(2, "0");

      // 输出时间差
      this.timeDifference = `${hours}:${minutes}:${seconds}`;
      currentTime = null;
      timeDiff = null;
      // console.log(`已经开始了 `);
    },

    // 结束直播事件上报
    EndLiveAxios() {
      var axiosUrl;
      if (this.rootInfo.liveType == "live") {
        axiosUrl = "/api/v1/room/edit_stream";
        var subData = {
          id: this.rootInfo.lessonId,
          liveEnd: 1,
        };
      } else {
        axiosUrl = "/api/v1/channel/close_live";
        var subData = {
          lessonId: this.rootInfo.lessonId,
        };
      }
      ixunkeAxios(subData, "post", axiosUrl).then((res) => {
        // console.log(res);
        this.$notify({
          title: "直接已结束",
          message: `直接已结束，直播时长${this.timeDifference}`,
          type: "success",
          duration: 500,
          offset: 100,
        });
        //处理停止逻辑
        this.btnStopRecordClicked();
      });
    },
    // 结束直播事件
    ENDLiveCheck() {


      this.CloseLivePage = true;
      // console.log(this.endTime);
      // // 获取当前时间（北京时间）
      // const now = new Date(
      //   new Date().toLocaleString("en-US", { timeZone: "Asia/Shanghai" })
      // );
      // 获取当前时间的时间戳
      // const timestamp = now.getTime();
      // console.log(timestamp);
      // if (timestamp < this.endTime) {
      // this.$confirm("请再次确认是否结束直播", "确认结束直播", {
      //   distinguishCancelAndClose: true,
      //   showClose: false,
      //   confirmButtonText: "离开直播",
      //   cancelButtonClass: "colseLiveButton",
      //   cancelButtonText: "结束直播",
      //   type: 'warning'
      // })
      //   .then(() => {
      //     console.log("暂停直播");
      //     this.btnStopRecordClicked();
      //   })
      //   .catch((action) => {
      //     this.EndLiveAxios();
      //     console.log("停止直播");
      //   });
      // } else {
      //   this.EndLiveAxios();
      //   console.log("停止直播");
      // }
    },
    checkKJ() {
      this.SelectMenu = 2;
      if (this.CoursewareList?.length > 0) {
        return;
      }
      this.$refs.UploadCourseware.checkdialogVisible();
    },
    // 停止直播
    btnStopRecordClicked(x) {
      this.Videomerger = null;
      if(x){
        this.stopCamera(this.LocalCameraStream);
      }
      
      this.ENDvisible = false;
      this.AudioState = false;
      ipcRenderer.send("closeTipsWindow", "");
    
      try {
        this.stopRTC()
      } catch (error) {

      }

      clearInterval(this.timer);
      clearInterval(this.BitrateintervalId);
      clearInterval(this.checkvideo1El);
      this.liveStatus = false;
    },
    onMetadataLoaded() { },
    // 视频媒体流替换
    async replaceTrack(replaceTrack) {
      //  console.log( this.VideomergerStream);
      if (!this.VideomergerStream) {
        return;
      }
    },

    async refreshDPList() {
      this.getWindows();
    },
    // 结束共享屏幕
    async EndSharingScreen() {
      ipcRenderer.send("closeTipsWindow", "");
      try {
        clearInterval(this.checkvideo1El);
      } catch (error) {
        
      }
      if (this.shareDPStatus) {
        try {
          this.shareDPStream.getTracks().forEach((track) => {
            track.stop();
          });
          this.shareDPStream = null;
          console.log(this.shareDPStream);
          this.source1.disconnect(this.destination);
          this.source1.mediaStream.getTracks().forEach(track => track.stop());
          this.source1 = null;
          this.mergeAudioTrack = this.destination.stream.getAudioTracks()[0];
          if(this.audioSender){
            console.log('替换audioSender')
            this.audioSender.replaceTrack(this.mergeAudioTrack)
          }
        }catch (error) {
          
        }
        try {
          this.fabricObj.remove(this.fabricM3)
        } catch (error) {
          
        }
        this.shareDPStatus = false;
        this.combinedSource = null;
        this.mergeAudioTrack = null;
      }
      
    },
    showWindowSetting() {
      this.$refs.Setting.changeModeCheck();
      // this.$refs.WindowSetting.changeModeCheck();
      // window.open('https://baidu.com','_blank','width=400,height=300,title="设置",frame=false,nodeIntegration=no')
    },
    async PreviousFastboard() {
      console.log("上一页白板");
      if (this.defaultwhiteboardIndex == 0) return;
      this.defaultwhiteboardIndex = this.defaultwhiteboardIndex - 1;
      this.defaultwhiteboardImg =
        this.CoursewareList[this.defaultwhiteboardIndex];
      this.fabricHistoryJson = [];
      this.mods = -1;
      // this.initCanvas();
     await this.changeFabricBg();
    },

    // 获取课件list
    async getCoursewareList(id,platform,taskId) {
      // console.log(id);
      console.log(platform,'------platform-----');
      if(platform=='Agora'){
        console.log('加载动效课件');
        this.defaultwhiteboardIndex = 1;
        if(this.fabricM9){
          await this.fabricObj.remove(this.fabricM9)
          
          this.fabricM9 = null;
        }
        this.CreateAgoraCanvas(taskId)
        console.log(`https://ixunke-courseware-sh.oss-cn-shanghai.aliyuncs.com/dynamicConvert/${taskId}/preview/1.png`);
        this.AgoraPerview = true;
        return
      }
      this.AgoraPerview = false;
      const axiosUrl = "/api/courseware/detail";
      let subData = {};
      subData.id = id;
      subData.imgs = true;
      ixunkeAxios(subData, "get", axiosUrl).then(async (res) => {
        // console.log(res);
        if (res.errmsg == 1000) {
          this.$message.warning(res.errmsg);
        }
        // console.log(res.data);
        this.CoursewareCount++;
        this.CoursewareList = res.data;
        this.CoursewareListLength = this.CoursewareList.length;

        this.defaultwhiteboardImg = res.data[0];
        // console.log('获取到this.defaultwhiteboardImg--------------');
        this.defaultwhiteboardIndex = 1;
        if (!this.fabricObj) {
          console.log('初始化canvas');
          await this.initCanvas()
        } else {
          // console.log('不需要初始化canvas');
          if(this.SelectMenu==2){
              this.changeFabricBg()
          }
        
        }

        // this.initCanvas();
        // console.log(
        //   this.CoursewareListLength,
        //   "------this.CoursewareListLength---------"
        // );
      });
    },
    // 获取课件ID
    async getCoursewareID() {
      const axiosUrl = "/api/courseware/list";
      let subData = {};
      subData.roomId = this.rootInfo.lessonId;
      subData.page = 1;
      subData.pageSize = 99;
      await ixunkeAxios(subData, "get", axiosUrl).then((res) => {
        this.CoursewareCount = res.count || 0;
        if (res.count == 0) {
          // this.checkKJ();
        }
        // console.log(this.CoursewareCount);
        // console.log(res.data[0].id);
        if (res.data.length == 0) return;
        this.CoursewareID = res.data[0].id;
        this.Courseware = res.data;
        // console.log("-------------------");
        // console.log(this.Courseware);
        if (this.CoursewareCount > 0) {
          this.getCoursewareList(res.data[0].id);
        } else {
        }
      });
    },
    async mergeAudio() {
        const DeviceNumber = store.get("AudioDeviceNumber");
        if (DeviceNumber == 0) {
          console.log("没有麦克风设备,使用分享流");
          // this.mergeAudioTrack = this.shareDPStream.getAudioTracks()[0];
        } else {
          console.log("存在麦克风声音");
          // console.log(this.LoopbackRecordStream)
          // console.log(this.LoopbackRecordStream.getAudioTracks()[0])
          // const track1 = this.LoopbackRecordStream.getAudioTracks()[0];
          console.log(this.shareDPStream.getAudioTracks()[0],'dpaudio')
          const track1 = this.shareDPStream.getAudioTracks()[0];
          this.source1 = this.audioContext.createMediaStreamSource(new MediaStream([track1]));
          // source2.connect(this.destination);
          this.source1.connect(this.destination);
        
          const newMediaStream = new MediaStream();
          this.AudioStream.getAudioTracks().forEach(track => {
            newMediaStream.addTrack(track.clone());
          });
          
          // this.mergeAudioTrack = newMediaStream.getAudioTracks()[0];
          this.mergeAudioTrack = this.destination.stream.getAudioTracks()[0];

          // this.mergeAudioTrack = this.audioStream

          //删除腾讯云白板音频流
          try {
            this.livePusher.stopCustomCapture(this.TXAudioID)
            this.TXAudioID = null;
            this.TXAudioID = this.livePusher.startCustomCapture(this.destination.stream)
            // this.videoEffectManager.setLayout([{
            //     streamId: this.TXVideoID,
            //     x: this.profile[this.profileIndex].width/2,
            //     y: this.profile[this.profileIndex].height/2,
            //     width: this.profile[this.profileIndex].width,
            //     height: this.profile[this.profileIndex].height,
            //     zOrder: 2
            // },{
            //     streamId: this.TXAudioID,
            //     x: 0,
            //     y: 0,
            //     width: 0,
            //     height: 0,
            //     zOrder: 1
            // }]);
          } catch (error) {
            console.log(error,'删除腾讯云白板音频流失败');
          }
          try {
            if(this.audioSender){
              console.log('替换audioSender')
              this.audioSender.replaceTrack(this.mergeAudioTrack)
            }
          } catch (error) {
            console.log(error,'替换audioSender失败')
          }  
        }    
    },
    AudioMixer() {
      try {
        // 创建高质量音频上下文，明确采样率确保稳定性
        const audioContextOptions = {
          latencyHint: 'interactive',
          sampleRate: 48000  // 使用标准高质量采样率
        };
        this.audioContext = new AudioContext(audioContextOptions);
        
        // 确保已存在的音频轨道
        if (!this.AudioStream || !this.AudioStream.getTracks().length) {
          console.error('AudioMixer: 没有可用的音频轨道');
          return;
        }
        
        const track2 = this.AudioStream.getTracks()[0];
        
        // 记录原始音频轨道的时间戳和配置
        console.info('音频轨道设置:', track2.getSettings());
        
        // 创建媒体流源并应用音频处理器
        this.source2 = this.audioContext.createMediaStreamSource(new MediaStream([track2]));
        
        // 创建具有精确时间缓冲控制的目标节点
        this.destination = this.audioContext.createMediaStreamDestination();
        
        // 创建增益节点并正确配置
        this.gainNode2 = this.audioContext.createGain();
        this.gainNode2.gain.value = (this.MicrophoneVolume/100);
        
        // 添加音频处理器节点来控制时间戳
        const bufferSize = 1024;
        const processorNode = this.audioContext.createScriptProcessor(
          bufferSize, // 缓冲区大小 - 较小的缓冲区可提供更低的延迟
          1,         // 输入声道数 - 通常默认为单声道
          1          // 输出声道数
        );
        
        // 时间戳校准
        let initialTimestamp = null;
        let audioFrameCount = 0;
        
        processorNode.onaudioprocess = (audioProcessingEvent) => {
          const inputBuffer = audioProcessingEvent.inputBuffer;
          const outputBuffer = audioProcessingEvent.outputBuffer;
          
          // 进行缓冲区复制
          for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
            const inputData = inputBuffer.getChannelData(channel);
            const outputData = outputBuffer.getChannelData(channel);
            
            // 直接复制数据，也可以在这里应用特殊处理
            for (let sample = 0; sample < inputBuffer.length; sample++) {
              outputData[sample] = inputData[sample];
            }
          }
          
          // // 记录并维护时间戳
          // if (initialTimestamp === null) {
          //   initialTimestamp = performance.now();
          // }
          
          // audioFrameCount++;
          
          // // 每100帧记录一次，用于调试
          // if (audioFrameCount % 100 === 0) {
          //   const currentTime = performance.now();
          //   const elapsedTime = currentTime - initialTimestamp;
          //   const sampleRate = this.audioContext.sampleRate;
          //   const expectedTime = (audioFrameCount * bufferSize / sampleRate) * 1000;
            
          //   // 计算时间偏移，这对调试非常有用
          //   const drift = elapsedTime - expectedTime;
          //   console.info(`音频处理统计 - 帧数: ${audioFrameCount}, 偏移: ${drift.toFixed(2)}ms`);
          // }
        };
        
        // 建立处理链
        this.source2.connect(this.gainNode2);
        this.gainNode2.connect(processorNode);
        processorNode.connect(this.destination);
        
        // 确保新轨道有正确的约束和设置
        const newMediaStream = new MediaStream();
        const clonedTrack = this.destination.stream.getAudioTracks()[0];
        
        // 如果需要设置轨道约束，可以在这里应用
        // 设置更高的优先级以确保稳定的处理
        if (typeof clonedTrack.contentHint === 'string') {
          clonedTrack.contentHint = 'speech'; // 针对语音进行优化
        }
        
        newMediaStream.addTrack(clonedTrack);
        this.mergeAudioTrack = newMediaStream.getAudioTracks()[0];
        
        // 记录最终轨道设置，帮助调试
        console.info('合并后音频轨道设置:', this.mergeAudioTrack.getSettings());
        
      } catch (error) {
        console.error('AudioMixer 初始化失败:', error);
        // 如果出错，尝试使用原始轨道作为备用
        if (this.AudioStream && this.AudioStream.getAudioTracks().length > 0) {
          this.mergeAudioTrack = this.AudioStream.getAudioTracks()[0];
        }
      }
    },
    
    async luzhi() {
      // return false;
      const { writeFile } = window.require("fs");
      const path = window.require("path");
      const recordStream = new MediaStream([this.remoteAudioTrack]);
      // const recordStream = this.remoteAudio;
      
      const recorder = new MediaRecorder(recordStream, {
        mimeType: "video/webm; codecs=avc1.42E01F",
      });
      // 存储录制的数据块
      let chunks = [];

      // 当数据可用时，将其添加到chunks数组中
      recorder.ondataavailable = function (event) {
        chunks.push(event.data);
      };

      // 当录制停止时，处理并保存文件
      recorder.onstop = function () {
        const blob = new Blob(chunks, { type: "video/webm" });
        const filePath = path.join(__dirname, "mergeAudioTrack.webm"); // 保存到桌面，文件名为1.webm

        // 将Blob转换为Buffer
        const reader = new FileReader();
        reader.onloadend = function () {
          const buffer = Buffer.from(reader.result);

          // 保存Buffer到文件系统
          writeFile(filePath, buffer, (err) => {
            if (err) {
              console.error("保存文件失败:", err);
            } else {
              console.log("文件已保存:", filePath);
            }
          });
        };
        reader.readAsArrayBuffer(blob);

        // 如果需要转换为MP3，你需要在这里添加转换逻辑
      };
      // console.log("开始录制");
      // 开始录制
      // await recorder.start();

      // 设置定时器停止录制
      setTimeout(() => {
        recorder.stop();
      }, 10000); // 10秒后停止录制
      return;
    },
    //确定屏幕共享窗口 获取流媒体对象
    async choiceDP() {
      
      if(this.shareDPStream){
        this.shareDPStream.getTracks().forEach(track => track.stop());
        this.shareDPStream = null;
      }
      try {
        await this.fabricObj.remove(this.fabricM3)
      } catch (error) {

      }

      var index = this.selectedIndex;
      var shareDPId = this.shareDPList[index].id;
      var str = shareDPId;
      const regex = /window:(\d+):/;
      const match = str.match(regex);
      var handle;
      if (match !== null) {
        handle = parseInt(match[1]);
        console.log(handle); // 输出 2362064
      }
      this.shareDPStatus = true;
      // console.log(this.shareDPList);
      console.log(shareDPId);
      await ipcRenderer.send("CheckShareDP",this.shareDPList[index]);
      let LocalBuildID = store.get("buildID");
     
      let audioConfig;
      if (this.platform == 'darwin' || LocalBuildID==12007) {
        audioConfig = false
      } else {
        audioConfig = {
          mandatory: {
            chromeMediaSource: "desktop",
            chromeMediaSourceId:shareDPId
          },
        }
      }
      var audioflag
      // if(this.platform=='win32'){
      //   audioflag = false;
      // }else{
      //   audioflag = false;
      // }
      var shareDPConfig = {
        audio: true,
        video: {
          // mandatory: {
          //   chromeMediaSource: "desktop",
          //   chromeMediaSourceId: shareDPId,
          //   minWidth: 1920,
          //   minHeight: 1080,
          //   maxWidth: 1920,
          //   maxHeight: 1080,
          // },
          width: 1920,
          height: 1080,
          frameRate: 30
        },
      };
      try {
        this.shareDPStream = await navigator.mediaDevices.getDisplayMedia(
          shareDPConfig
        );
      } catch (error) {
       console.log(error,'----------  error  ----------');
      }
      try {
        console.log(this.shareDPStream,'----------  this.shareDPStream  ----------');
        console.log(this.shareDPStream.getAudioTracks()[0])
        console.log(this.shareDPStream.getAudioTracks()[0].getSettings())
      } catch (error) {
        console.log(error)
      }
      
      this.$refs.playerBox.srcObject = this.shareDPStream;
      if (this.isAuxiliaryAudio ) {
        // if(this.platform=='win32'){
        //  await ipcRenderer.invoke('enableLoopbackRecording');
        // }
          this.mergeAudio();
          console.log("开始合并声音");
          if (this.RNNoiseFlag) {
            this.$message.warning("共享声音无法同时使用降噪,已关闭降噪功能");
            await this.$refs.Setting.rnnoiseStop();
          }
      
      }

      ipcRenderer.send("createTipsWindow", "");

      if (index == 0) {
      } else {
        console.log("TopWindow");
        // ipcRenderer.send("test", "test");
        ipcRenderer.send("TopWindow", [this.shareDPList[index].name, handle]);
        // ipcRenderer.send("destroyTmpWindow", "");
      }

      this.shareDPVisible = false;
      setTimeout(() => {
        this.FabricAddShare();

      }, 300);
    },
  
    // 关闭摄像头
    async stopCamera(mediaStream) {
      console.log("执行关闭摄像头逻辑");
      if (mediaStream) {
        console.log('关闭摄像头');
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
        mediaStream = null;
      }
      console.log(mediaStream);
    
      this.CameraStatus = false;
      this.fabricHiddenM1()
    },
    async closeCamera() {
      if (!this.CameraStatus) {
        this.openCamera(true);
        return
      }

      console.log("执行关闭摄像头函数");
      this.stopCamera(this.LocalCameraStream);
      this.$refs.cameraStream.pause();

      this.addP2PM0()
    },
   
    // 开启摄像头
    async openCamera(flag, id) {
      console.log(id,'-------');
      // if(this.LocalCameraStream){
      //   this.LocalCameraStream.getTracks().forEach((track) => {
      //     track.stop();
      //   });
      // }
     
      // console.log(this.DeviceConfig[0]);
      try {
        this.LocalCameraStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: id || this.DeviceConfig[0],
            width: {
              min: 640,
              ideal: 1920,
              max: 1920,
            },
            height: {
              min: 480,
              ideal: 1080,
              max: 1080,
            },
            frameRate:{ideal:24,max:40,min:15}
          },
          audio: false,
        });
      } catch (error) {
        console.log("------error---------");
        console.log(error);
        this.$message.error('摄像头获取失败')
        this.CameraStatus = true
        this.closeCamera()

        this.stopRTC()
      }
    
      console.log( this.LocalCameraStream.getVideoTracks()[0].getSettings()  );
      console.log('======== this.LocalCameraStream======');
      // 假设 `stream` 是你的 MediaStream 对象
      let videoTrack = this.LocalCameraStream.getVideoTracks()[0]; // 获取第一个视频轨道

      if (videoTrack) {
        let settings = await videoTrack.getSettings(); // 获取轨道设置
        // this.localTrackWidth = settings.width;
        // this.localTrackHeight = settings.height;
        // console.log(`Resolution: ${settings.width}x${settings.height}`); // 打印分辨率
        // console.log((settings.width/settings.height).toFixed(1));
        // this.lengthWidthRatio = await (settings.width / settings.height).toFixed(1);
        // console.log(this.lengthWidthRatio, "长宽比");
        // store.set("lengthWidthRatio", this.lengthWidthRatio);
      } else {
        console.log("No video track available");
      }
      // await this.mergeVideo();
      if (!this.LocalCameraStream) {
        console.log("无可用视频输入设备");
        this.CameraStatus = false;
        this.ExistCamera = false;
        return;
      }
      try {

        this.CameraStatus = true;
        this.$refs.cameraStream.srcObject = this.LocalCameraStream;
        this.$refs.cameraStream.play();
      } catch (error) {
        console.log(error);
        console.log('摄像头失败');
      }
      // if (this.SelectMenu == 1) {
        // console.log('添加摄像头');
       
        this.isRendering = true;
        // console.log(this.fabricM1);
        // if(!this.fabricM1){
        this.fabricObj.remove(this.fabricM1)
        await this.FabricAddCamera();
        if(this.lianmai.state){
          let scaleX = 0.5;
          let scaleY = 1;
          let left = 0;
          if(this.SelectMenu!==1){
            scaleX = 0.15;
            scaleY = 0.15;
            left = 637;
          }
         
            this.fabricM1.set({
              scaleX: scaleX,
              scaleY: scaleY,
              width: 1920,
              height: 1080,
              top:0,
              left:left,
              selectable:true,
            })
          
        }else{
          
        }
        // }
        
        this.fabricObj.renderAll()

      // } else {

      // }
    },
    FabricAddCamera(){
      console.log('添加摄像头！！！！');
       // 创建自定义的 fabric.Image 子类
      var CustomImage = fabric.util.createClass(fabric.Image, {
    _render: function(ctx) {
        var x, y, imageMargins = this._findMargins(), elementToDraw;

        // // Calculate the starting point
        x = -imageMargins.containerWidth / 2;
        y = -imageMargins.containerHeight / 2;
        // console.log(x, y, 'x,y');
        if (this.meetOrSlice === 'slice') {
            ctx.beginPath();
            ctx.rect(x, y, 1920, 1080);
            ctx.clip();
        }

        elementToDraw = this._element;
        elementToDraw && ctx.drawImage(elementToDraw,
                                      x + imageMargins.marginX,
                                      y + imageMargins.marginY,
                                      1920,
                                      1080
                                      );

        this._renderStroke(ctx);
    },

    _findMargins: function() {
        var containerWidth = 1920;
        var containerHeight = 1080;
        var marginX = 0, marginY = 0;

        // Directly set the width and height to fill the container
        var width = containerWidth;
        var height = containerHeight;

        // No margins needed since we are filling the whole container
        marginX = 0;
        marginY = 0;

        return {
          width: 1920,
          height: 1080,
          marginX: 0,
          marginY: 0,
          containerWidth: 1920,
          containerHeight: 1080
        };
    }
});


      try {
        this.CameraMirrorMode = store.get('CameraMirrorMode')||false;
        console.log('----------CameraMirrorMode---------',this.CameraMirrorMode);
      } catch (error) {
        this.CameraMirrorMode = false;
      }
      const _this = this;
      var video1El = document.getElementById('cameraStream');
      console.log(video1El);
      // console.log(video1El);
      // this.SelectMenu = 1
      var scaleX = 1;
      var scaleY = 1;
      var left = 0;
      var top = 0;
      if(this.SelectMenu !== 1){
        scaleX = 0.22;
        scaleY = 0.22;
        left = this.m1Left;
        top = this.m1Top;
      }
      console.log(scaleX,scaleY,'scaleX,scaleY');
      // new fabric.Image
      var video1 = new CustomImage(video1El, {
          left: left,
          top: top,
          scaleX:scaleX,
          scaleY:scaleY,
          width:1920,
          height:1080,
          objectCaching: false,
          selectable:false,
          selection:false, 
          id:'m1',
          hoverCursor:'default',
          transparentCorners: false,  //实心角
          cornerColor: 'red',  //角颜色
          cornerStrokeColor: 'red', //角边框颜色
          cornerSize: 8, //角大小
          borderColor: 'red', //辅助线颜色
          padding: -2,
          borderScaleFactor: 2, //辅助线宽度
          lockMovementX: true,
          lockMovementY: true,
      });
      video1.setControlVisible('mtr', false) //隐藏旋转角
      video1.moveTo(0)
      
      this.fabricM1 = video1;
      this.fabricM1.on('mousedown', function(opt) {
          // console.log('元素被点击');

          
          _this.fabricM1.moveTo(0);
          
          _this.fabricM1.bringToFront();
          _this.fabricObj.renderAll();
      });
      if(this.CameraMirrorMode){
        video1.set('flipX', true)
      }
   
      this.fabricObj.add(video1);
      this.fabricObj.renderAll()
      // console.log(this.fabricObj?.getObjects());
      if (!this.animationId) {
          // console.log('不存在动画，开始渲染');
          this.fabricRender();
      }
      // this.changeFabricBg()
    },
    //移除摄像头
    fabricHiddenM1(){
      this.fabricObj.remove(this.fabricM1);
      this.fabricM1 = null;
    },
    //增加屏幕共享
    async FabricAddShare(){
      const _this = this;
      var CustomImage = fabric.util.createClass(fabric.Image, {
        _render: function(ctx) {
            var x, y, imageMargins = this._findMargins(), elementToDraw;

            x = -imageMargins.containerWidth / 2;
            y = -imageMargins.containerHeight / 2;

            if (this.meetOrSlice === 'slice') {
                ctx.beginPath();
                ctx.rect(x, y, imageMargins.containerWidth, imageMargins.containerHeight);
                ctx.clip();
            }

            elementToDraw = this._element;
            elementToDraw && ctx.drawImage(elementToDraw,
                x + imageMargins.marginX,
                y + imageMargins.marginY,
                imageMargins.width,
                imageMargins.height
            );

            this._renderStroke(ctx);
        },

        _findMargins: function() {
            var containerWidth = 1920;
            var containerHeight = 1080;
            var width = this._element.width;
            var height = this._element.height;
            var SX = containerWidth / width;
            var SY = containerHeight / height;
            var imageRatio = width / height;
            var containerRatio = containerWidth / containerHeight;
            var scale, marginX = 0, marginY = 0;

            // 特殊处理：当宽度比例为1，且高度比例大于0.95时
            if (Math.abs(SX - 1) < 0.01 && SY > 0.95) {
                scale = 1;
                // 强制铺满
                return {
                    width: containerWidth,
                    height: containerHeight,
                    marginX: 0,
                    marginY: 0,
                    containerWidth: containerWidth,
                    containerHeight: containerHeight
                };
            }

            // 根据长宽比例决定适配方式
            if (imageRatio > containerRatio) {
                // 图片更宽，以宽度铺满
                scale = SX;
                var scaledHeight = height * scale;
                marginX = 0;
                marginY = (containerHeight - scaledHeight) / 2;
            } else {
                // 图片更高，以高度铺满
                scale = SY;
                var scaledWidth = width * scale;
                marginY = 0;
                marginX = (containerWidth - scaledWidth) / 2;
            }

            // 判断是否需要缩放
            if (width <= containerWidth && height <= containerHeight) {
                // 小图片保持原始尺寸
                if (Math.abs(scale - 1) < 0.01) {
                    scale = 1;
                    marginX = (containerWidth - width) / 2;
                    marginY = (containerHeight - height) / 2;
                }
            }

            return {
                width: width * scale,
                height: height * scale,
                marginX: marginX,
                marginY: marginY,
                containerWidth: containerWidth,
                containerHeight: containerHeight
            };
        }
      });
      // 创建一个包装类来处理视频尺寸变化
        class VideoWrapper {
            constructor(videoEl, canvas) {
                this.videoEl = videoEl;
                this.canvas = canvas;
                this.customImage = null;
                this.resizeObserver = null;
                this.init();
            }

            init() {
                // 创建ResizeObserver监听视频尺寸变化
                this.resizeObserver = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        this.updateVideoDimensions();
                    }
                });
                
                // 开始监听视频元素
                this.resizeObserver.observe(this.videoEl);

                // 监听视频的loadedmetadata事件
                this.videoEl.addEventListener('loadedmetadata', () => {
                    this.updateVideoDimensions();
                });

                // 首次创建CustomImage
                this.createCustomImage();
            }

            updateVideoDimensions() {
                // 更新视频元素的尺寸属性
                const width = this.videoEl.videoWidth || this.videoEl.offsetWidth;
                const height = this.videoEl.videoHeight || this.videoEl.offsetHeight;
                
                this.videoEl.style.width = `${width}px`;
                this.videoEl.style.height = `${height}px`;
                this.videoEl.width = width;
                this.videoEl.height = height;

                // 如果CustomImage已存在，更新它
                if (this.customImage) {
                    // 触发重新渲染
                    this.customImage._element = this.videoEl;
                }
            }

            createCustomImage() {
                this.customImage = new CustomImage(this.videoEl, {
                    left: 0,
                    top: 0,
                    width: 1920,
                    height: 1080,
                    meetOrSlice: 'meet',
                    alignX: 'Mid',
                    alignY: 'Mid',
                    id: 'm3',
                    objectCaching: false,
                    selectable: false,
                    backgroundColor: 'black'
                });
                
                return this.customImage;
            }

            destroy() {
                if (this.resizeObserver) {
                    this.resizeObserver.disconnect();
                }
            }
        }

        // 使用方式：
        var video1El =await document.getElementById('playerBox');



        const videoWrapper = new VideoWrapper(video1El, canvas);
        const video1 = videoWrapper.customImage;
        video1.setControlVisible('mtr', false) //隐藏旋转角
        // 将 CustomImage 实例添加到画布
        this.fabricM3 = video1;
        // console.log(this.fabricM3,'----------this.fabricM3----------');

        this.fabricObj.add(video1);

        this.isRendering = true;
        if(this.fabricM1){
          this.fabricM1.bringToFront();
        }

        this.fabricObj.renderAll()
        if (!this.animationId) {
            // console.log('不存在动画，开始渲染');
            this.fabricRender();
        }
        // 可以添加定时检查来增加可靠性
        _this.checkvideo1El =  setInterval(() => {
          // console.log(12312312)
            if(_this.fabricM5){
              _this.fabricM5.bringToFront();
            }
            if (video1El.videoWidth && video1El.videoHeight) {
                videoWrapper.updateVideoDimensions();
            }
        }, 1000);

      return
      // 创建自定义的 fabric.Image 子类
      var CustomImage = fabric.util.createClass(fabric.Image, {
          _render: function(ctx) {
              var x, y, imageMargins = this._findMargins(), elementToDraw;

              x = -imageMargins.containerWidth / 2;
              y = -imageMargins.containerHeight / 2;

              if (this.meetOrSlice === 'slice') {
                  ctx.beginPath();
                  ctx.rect(x, y, imageMargins.containerWidth, imageMargins.containerHeight);
                  ctx.clip();
              }

              elementToDraw = this._element;
              elementToDraw && ctx.drawImage(elementToDraw,
                  x + imageMargins.marginX,
                  y + imageMargins.marginY,
                  imageMargins.width,
                  imageMargins.height
              );

              this._renderStroke(ctx);
          },

          _findMargins: function() {
              var containerWidth = 1920;
              var containerHeight = 1080;
              var width = this._element.width;
              var height = this._element.height;
              var SX = containerWidth / width;
              var SY = containerHeight / height;
              var imageRatio = width / height;
              var containerRatio = containerWidth / containerHeight;
              var scale, marginX = 0, marginY = 0;

              // 特殊处理：当宽度比例为1，且高度比例大于0.95时
              if (Math.abs(SX - 1) < 0.01 && SY > 0.95) {
                  scale = 1;
                  // 强制铺满
                  return {
                      width: containerWidth,
                      height: containerHeight,
                      marginX: 0,
                      marginY: 0,
                      containerWidth: containerWidth,
                      containerHeight: containerHeight
                  };
              }

              // 根据长宽比例决定适配方式
              if (imageRatio > containerRatio) {
                  // 图片更宽，以宽度铺满
                  scale = SX;
                  var scaledHeight = height * scale;
                  marginX = 0;
                  marginY = (containerHeight - scaledHeight) / 2;
              } else {
                  // 图片更高，以高度铺满
                  scale = SY;
                  var scaledWidth = width * scale;
                  marginY = 0;
                  marginX = (containerWidth - scaledWidth) / 2;
              }

              // 判断是否需要缩放
              if (width <= containerWidth && height <= containerHeight) {
                  // 小图片保持原始尺寸
                  if (Math.abs(scale - 1) < 0.01) {
                      scale = 1;
                      marginX = (containerWidth - width) / 2;
                      marginY = (containerHeight - height) / 2;
                  }
              }

              return {
                  width: width * scale,
                  height: height * scale,
                  marginX: marginX,
                  marginY: marginY,
                  containerWidth: containerWidth,
                  containerHeight: containerHeight
              };
          }
      });
      

       // let width = _this.fabricObj.width;
        // let height = _this.fabricObj.height;
        // let top=0;
        // let left =0;
        // if(this.lianmai.state){
        //   width = width*0.85;
        //   height = height*0.85;
        //   top = 162;
        //   left = 144;
        // }
      await this.changeFabricBg()

      var video1El =await document.getElementById('playerBox');

      console.log(video1El.offsetWidth,'000000000')
      const width = video1El.offsetWidth;
      const height = video1El.offsetHeight;
      // console.log(video1El.width,video1El.height);
      //  video1El.style.width = `${width}px`;
      //  video1El.style.height = `${height}px`;
      //  video1El.width = width;
      //  video1El.height = height;
      
      // 创建 CustomImage 实例
      setTimeout(() => {
        video1 = new CustomImage(video1El, {
        left: 0,
        top: 0,
        width: 1920,
        height: 1080,
        meetOrSlice: 'meet',
        alignX: 'Mid',
        alignY: 'Mid',
        id:'m3',
        objectCaching: false,
        selectable:false,
        backgroundColor :'black'
      });
      video1.moveTo(0)

      video1.setControlVisible('mtr', false) //隐藏旋转角
      // 将 CustomImage 实例添加到画布
      this.fabricM3 = video1;
      // console.log(this.fabricM3,'----------this.fabricM3----------');

      this.fabricObj.add(video1);

      this.isRendering = true;
      if(this.fabricM1){
        this.fabricM1.bringToFront();
      }

      this.fabricObj.renderAll()
      if (!this.animationId) {
          // console.log('不存在动画，开始渲染');
          this.fabricRender();
      }
        }, 2000);
            
      
    
    },
    fabricRender() {
        var _this = this;
        if (!_this.isRendering) return;
        
        // 初始化FPS计算相关变量（如果不存在）
        if (!_this.frameCount) _this.frameCount = 0;
        if (!_this.fpsStartTime) _this.fpsStartTime = Date.now();
        if (!_this.currentFps) _this.currentFps = 0;
        
        // 更新帧计数
        _this.frameCount++;
        
        // 计算当前时间和渲染间隔
        var currentTime = Date.now();
        var elapsed = currentTime - _this.lastTime;
        var interval = 60; // 约16.7ms对应60fps
        
        // 计算并输出FPS（每秒更新一次）
        if (currentTime - _this.fpsStartTime >= 1000) {
            _this.currentFps = Math.round((_this.frameCount * 1000) / (currentTime - _this.fpsStartTime));
            console.log('当前FPS:', _this.currentFps);
            _this.frameCount = 0;
            _this.fpsStartTime = currentTime;
        }
        
        // 执行渲染（如果达到间隔时间）
        if (elapsed >= interval) {
            _this.fabricObj.renderAll();
            _this.lastTime = currentTime - (elapsed % interval);
        }
        
        // 请求下一帧
        _this.animationId = requestAnimationFrame(_this.fabricRender.bind(_this));
    },
    //混合摄像头与共享流
    async mixCamera() {
      return
      var config = [
        {
          streamId: this.ID2,
          x: 960.4,
          y: 540,
          width: 1920,
          height: 1080,
          zOrder: 1,
        },
        {
          streamId: this.ID3,
          x: 960.4,
          y: 540,
          width: 1920,
          height: 1080,
          zOrder: 0,
        },
        {
          streamId: this.ID1,
          x: 1920 * this.left + (1920 * this.widthPercentage) / 2,
          y:
            1080 * this.top +
            (1920 * this.widthPercentage) / this.lengthWidthRatio / 2,
          width: 1920 * this.widthPercentage + 2,
          height: (1920 * this.widthPercentage) / this.lengthWidthRatio,
          zOrder: 2,
        },
        {
          streamId: this.ID4,
          x: 1920 * this.left + (1920 * this.widthPercentage) / 2,
          y:
            1080 * this.top +
            (1920 * this.widthPercentage) / this.lengthWidthRatio / 2,
          width: 1920 * this.widthPercentage + 2,
          height: (1920 * this.widthPercentage) / this.lengthWidthRatio,
          zOrder: 3,
        },
      ];
      if(!this.CameraStatus){
        console.log('mixCamera没有摄像头');
        var config = [
        {
          streamId: this.ID2,
          x: 960.4,
          y: 540,
          width: 1920,
          height: 1080,
          zOrder: 11,
        },
        {
          streamId: this.ID3,
          x: 960.4,
          y: 540,
          width: 1920,
          height: 1080,
          zOrder: 10,
        },
        
      ];
      }
      if (this.SelectMenu == 2) {
        try {
          await this.livePusher.stopCustomCapture(this.ID3);
          this.ID3 = null;
        } catch (error) {
          console.log(error);
        }
        // this.$refs.cameraStream.srcObject = this.LocalCameraStream;
        // this.$refs.cameraStream.play();

        if (!this.ID2) {
          console.log('不存在id2');
          await this.livePusher
            .startCustomCapture(this.whiteboardRecorder.stream)
            .then((streamId) => {
              console.log("custom stream id is " + streamId);
              this.ID2 = streamId;
              let tmpConfig = JSON.parse(JSON.stringify(config))
              tmpConfig[1].width = 0;
              tmpConfig[1].height = 0;
              tmpConfig[0].streamId = this.ID2;
              console.log(tmpConfig);

              this.videoEffectManager.setLayout(tmpConfig);
              // this.videoEffectManager.setMirror({
              //   streamId: this.ID4 || this.ID1,
              //   mirrorType: 1,
              // });
            })
            .catch((error) => {
              console.log("start custom error: " + error.toString());
            });
        } else {
          console.log('存在id2');
          let tmpConfig = JSON.parse(JSON.stringify(config))
          tmpConfig[1].width = 0;
          tmpConfig[1].height = 0;
          tmpConfig[0].streamId = this.ID2;
          console.log(tmpConfig);
          this.videoEffectManager.setLayout(tmpConfig);
          // this.videoEffectManager.setMirror({
          //   streamId: this.ID4 || this.ID1,
          //   mirrorType: 1,
          // });
        }

        // this.lengthWidthRatio  摄像头长宽比
        // this.widthPercentage   宽度百分比
        // 1920 *widthPercentage  宽度
      } else if (this.SelectMenu == 3) {
        try {
          await this.livePusher.stopCustomCapture(this.ID2);
          this.ID2 = null;
        } catch (error) {
          console.log(error);
        }
        console.log(this.videoEffectManager);
        if (!this.ID3) {
          console.log("不存在屏幕共享流，不需要处理");
          return false;
        } else {

          let tmpConfig = JSON.parse(JSON.stringify(config))
          tmpConfig[0].width = 0;
          tmpConfig[0].height = 0;
          tmpConfig[1].streamId = this.ID3;
          console.log(tmpConfig,'--tmpConfig----');
          this.videoEffectManager.setLayout(tmpConfig)
          // this.videoEffectManager.setMirror({
          //   streamId: this.ID4 || this.ID1,
          //   mirrorType: 1,
          // });
        }
      } else if (this.SelectMenu == 1) {
        console.log('1  开始混流');
       
        let tmpConfig = JSON.parse(JSON.stringify(config))
        if(!this.CameraStatus){
          tmpConfig = {
            
          };
          this.videoEffectManager.setLayout(tmpConfig);
          return
        }
        try {
          await this.livePusher.stopCustomCapture(this.ID3);
          this.ID3 = null;
          await this.livePusher.stopCustomCapture(this.ID2);
          this.ID2 = null;
        } catch (error) {
          console.log(error);
        }
        // console.log(this.videoEffectManager.getLayout());
        // this.videoEffectManager.clearLayout()
        tmpConfig[0].width = 0;
        tmpConfig[0].height = 0;
        tmpConfig[1].width = 0;
        tmpConfig[1].height = 0;
        tmpConfig[2].width = 1920;
        tmpConfig[2].height = 1080;
        tmpConfig[3].width = 1920;
        tmpConfig[3].height = 1080;
        if (this.BeautyFlag) {
          tmpConfig[2].width = 0;
          tmpConfig[2].height = 0;
          tmpConfig[3].x = 960;
          tmpConfig[3].y = 540;
          tmpConfig[3].streamId = this.ID4;
        } else {
          // console.log(this.lengthWidthRatio);
          tmpConfig[3].width = 0;
          tmpConfig[3].height = 0;
          tmpConfig[2].x = 960;
          tmpConfig[2].y = 540;
          tmpConfig[2].streamId = this.ID1;
        }
        // console.log(tmpConfig);
        this.videoEffectManager.setLayout(tmpConfig);
        // console.log(this.videoEffectManager.getLayout(this.ID1));
        // console.log(this.videoEffectManager.getLayout(this.ID4));
        // this.videoEffectManager.setMirror({
        //   streamId: this.ID4 || this.ID1,
        //   mirrorType: 1,
        // });
      }
    },

    // 混流
    async mergeVideo() {
      let subData = {};
      subData.video = true;

      if (this.SelectMenu == 2) {
        if (this.CameraStatus) {
          await this.mixCamera();
          console.log("存在摄像头，开始混流");
          setTimeout(async () => {
            let Video = document
              .getElementById("local_video")
              .getElementsByTagName("video")[0];
            
            this.VideomergerStream = Video.captureStream(15);
            console.log('替换了this.VideomergerStream',this.VideomergerStream);
            try {
              this.audioSender.replaceTrack(this.AudioStream.getTracks()[0])

            } catch (error) {
              console.log(error, '修改srs音频失败');
            }

          }, 300);
        } else {
          await this.mixCamera(false);
        }
      }
      if (this.SelectMenu == 3) {
        await this.mixCamera();
        setTimeout(() => {
          let Video = document
            .getElementById("local_video")
            .getElementsByTagName("video")[0];

          this.VideomergerStream = Video.captureStream(15);
          console.log('替换了this.VideomergerStream',this.VideomergerStream);
          if (this.mergeAudioTrack) {
            console.log('替换AudioTrack为合并音频');
            try {
              this.audioSender.replaceTrack(this.mergeAudioTrack)
            } catch (error) {

            }
          }
          // if (this.lianmai.state) {
          //   this.$refs.RemoteStream.srcObject = this.RemoteStream;
          // }

          // this.replaceTrack(this.VideomergerStream.getVideoTracks()[0]);
        }, 300);
      }
      // if (this.SelectMenu == 1) {
      //   await this.mixCamera();
      //   setTimeout(() => {
      //     let Video = document
      //       .getElementById("local_video")
      //       .getElementsByTagName("video")[0];
      //     this.VideomergerStream = Video.captureStream(15);
      //     console.log('替换了this.VideomergerStream',this.VideomergerStream);
      //   }, 300);
      // }
    },
    // 点击开始直播事件
    async btnStartRecordClicked(e) {
      // this.$message("正在开启直播");
      console.log("正在开启直播");
      try {
        this.AudioStream.getAudioTracks().forEach((value) =>
          this._stream.addTrack(value)
        );
      } catch (error) {
        console.log(error);
      }

      this.startRecord();
    },
    // 计算VideoTrack,准备推流
    async startRecord() {
       this.publishTest();
    },
    // 推流
    async publishTest(flag) {

      this.AudioState = true;
      console.log(this.liveDetail.appName);
      let PushType = store.get('PushType');
      console.log(PushType,'--------------------')
      
      console.log(this.pc)
  
      if(flag){
        PushType = 1
      }
      console.log('===============================');
      console.log(PushType);
      console.log('===============================');
      
      if(PushType==0){
        this.testWSMeat()
        console.log('ws-------------');
        return
      }else{
        this.TXPusherFUN();
        return
      }
    },
    //直播信息---获取推流地址（直播间同时添加聊天室信息）
    getPushUrlEvent() {
      let subData = {};
      let axiosUrl = "";
      const _this = this;
      // console.log(
      //   this.rootInfo.liveType,
      //   "------------this.rootInfo.liveType--------------"
      // );
      if (this.rootInfo.liveType == "lesson" && !this.liveDetail.relateRoomId) {
        subData.courseId = this.liveDetail.courseId;
        subData.lessonId = this.rootInfo?.lessonId || "";
        axiosUrl = "/api/v1/channel/course_thirdlive_info";
      } else {
        if (this.rootInfo.liveType == "live") {
          subData.id = this.rootInfo.lessonId;
          subData.lessonId = this.rootInfo?.lessonId || "";
        } else {
          subData.id = this.liveDetail.relateRoomId;
          subData.lessonId = this.rootInfo?.lessonId || "";
        }
        axiosUrl = "/api/v1/room/manage_stream_info";
      }
      ixunkeAxios(subData, "get", axiosUrl).then((res) => {
        // console.log("success------------------------");
        if (res.errno == 0) {
          // console.log(res.data?.isVertical);
          if(res.data?.isVertical==1){
            console.log('竖屏直播！！！');
            this.$router.replace({
              name: "Vertica",
              query: {
                courseId: this.rootInfo?.courseId,
                title: this.rootInfo?.title || this.rootInfo?.name,
                lesson:this.rootInfo?.title ||this.rootInfo?.name,
                lessonId:this.rootInfo.id || this.rootInfo.roomId||this.$route.query.lessonId,
                liveType: this.rootInfo?.liveType,
              },
            });
            return
          }
          if (_this.rootInfo.liveType == "lesson") {
            console.log(res.data);
            // _this.liveDetail = res.data
            _this.endTime = res.data.endTime;
            _this.liveDetail.type = "lesson";
            _this.liveDetail.encryptPushUrl = res.data.encryptPushUrl;
            _this.liveDetail.encryptWebRTCPushUrl =
              res.data.encryptWebRTCPushUrl;
            _this.liveDetail.roomId = res.data.roomId;
            console.log(_this.liveDetail, "======lesson==========");
          } else if (_this.rootInfo.liveType == "live") {
            _this.livendTimeeDetail = res.data;
            _this.endTime = res.data.endTime;
            _this.liveDetail.type = "live";
            // console.log(_this.liveDetail);
          }

          if (this?.liveDetail?.roomId) {
            store.set('roomId', this.liveDetail.roomId)
          }
          console.log(_this.liveDetail?.chatroom?.identifier);
          if(_this.liveDetail?.chatroom){
            store.set('identifier',_this.liveDetail?.chatroom?.identifier)
          }
          _this.liveGoods = res.data?.goods || "";
          //判断是腾讯云，还是华为云
          const HWplatform = "-hw.";
          // console.log(this.liveDetail.encryptPushUrl);
          if (
            this.liveDetail.encryptPushUrl
              .toUpperCase()
              .indexOf(HWplatform.toUpperCase()) < 0
          ) {
            //腾讯云推流
            this.livePlatform = "tengxun";
            this.livePusherUrl = this.liveDetail.encryptWebRTCPushUrl;
          } else {
            //华为云推流
            this.livePlatform = "huawei";
            this.livePusherUrl = this.liveDetail.encryptPushUrl;
            //华为---生成华为客户端
            // let  PushType = store.get('PushType')
            if(this.platform=='darwin'){
              store.set('PushType',1);
              this.PushType =1;
              PushType = 1;
            }
            if(PushType==1){
              // this.initClientEvent();
              return
            }            
          }
          this.liveDetailLoadState = true;
        } else {
          console.log(res.errno, "errno--------------------");
          this.$error({
            centered: true,
            title: "推流地址获取失败！1",
            content: JSON.stringify(res.errmsg),
            okText: "知道了",
          });
        }
      });
      // .catch((error) => {
      //   console.log(1121312231);
      //   this.$error({
      //     centered: true,
      //     title: "推流地址获取失败！2",
      //     content: JSON.stringify(error),
      //     okText: "知道了",
      //   });
      // });
    },
    // 获取直播间信息
    getLiveInfoEvent() {
      const _this = this;
      this.liveDetailLoadState = false;
      if (this.rootInfo.liveId || this.rootInfo.lessonId) {
        let axiosUrl = "";
        let subData = {
          id: this.rootInfo.liveId || this.rootInfo.lessonId,
        };
        // console.log(this.rootInfo);
        if (this.rootInfo.liveType == "lesson") {
          subData.videoFormat = "udp|m3u8";
          axiosUrl = "/api/lesson";
        } else if (this.rootInfo.liveType == "live") {
          axiosUrl = "/api/v1/room/manage_stream_info";
        }
        this.pageLoading = true;
        this.pageLoadText = "加载中...";
        console.log(213333333);
        ixunkeAxios(subData, "get", axiosUrl).then((res) => {
          this.pageLoading = false;
          console.log(res.errno);
          if (res.errno == 0) {
            this.liveDetail = res.data;
            // console.log(this.liveDetail.appName,'---------this.liveDetail?.appName')
           
            //   let script = document.createElement('script');
            //   script.src = '../sdk/TXLivePusher-2.0.0.min.js';
            //   script.onload = function() {
            //     console.log('加载完成');
            //     _this.livePusher = new TXLivePusher()
               
            //   };
            //   document.body.appendChild(script);
            
            //直播信息---获取推流地址（直播间同时添加聊天室信息）
            this.getPushUrlEvent();
          } else {
            
            this.$error({
              centered: true,
              title: "直播信息获取失败！",
              content: JSON.stringify(res.errmsg),
              okText: "知道了",
            });
          }
        });
      }
    },

    CancelDPVisible() {
      this.selectedIndex = -1;
      this.shareDPVisible = false;
    },
    async addCheckClass(index) {
      this.selectedIndex = index;
    },
    //获取窗口列表
    async getWindows() {
      
      console.log('getWindows');
      let selectedWindowId;
      let mediaRecorder;
      const recordedChunks = [];
      // 渲染器进程

      // const DPList = await desktopCapturer.getSources({
      //   types: ["window", "screen"],
      //   thumbnailSize: {
      //     width: 512,
      //     height: 512,
      //   },
      // });
      await ipcRenderer.send("getDesktopSources");
    },
    // 分享屏幕
    ipcShareDP() {
      this.SelectMenu = 3;
      console.log(this.shareDPStatus);
      // if (!this.shareDPStatus) {
        this.getWindows();
      // }
    },

    // 初始化房间
    async initClientEvent() {
      const _this = this;
      // return
      let config = {
        appId: "62e66339fa163e45d5151305f0f9f6ca",
        domain: "62e66339fa163e45d5151305f0f9f6ca.cloudrtc.myhuaweicloud.com",
        // appId:"66051d4bfa163e026ac016978218939e",
        // domain:"66051d4bfa163e026ac016978218939e.cloudrtc.myhuaweicloud.com",
        countryCode: "CN",
      };
      var signature;
      let ctime = moment.utc().add(2, "h").unix() + "";
      await ixunkeAxios(
        { room_id: this.liveDetail.roomId, user_id: "1", ctime: ctime },
        "post",
        "/api/v1/channel/rtc_sign"
      ).then((res) => {
        signature = res.data.signature;
      });

      try {
        // console.log(HRTC.getVersion());
        this.client = await HRTC.createClient(config);
      } catch (error) {
        console.log("创建本地客户端失败");
        console.log(error);
      }

      // console.log(this.client, "success");
      let option = {
        userId: "1",
        userName: "直播管理员",
        signature: signature,
        ctime: ctime,
        role: 0,
      };
      // console.log(this.liveDetail.roomId, "------this.liveDetail.roomId-----");
      // console.log(signature, "-------signature----");
      // console.log(option, "----------option----------------");
      try {
        await this.client.join(this.liveDetail.roomId, option);
      } catch (error) {
        console.log("join room fail", error);
      }
      return;
    },
    // 附加音频流
    async attachAudioStream() {
      // 获取麦克风流
      var deviceId
      try {
        deviceId = this.DeviceConfig[1] || 'default'

      } catch (error) {
        deviceId = 'default'
      }
      try {
        this.AudioStream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
            echoCancellation: true, // 开启回声消除
            noiseSuppression: true, // 开启噪声抑制
            autoGainControl: true, // 开启自动增益
            channelCount: { ideal: 1 },

            sampleRate: { ideal: 48000 },
            deviceId: deviceId
          },
        });
        // console.log(this.AudioStream , "--AudioStream ----------");
        // 将麦克风的流，附加到主流上
        this.AudioStream.getAudioTracks().forEach((value) =>
          this._stream.addTrack(value)
        );

        this.AudioMixer()
      } catch (error) {
        console.log(error);
        this.$message.error("没有检测到有效音频输入设备,开启直播失败");
      }
    },
    //返回首页
    async goBackHome() {

      await this.client.leave(this.liveDetail.roomId);

      // try {
      //   this.client.leave(this.liveDetail.roomId);
      // } catch (error) {}

      // this.$router.push({
      //   name: "course",
      // });
    },
    async CreateAgoraCanvas(taskId){
     await this.initCanvas2(taskId);
     
   
    
    },
    async initCanvas2(taskId){   
      const _this = this;
      if(this.slide){
        this.slide.destroy();
        this.slide = null;
      }
      console.log(taskId,'------id-----');
      const anchor = document.getElementById('anchor');
      const slide = await new Slide({
          anchor: anchor,
          interactive: true,
          mode: "local",
          renderOptions:{
            minFPS:10,
            maxFPS:10,
            resolution:1, 
          }
      });
      this.slide = slide;
      // 55572148f9c74c7787b20766ae613703
      // 0c4d6513462840229d7f3293a8f0d657
      const taskID = taskId;
      // const url = 'https://walter-file.oss-cn-beijing.aliyuncs.com/SW/dynamicConvert';
      const url = 'https://ixunke-courseware-sh.oss-cn-shanghai.aliyuncs.com/dynamicConvert';
      await slide.setResource(taskID,url);
      console.log(slide);
      await slide.renderSlide(1);
     
       
        console.log(slide,'this.slideCount');
        console.log(Object.keys(slide) );
        window.postMessage({
            type: "@slide/_request_log_",
            sessionId: taskID,  // session 标识
        }, "*");
        window.addEventListener("message", evt => {
            if (evt.data.type === "@slide/_render_start_") {
                console.log(evt.data.taskId); // ppt 转码任务的 taskId
                console.log(`第 ${evt.data.index} 页开始渲染`);
                _this.defaultwhiteboardIndex =evt.data.index;
            } else if (evt.data.type === "@slide/_render_end_") {
                console.log(evt.data.taskId); // ppt 转码任务的 taskId
                console.log(`第 ${evt.data.index} 页开始结束`);
                setTimeout(() => {
                  const anchor = document.getElementById('anchor');
                  const PPTCanvas = anchor.querySelector('canvas');
                  this.slideCount = slide.slideCount;
                  this.AgoraPerview = true;
                  var fabricPPT = new fabric.Image(PPTCanvas, {
                      left: 0,
                      top: 0,
                      width:1280,
                      height:720,
                      scaleX:1.5,
                      scaleY:1.5,
                      objectCaching: false,
                      selectable:false,
                      id:'m9',
                      hoverCursor:'default',
                  });
                  fabricPPT.setControlVisible('mtr', false) //隐藏旋转角
                  fabricPPT.moveTo(0)
                  console.log(fabricPPT);

                  this.fabricM9 = fabricPPT;
                

                  this.fabricObj.add(fabricPPT);

                  this.fabricObj.renderAll()
                  setTimeout(() => {
                    this.fabricM9.sendToBack();
                    this.fabricObj.renderAll()
                  }, 400);
                }, 300);
                slide.cacheImage = false;
            }
        });
      

    },
    //初始化canvas
    async initCanvas() {
      await this.fabricObj?.dispose();
      this.currentTool = "";
      this.fabricHistoryJson = [];
      this.initIdx = 0;
      this.mods = -1;
      var fabricObj = await new fabric.Canvas("canvas", {
        renderOnAddRemove: true,
        isDrawingMode: false,
        selectable: true,
        selection: true,
        width: 1920,
        height: 1080,
        erasable: false, // 不允许被橡皮擦抹除
        devicePixelRatio: true, //Retina 高清屏 屏幕支持
        preserveObjectStacking: true,
        controlsAboveOverlay: false,
      });
      this.fabricObj = fabricObj;
      const whiteboard = this.$refs.canvas;
      fabricObj.freeDrawingBrush.color = "#E34F51";
      fabricObj.freeDrawingBrush.width = 2;
      // 关闭图像平滑处理，避免颜色失真问题
      // const ctx = whiteboard.getContext("2d");
      // ctx.imageSmoothingEnabled = false;
      // ctx.webkitImageSmoothingEnabled = false;
      this.whiteboardStream = whiteboard.captureStream();
      this.fabricObjAddEvent();
      this.isRendering = true;
      if(!this.animationId){
        this.fabricRender();
      }
    },
    // 上一页白板
    async PreviousFastboard() {
      console.log("上一页白板");
      if(this.AgoraPerview){
        this.slide.prevStep();
        this.slide.clearSlideCache()
        return
      }
      if (this.defaultwhiteboardIndex < 2) return;
      console.log(this.defaultwhiteboardIndex,'-------this.defaultwhiteboardIndex-------')
      this.defaultwhiteboardIndex = this.defaultwhiteboardIndex - 1;
      let tmpIndex = this.defaultwhiteboardIndex -1;
      this.defaultwhiteboardImg =
      this.CoursewareList[tmpIndex];
      this.fabricHistoryJson = [];
      this.mods = -1;
      // this.initCanvas();
     await this.changeFabricBg();
    },

    // 下一页白板、
    nextFastboard() {
      
      if(this.AgoraPerview){
        console.log('下一步动画');
        this.slide.nextStep();
        return
      }
      console.log("下一页白板");
      if (
        this.defaultwhiteboardIndex  == this.CoursewareList.length ||
        this.defaultwhiteboardIndex > this.CoursewareList.length
      )return;
      let tmpIndex = this.defaultwhiteboardIndex
      console.log(this.defaultwhiteboardIndex,'-------this.defaultwhiteboardIndex-------')
      this.defaultwhiteboardIndex = this.defaultwhiteboardIndex + 1;
      this.defaultwhiteboardImg =
        this.CoursewareList[tmpIndex];
    
      this.fabricHistoryJson = [];
      this.mods = -1;
      // this.initCanvas();
      
      this.changeFabricBg();
    },
    //手动输入页码
    ChangeWhiteIndex(){
      this.defaultwhiteboardIndex = Number(this.defaultwhiteboardIndex)
      console.log(this.defaultwhiteboardIndex)
      console.log('新页面')
      if(this.AgoraPerview){
        console.log('跳转到指定页面');
        this.slide.renderSlide(this.defaultwhiteboardIndex)
        return
      }else{
        let tmpIndex = this.defaultwhiteboardIndex-1;
        this.defaultwhiteboardImg =
        this.CoursewareList[tmpIndex];
    
        this.fabricHistoryJson = [];
        this.mods = -1;
        
        this.changeFabricBg();
      }
    },
    async removeFabricChildren(){
      try {
          var children = this.fabricObj?.getObjects() || [];
          // console.log('All children:', children);
          var filteredChildren = children.filter(function(obj) {
              return obj.id !== 'm1' && obj.id !== 'm3' && obj.id !== 'm4' && obj.id !== 'm5'&& obj.id !== 'm9';
          });

          // console.log('Filtered children:', filteredChildren);
      } catch (error) {
        filteredChildren = []
      }
      if (filteredChildren.length > 0) {
        // 移除所有对象
        this.fabricObj.remove(...filteredChildren);
      }
    },
    resizeFabricBackground(isEnlarge = true) {
        // 检查是否存在 fabricM9 元素
        if (!this.fabricM9) return;
        console.log('存在')
        const tmpSize = this.FabricBackgroundImageSize;
        const step = 0.1;
        const minScale = 1;
        const maxScale = 3;
        
        // 计算新的比例
        this.BackgroundImageProportion = Math.min(
            Math.max(
                this.BackgroundImageProportion + (isEnlarge ? step : -step),
                minScale
            ),
            maxScale
        );

        // 设置图片元素的缩放
        this.fabricM9.set({
            scaleX: tmpSize.scaleX * this.BackgroundImageProportion,
            scaleY: tmpSize.scaleY * this.BackgroundImageProportion,
            left: tmpSize.left,
            top: tmpSize.top,
            selectable: true,
            evented: true,
            lockMovementX: false,
            lockMovementY: false
        });

        // 重新渲染画布
        this.fabricObj.renderAll();

        // 可选：保持图片在视图中心
        this.fabricM9.setCoords(); // 更新对象的边界框
        const center = this.fabricObj.getCenter();
        // this.fabricM9.center(); // 将图片居中
        this.fabricM9.sendToBack();

        this.fabricM1.moveTo(0);
        this.fabricM1.bringToFront();

     
        this.fabricM1.selection =true;
        this.fabricM1.selectable = true;

        
        this.fabricObj.selection = true;
        this.fabricObj.skipTargetFind = false;
        this.fabricObj.selectable = true;
        this.fabricObj.renderAll();
    },
    // 修改canvas背景图
    async changeFabricBg() {
        const _this = this;
        this.BackgroundImageProportion = 1;
        this.removeFabricChildren()
        if(!this.defaultwhiteboardImg){
            console.log('不存在defaultwhiteboardImg');
            return;
        }

        try {
            const img = new Image();
            img.src = this.defaultwhiteboardImg;
            
            img.onload = function() {
                const width = img.width;
                const height = img.height;
                console.log(width,height,'width,height')
                const SX = (1920/width).toFixed(2);
                const SY = (1080/height).toFixed(2);
                var scale, top, left;
                
                // 计算图片宽高比
                const imageRatio = width / height;
                const targetRatio = 16 / 9;
                const ratioTolerance = 0.05; // 5%的容差
                
                // 检查图片比例是否接近16:9（在95%-105%范围内）
                const isNearTarget = Math.abs(imageRatio / targetRatio - 1) <= ratioTolerance;
                
                if (isNearTarget) {
                    // 如果接近16:9，铺满整个画布
                    scale = Math.max(SX, SY);
                    top = 0;
                    left = 0;
                } else {
                    // 否则使用原来的逻辑
                    if (SX >= SY) {
                        scale = SY;
                        top = 0;
                        left = (1920 - width * scale) / 2;
                    } else {
                        scale = SX;
                        top = (1080 - height * scale) / 2;
                        left = 0;
                    }
                }
                if(_this.lianmai.state){
                      top = 162;
                      left = 144;
                      scaleX = scaleX*0.85;
                      scaleY = scaleY*0.85;
                    }
                // 如果fabricM9已存在，只更新图片源和相关属性
                if (_this.fabricM9) {

                    console.log('存在m9');
                    fabric.util.loadImage(_this.defaultwhiteboardImg, function(img) {
                        _this.fabricM9.setElement(img);
                        _this.fabricM9.set({
                            scaleX: scale,
                            scaleY: scale,
                            top: top,
                            left: left
                        });
                        _this.fabricObj.renderAll();
                    });
                } else {
                    // 首次创建fabricM9
                    console.log('不存在m9');
                    
                    const fabricImage = new fabric.Image(img, {
                        scaleX: scale,
                        scaleY: scale,
                        top: top,
                        left: left,
                        selectable: true,
                        hasControls: false,
                        hasBorders: false,
                        lockUniScaling: true,
                        id: 'm9',
                        evented: true,
                        hoverCursor: 'move',
                        borderOpacityWhenMoving: 0
                    });

                    _this.fabricObj.add(fabricImage);
                    _this.fabricM9 = fabricImage;
                    _this.fabricM9.on('mousedown', function(opt) {
                       if(_this.SelectMenu == 1){
                        return
                       }
                        // console.log('课件被点击');
                        if(_this.initIdx==12){
                          _this.fabricM9.moveTo(0);
                          _this.fabricM9.bringToFront();
                          _this.fabricObj.renderAll();
                        }
                    });

                    _this.fabricM9.on('moved', (opt) => {
                        // console.log('元素移动结束');
                        _this.fabricM9.sendToBack();
                        const opts = {
                          scaleX: _this.FabricBackgroundImageSize.scaleX,
                          scaleY: _this.FabricBackgroundImageSize.scaleY,
                          top:_this.fabricM9.top,
                          left:_this.fabricM9.left
                        }
                        // console.log(opts,'opts');
                        _this.FabricBackgroundImageSize = opts;
                        _this.fabricObj.renderAll();
                    });
                }

                // 更新尺寸信息
                _this.FabricBackgroundImageSize = {
                    scaleX: scale,
                    scaleY: scale,
                    top: top,
                    left: left
                };
                _this.fabricM1.moveTo(0);
                _this.fabricM1.bringToFront();
            };

        } catch (error) {
            console.log('Error in changeFabricBg:', error);
        }
    },
    // 修改摄像头画布位置
    async changeM1Potion(){
      // console.log(this.m1Top,this.m1Left,'-------------m1left');
      // console.log(this.lianmai.state);
      if(!this.lianmai.state){
        if(this.fabricM1){
          // console.log('删除m1');
          this.fabricM1.set({
            scaleX: 0.22,
            scaleY: 0.22,
            width: 1920,
            height: 1080,
            top:2000,
            left:1200,
            selectable:true,
          })
        }
        if(!this.CameraStatus){
          // if(this.fabricM1){
            this.fabricM1.set('opacity', 0);
            
          // }
          return
        }
        // console.log('修改m1位置');
       
        this.fabricM1.set({
          scaleX: 0.22,
          scaleY: 0.22,
          width: 1920,
          height: 1080,
          top:this.m1Top,
          left:this.m1Left,
          selectable:true,
        })
        
        // this.fabricM5.set({
        //   left: 2000,
        //   top:0,
        //   selectable:false,
        // })
      }else{
        console.log('changeM1Potion');
        this.fabricM1.set({
          scaleX: 0.15,
          scaleY: 0.15,
          width: 1920,
          height: 1080,
          top:0,
          left:637,
          selectable:true,
        })
        this.fabricM5.set({
          scaleX: 0.6,
          scaleY:0.45,
          width: 1920,
          height: 360,
          left: 926,
          top:0
        })
        // console.log(this.fabricM5)
      }
      if(this.lianmai.state){
        console.log('修改背景图长宽3');
        this.changeFabricBgPotion()
      }
      this.fabricObj.renderAll()
    },
    async revertM1Potion(){
      var scaleX = 1920/this.localTrackWidth;
      var scaleY = 1080/this.localTrackHeight;
      if(!this.lianmai.state){
        console.log('m1铺满');
        this.fabricM1.set({
          width: 1920,
          height: 1080,
          scaleX: 1,
          scaleY: 1,
          top:0,
          left:0,
          selectable:true,
        })
      }else{
        const backgroundImage = this.fabricObj.backgroundImage;
        try {
          if(backgroundImage){
            backgroundImage.opacity = 0;
          }
        } catch (error) {
          
        }
        this.fabricM1.set({
          width: 1920,
          height: 1080,
          scaleX: .5*scaleX,
          scaleY: scaleY,
          top:0,
          left:0,
          selectable:true,
        })
        this.fabricM5.set({
          width: 1920,
          height: 1080,
          scaleX: 2,
          scaleY:2,
          top:0,
          left:960,
          selectable:false,
        })

      }
      
    },
    checkM1Click(pointer) {
      let t, r, b, l;
      try {
        t = this.fabricM1.top;
        l = this.fabricM1.left;
        r = l + (this.fabricM1.width * this.fabricM1.scaleX);
        b = t + (this.fabricM1.height * this.fabricM1.scaleY);
      } catch (error) {
        
      }
   
      if(this.SelectMenu==1){
        return
      }
      // 
      if (pointer.x >= l && pointer.x <= r && pointer.y >= t && pointer.y <= b) {
        // console.log(this.fabricM1);
        console.log("点击位置在元素内部");
         this.fabricObj.selectable = true;
         this.fabricObj.selection = true;
        if(this.currentTool!=='camera'){
          this.oldTool = this.currentTool;
        }
        // console.log(this.oldTool);
        this.handleTools({ name: "camera" }, 13);
        this.mouseFromX = null;
        this.mouseFromY = null;
        this.mouseTo.x = null;
        this.mouseTo.y = null;
      } else {
        console.log('外部');
        if(this.SelectMenu==2){
          this.fabricObj.isDrawingMode = false;
          let Tool;
          if(this.currentTool!=='camera'){
            Tool = this.currentTool;
          }else{
            Tool = this.oldTool;
          }
          console.log(this.initIdx);
          
          this.fabricObj.discardActiveObject(this.fabricM1);
          this.doDrawing = true;
          // this.fabricObj.selectable = false;
          // this.fabricObj.selection = false;
          this.handleTools({ name: Tool }, this.initIdx);
        }else{
          this.fabricObj.isDrawingMode  = false;
          console.log('模块3');
          console.log(this.fabricObj.isDrawingMode);
        }
      
      }
    },
    // //事件监听
    fabricObjAddEvent() {
      // console.log('事件监听一下');
      this.fabricObj.on({
        "mouse:down": (o) => {
          // console.log('mouse down')
          if(this.SelectMenu == 1){
            return
          }
          // console.log(this.fabricObj)
          // console.log(this.mouseFromX,this.mouseFromY);
          // if(this.SelectMenu==3&&this?.fabricM1){
          //   // console.log('开始检查点位')
          //   // this.checkM1Click(o.pointer)
          // }else{
          //   console.log('不需要判断');
          // }
          this.mouseFromX = o.pointer.x;
          this.mouseFromY = o.pointer.y;
          if (this.currentTool == "text") {
            this.mouseFromX = o.pointer.x;
            this.mouseFromY = o.pointer.y;
            this.drawText();
          } else if (this.currentTool !== "camera") {
            this.mouseFromX = o.pointer.x;
            this.mouseFromY = o.pointer.y;
            this.doDrawing = true;
          }
        },
        "path:created": (e) => {
        },
        "mouse:up": (e) => {
          if (this.currentTool === 'camera') {
            this.mouseTo.x = null;
            this.mouseTo.y = null;
            this.mouseTo = {};
            // console.log(this.mouseTo);
            return;
          } else {
            // console.log('mouse up');
            this.mouseTo.x = e.pointer.x;
            this.mouseTo.y = e.pointer.y;
            this.drawingObject = null;
            this.moveCount = 1;
            this.doDrawing = false;
            this.updateModifications(true);
            this.FabricUP = true;
            if(this.currentTool === 'text'){
              this.doDrawing = true;
            }
            // console.log('UP!!!!!!');
          }
              
          // this.fabricTargetList.push(e.currentTarget)
          // console.log(this.fabricTargetList);
          // console.log(12123123312);
        },
        "mouse:move": (o) => {
          if (this.moveCount % 2 && !this.doDrawing) {
            //减少绘制频率
            return;
          }
          if (this.currentTool === 'camera') {
            // console.log(this.mouseTo);
            return;
          } else {
            if(!this.mouseFromX&&!this.mouseFromY){
              return
            }else{
              this.moveCount++;
              this.mouseTo.x = o.pointer.x;
              this.mouseTo.y = o.pointer.y;
              this.drawing();
            }
           
          }
       
        },
        //对象移动时间
        "object:moving": (e) => {
          e.target.opacity = 1;
        },
        //增加对象
        "object:added": (e) => {
          // debugger
          // console.log(e.target);
        },
        "object:modified": (e) => {
          // console.log(e.target);
          e.target.opacity = 1;
          // console.log("修改了对象");
          // let object = e.target;
          // console.log(e);
          // this.updateModifications(true);
        },
        "selection:created": (e) => {
          console.log(this.initIdx);
          if(this.initIdx!==10){
            return
          }
          let id = e?.target?.id;
          
          this.fabricObj.renderAll();
          if(id=='m1'||id=='m9'){
            return
          }
          
          if (e.target._objects) {
            //多选删除
            console.log('多选删除')
            var etCount = e.target._objects.length;
            for (var etindex = 0; etindex < etCount; etindex++) {
              console.log(e.target._objects[etindex])
              let id = e.target._objects[etindex].id;
              if(id=='m1'||id=='m9'){
                continue
              }else{
                this.fabricObj.remove(e.target._objects[etindex]);
              }
            }
          } else {
            //单选删除
            console.log('单选删除')
           
            this.fabricObj.remove(e.target);
          }
          this.fabricObj.discardActiveObject(); //清楚选中框
          this.updateModifications(true);
        },
      });
    },
    //储存历史记录
    updateModifications() {
      this.fabricHistoryJson.push(JSON.stringify(this.fabricObj));
      this.fabricHistoryJson.push(JSON.stringify(this.fabricObj));

      this.fabricHistoryJson.push(JSON.stringify(this.fabricObj));
    },
    // //canvas 历史后退
    undo() {
      // console.log(this.fabricObj.getObjects());
      let list = this.fabricObj.getObjects();
      list = list.filter(function(obj) {
          return obj.id !== 'm1' && obj.id !== 'm3' && obj.id !== 'm4' && obj.id !== 'm5' && obj.id !== 'm9';
      });
      // console.log(list);
      this.fabricObj.remove(list[list.length - 1]);
      return;
      console.log(this.fabricTargetList);
      this.fabricObj.remove(
        this.fabricTargetList[this.fabricTargetList.length - 1]
      );
      this.fabricTargetList.pop();
      return;
      let state = this.fabricHistoryJson;
      console.log(state);
      if (state.length < 1) {
        return;
      }
      // if (this.mods < state.length) {
      this.fabricObj.clear().renderAll();
      this.fabricObj.setBackgroundImage(
        this.defaultwhiteboardImg,
        this.fabricObj.renderAll.bind(this.fabricObj),
        {
          repeat: "no-repeat",
          scaleX: 1066 / this.imgwidth,
          scaleY: 600 / this.imgheight,
        }
      );

      this.fabricObj.loadFromJSON(state[state.length - 2]);
      this.fabricHistoryJson.pop();
      this.fabricObj.renderAll();
      setTimeout(() => {
        this.mergeVideo();
      }, 100);

      // this.mods += 1;
      // }
    },
    // //前进
    // redo() {
    //   let state = this.fabricHistoryJson;
    //   if (this.mods > 0) {
    //     this.fabricObj.clear().renderAll();
    //     this.fabricObj.loadFromJSON(state[state.length - 1 - this.mods + 1]);
    //     this.fabricObj.renderAll();
    //     this.mods -= 1;
    //   }
    // },
    transformMouse(mouseX, mouseY) {
      return { x: mouseX / this.zoom, y: mouseY / this.zoom };
    },
    resetObj() {
      this.fabricObj.selectable = false;
      this.fabricObj.selection = false;
      this.fabricObj.skipTargetFind = false;
      //清除文字对象
      if (this.textboxObj) {
        this.textboxObj.exitEditing();
        this.textboxObj = null;
      }
    },
    handleTools(tools, idx) {
      // console.log(tools, idx);
      this.mouseFromX = null;
      this.mouseFromY = null;
      if(idx!==13){
        this.initIdx = idx;
      }
      
      
      this.currentTool = tools.name;
      this.fabricObj.isDrawingMode = false;
      this.resetObj();
      // console.log(this.currentTool);
      switch (tools.name) {
        case "pencil":
          this.fabricObj.isDrawingMode = true;
          this.fabricObj.selection = false;
          this.fabricObj.skipTargetFind = false;
          this.fabricObj.selectable = false;
          break;
        case "line":
        case "arrow":
        case "dashedline":
        case "rectangle":
        case "circle":
          console.log('不允许选中！！！')
          this.fabricM9.selection =false;
          this.fabricM9.selectable = false;
          this.fabricM1.selection =false;
          this.fabricM1.selectable = false;

          this.fabricM9.selection =false;
          this.fabricM9.selectable = false;
          this.fabricM9.evented = false;

          this.fabricObj.isDrawingMode = false;
          this.fabricObj.selection = false;
          this.fabricObj.skipTargetFind = false;
          this.fabricObj.selectable = false;
        // console.log(this.fabricM9);
          this.fabricObj.renderAll()
          break;
        case "color":
          console.log("色彩选择");
          break;
        case "camera":
          this.fabricM1.moveTo(0);
          this.fabricM1.bringToFront();
          

          // console.log('选择事件');
          this.fabricObj.selection = true;
          this.fabricObj.skipTargetFind = false;
          this.fabricObj.selectable = true;
          this.fabricM9.set({
              selectable: true,
              evented: true,
              lockMovementX: false,
              lockMovementY: false
          });
          this.fabricM1.set({
              selectable: true,
              evented: true,
              lockMovementX: false,
              lockMovementY: false
          });
          this.fabricObj.renderAll();
          // this.fabricObj.setActiveObject(this.fabricM9);
          
          break;
        case "remove":
          this.fabricObj.selection = true;
          this.fabricObj.skipTargetFind = false;
          this.fabricObj.selectable = true;

          this.fabricM9.set({
            selectable: false,
            evented: false,
            lockMovementX: true,
            lockMovementY: true,
            hasControls: false,
            hasBorders: false,
            hoverCursor: 'default'
          });
          this.fabricM9.sendToBack();
          this.fabricObj.renderAll();
          break;
        case "reset":
          this.mods = -1;

          this.changeFabricBg();
          break;
        case "redo":
          this.redo();
          break;
        case "undo":
          this.undo();
          break;
        case "equilaenlargeteral":
          console.log('放大')
          if(this.AgoraPerview){
            this.$message.closeAll()
            this.$message.warning('课件动画演示，无法放大');
            return
          }else{
            this.resizeFabricBackground(true)
          }
          break;
        case "narrow": 
          if(this.AgoraPerview){
            this.$message.closeAll()
            this.$message.warning('课件动画演示，无法缩小');
            return
          }else{
            this.resizeFabricBackground(false)
          }
          // console.log('缩小')
        default:
          break;
      }
    },
    //绘制文字对象
    drawText() {
      console.log('开始绘制文字对象');
      this.textboxObj = new fabric.Textbox(" ", {
        left: this.mouseFromX,
        top: this.mouseFromY,
        width: 220,
        fontSize: this.drawWidth*16,
        fill: this.drawColor,
        hasControls: true,
      });
      this.fabricObj.add(this.textboxObj);
      this.textboxObj.enterEditing();
      this.textboxObj.hiddenTextarea.focus();
      this.updateModifications(true);
    },
    drawing() {
      if (this.drawingObject) {
        this.fabricObj.remove(this.drawingObject);
      }
     
      // console.log(this.currentTool);
      let fabricObject = null;
      this.fabricObj.selection = false;
      this.fabricObj.skipTargetFind = false;
      this.fabricObj.selectable = false;
      switch (this.currentTool) {
        case "pencil":
        // console.log('pencil!!!!');
          // console.log(22222222222);
          if(this.SelectMenu==2){
            this.fabricObj.isDrawingMode = true;
          }
          break;
        case "line":
          console.log('LINE!!!!');
          fabricObject = new fabric.Line(
            [
              this.mouseFromX,
              this.mouseFromY,
              this.mouseTo.x,
              this.mouseTo.y,
            ],
            {
              stroke: this.drawColor,
              strokeWidth: this.drawWidth,
            }
          );
          break;
        case "arrow":
          fabricObject = new fabric.Path(
            this.drawArrow(
              this.mouseFromX,
              this.mouseFromY,
              this.mouseTo.x,
              this.mouseTo.y,
              15.5,
              15.5
            ),
            {
              stroke: this.drawColor,
              fill: "rgba(255,255,255,0)",
              strokeWidth: this.drawWidth,
            }
          );
          break;
        case "dashedline":
          // doshed line
          fabricObject = this.drawDoshedLine();
          break;
        case "rectangle":
          // 矩形
          fabricObject = this.drawRectangle();
          break;
        case "circle": //正圆
          fabricObject = this.drawCircle();
          break;
        case "equilaenlargeteral":
          console.log('放大')
          break;
        case "narrow": //等边三角形
          // console.log('缩小')
          break;
        case "remove":
          break;
        default:
          // statements_def'
          break;
      }
      if (fabricObject) {
        // console.log(fabricObject);
        this.$nextTick(() => {
          this.fabricObj.add(fabricObject);
          this.drawingObject = fabricObject;
        });
      }
      // if(this.FabricUP){
      //   console.log(this.fabricObj);
      //   this.fabricTargetList.push(this.fabricObj)

      //   this.FabricUP = false
      // }
    },
    // dashed line
    drawDoshedLine() {
      console.log('drawDoshedLine');
      return new fabric.Line(
        [this.mouseFromX, this.mouseFromY, this.mouseTo.x, this.mouseTo.y],
        {
          strokeDashArray: [10, 3],
          stroke: this.drawColor,
          strokeWidth: this.drawWidth,
        }
      );
    },
    // circle 圆形
    drawCircle() {
        let radius = Math.sqrt(
            (this.mouseTo.x - this.mouseFromX) * (this.mouseTo.x - this.mouseFromX) +
            (this.mouseTo.y - this.mouseFromY) * (this.mouseTo.y - this.mouseFromY)
        ) / 2;
        
        return new fabric.Circle({
            top: Math.min(this.mouseFromY, this.mouseTo.y),
            left: Math.min(this.mouseFromX, this.mouseTo.x),
            stroke: this.drawColor,
            fill: "rgba(255, 255, 255, 0)",
            radius: radius,
            strokeWidth: this.drawWidth,
            // 禁止移动和选中的属性
            selectable: true,        // 禁止选中
            evented: true,          // 禁止事件响应
            lockMovementX: true,     // 锁定X轴移动
            lockMovementY: true,     // 锁定Y轴移动
            hasControls: false,      // 禁止控制点
            hasBorders: false,       // 禁止边框
            hoverCursor: 'default'   // 鼠标样式为默认
        });
    },
    // triangle
    drawTriangle() {
      let height = this.mouseTo.y - this.mouseFromY;
      return new fabric.Triangle({
        top:Math.min(this.mouseFromY,this.mouseTo.y),
        left:Math.min(this.mouseFromX,this.mouseTo.x),
        width: Math.sqrt(Math.pow(height, 2) + Math.pow(height / 2.0, 2)),
        height: height,
        stroke: this.drawColor,
        strokeWidth: this.drawWidth,
        fill: "rgba(255,255,255,0)",
      });
    },
    // ellipse
    drawEllipse() {
      let left = this.mouseFromX;
      let top = this.mouseFromY;
      // let ellipse = Math.sqrt((this.mouseTo.x - left) * (this.mouseTo.x - left) + (this.mouseTo.y - top) * (this.mouseTo.y - top)) / 2;
      return new fabric.Ellipse({
        left: left,
        top: top,
        stroke: this.drawColor,
        fill: "rgba(255, 255, 255, 0)",
        originX: "center",
        originY: "center",
        rx: Math.abs(left - this.mouseTo.x),
        ry: Math.abs(top - this.mouseTo.y),
        strokeWidth: this.drawWidth,
      });
    },
    // rectangle  矩形
    drawRectangle() {
      return new fabric.Rect({
        left: Math.min(this.mouseFromX,this.mouseTo.x),
        top: Math.min(this.mouseFromY,this.mouseTo.y),
        width:Math.abs(this.mouseTo.x - this.mouseFromX),
        height:Math.abs(this.mouseTo.y - this.mouseFromY),
        fill: "rgba(255, 255, 255, 0)",
        stroke: this.drawColor,
        strokeWidth: this.drawWidth,
        // 禁止移动和选中的属性
        selectable: true,        // 禁止选中
        lockMovementX: true,     // 锁定X轴移动
        lockMovementY: true,     // 锁定Y轴移动
        // hasControls: false,      // 禁止控制点
        // hasBorders: false,       // 禁止边框
        hoverCursor: 'default'   // 鼠标样式为默认
      });
    },
    //箭头
    drawArrow(fromX, fromY, toX, toY, theta, headlen) {
      theta = typeof theta != "undefined" ? theta : 30;
      headlen = typeof theta != "undefined" ? headlen : 10;
      let angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI,
        angle1 = ((angle + theta) * Math.PI) / 180,
        angle2 = ((angle - theta) * Math.PI) / 180,
        topX = headlen * Math.cos(angle1),
        topY = headlen * Math.sin(angle1),
        botX = headlen * Math.cos(angle2),
        botY = headlen * Math.sin(angle2);
      let arrowX = fromX - topX,
        arrowY = fromY - topY;
      let path = " M " + fromX + " " + fromY;
      path += " L " + toX + " " + toY;
      arrowX = toX + topX;
      arrowY = toY + topY;
      path += " M " + arrowX + " " + arrowY;
      path += " L " + toX + " " + toY;
      arrowX = toX + botX;
      arrowY = toY + botY;
      path += " L " + arrowX + " " + arrowY;
      return path;
    },
  },
  watch: {
    async SelectMenu(newVal, oldVal) {
      if (oldVal == 3 && newVal == 2) {
        console.log("屏幕分享切换到课件");
        this.EndSharingScreen()
      }
      if (oldVal == 3 && newVal == 1) {
        console.log("屏幕分享切换到摄像头");
        this.EndSharingScreen();
      }
      if (newVal == 3) {
        // console.log(this.fabricM1);
      
        try {
          this.changeM1Potion();

        } catch (error) {
          
        }
        try {
          this.fabricM1.moveTo(0)
        } catch (error) {
          
        }
        
        this.removeFabricChildren()
        this.fabricObj.isDrawingMode = false;
        this.fabricObj.selection = false;
        this.fabricObj.skipTargetFind = false;
        this.fabricObj.selectable = false;
        if(this.CameraStatus){
          this.m1Left = this.fabricM1.left;
          this.m1Top = this.fabricM1.top;
        }
        this.fabricM1.set({
              selectable: true,
              evented: true,
              lockMovementX: false,
              lockMovementY: false
          })
        this.fabricObj.renderAll();
        // this.fabricObj.discardActiveObject(this.fabricM1)
        // let container = this.$refs.VueDragResize.$el.querySelector('.content-container')
        // container.appendChild(this.$refs.cameraStream)
      }
      if (newVal == 2) {
          await this.EndSharingScreen()
          this.shareDPStatus = false;
          await this.changeFabricBg();
          this.handleTools({name:'pencil'}, 0)
          try {
            this.changeM1Potion()
          } catch (error) {
            
          }
          
          try {
            this.fabricM1.moveTo(0);
            
          } catch (error) {
            
          }
          if(this.lianmai.state){
            console.log('正在连麦中。。。。');
          }
          this.fabricObj.discardActiveObject(this.fabricM1);
          try {
            if(this.CameraStatus){
              this.m1Left = this.fabricM1.left;
              this.m1Top = this.fabricM1.top;
            }
          
          } catch (error) {
            
          }
         
      }
      if (newVal == 1) {
        
        console.log('切换到摄像头画面');
        // this.removeFabricChildren()
        if(this.CameraStatus){
          this.m1Left = this.fabricM1.left;
          this.m1Top = this.fabricM1.top;
        }else{
          this.fabricM1.set('opacity', 1);
        }
        this.fabricM1.set({
            selectable: false,
            evented: false,
            lockMovementX: false,
            lockMovementY: false
        });
        await this.revertM1Potion();
        await this.fabricM1.bringToFront();
        

        this.fabricObj.discardActiveObject(this.fabricM1)

        this.fabricObj.isDrawingMode = false;
        this.fabricObj.selection = false;
        this.fabricObj.skipTargetFind = false;
        this.fabricObj.selectable = false;
        this.fabricObj.renderAll();
        console.log('禁止摄像头被选中和移动')
      }

     
      // if (oldVal == 1 && newVal == 3) {
      //   // 再次将摄像头流对象渲染到video
      //   setTimeout(() => {
      //     this.$refs.cameraStream.srcObject = this.LocalCameraStream;
      //     this.$refs.cameraStream.play();
      //   }, 300);
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
#anchor{
  position:absolute;
  top:-200vh;
  width: 1920px;
  height: 1080px

}
.P2P{
  position: absolute;
    z-index: 3333;
    top: 10%;
    left: 30%;
}
#cameraStream{
  width: 192px;
  height: 108px;
  position: absolute;
  top: -310vh;
  z-index: 1;
  object-fit: cover;
}
.tr180{
  transform: rotateY(180deg) !important;
}
#window-list {
  height: 60px;
  position: absolute;
  z-index: 5;
  bottom: -11vh;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-left: 10px;
  // width: 1080px;
  width: 98%;
  justify-content: space-between;
  .changeShareDP{
    position: absolute;
    top: -2vw;
    z-index: 99999;
    left:10px;
    border-radius: 0 !important;
  }
  .TransportBox {
    display: flex;
    flex-wrap: wrap;
    margin-left: 14px;
    text-align: left;
    width: 100px;

    span {
      width: 100%;
    }
  }

  .WeakTips {
    color: red;
  }

  .liveTitle {
    font-size: 16px;
    margin-left: 14%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .flexcenter {
    display: flex;
    align-items: center;
  }

  .redLine {
    width: 2px;
    height: 32px;
    position: absolute;
    left: 24px;
    transform: rotate(59deg);
    top: -4px;
    background: #fb4747;
  }

  .windowSvg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 12px;
    justify-content: center;
    color: #999;
    position: relative;
  }

  .cameraSvg {
    width: 50px;
    margin-left: 20px;

    img {
      width: 26px;
    }
  }

  .mikeSvg {
    width: 50px;
    flex-wrap: wrap;
    margin-left: 10px;

    img {
      display: block;
      width: 26px;
    }

    p {
      width: 100%;
    }
  }

  .settingSvg {
    width: 30px;
    margin-left: 210px;
    margin-right: 60px;

    img {
      width: 100%;
    }
  }

  p {
    margin-top: 4px;
  }
}

.WinBox {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.move-box {
  position: fixed;
  bottom: 80px;
  right: 50px;
  width: 200px;
  height: 112px;
  border-radius: 6px;
  background: linear-gradient(45deg, rgb(174, 79, 252), rgb(137, 64, 253));
  box-shadow: 0 0 30px rgb(43, 58, 73);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  user-select: none;
}

.text {
  text-align: center;
  margin-top: 8rem;
  font-weight: bold;
  font-size: 1.3rem;
  color: #262626;
}

.skeleton {
  width: 1434px;
  height: 738px;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999999;
}

#RemoteStream {
  width: 180px;
  height: 100px;
  position: absolute;
  z-index: 9999;
  left: 43%;
  border: 1px solid;

  .lianmaiMenu {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    margin-left: 9px;

    .lianmaiTips {
      padding: 4px 2px 4px 4px;
      position: absolute;
      left: -9px;
      top: 0;
      height: 26px;
      color: #f2f2f2;
      font-size: 12px;
      background-image: linear-gradient(to bottom, rgb(70 183 249 / 70%), rgb(85 119 167 / 50%));
      align-items: center;
      border-radius: 0 0 14px 0;
    }

    .MenuBox {
      position: absolute;
      bottom: 4px;

      .grey {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        width: 28px;
        height: 28px;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .offLianmai {
      position: absolute;
      background: red;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      bottom: 4px;
      right: 20px;

      img {
        width: 20px;
      }
    }
  }

  ::v-deep div {
    display: flex !important;
  }

  .ringOff {
    width: 24px;
    position: absolute;
    left: 45%;
    top: 30px;
    z-index: 3;
  }
}

.el-color-picker__trigger .el-icon-arrow-down:before {
  content: "\e671" !important;
  font-size: 18px;
}

.el-color-picker__trigger {
  border: none;
}

.el-color-picker__color {
  border: none;
}

.colorPicker {
  position: absolute;
  left: 14.3%;
  top: 257%;
  z-index: 999;
}

.MissingCourseware {
  position: fixed;
  left: 35%;
  top: 19%;
  z-index: 999;
}

.CoursewareManagement {
  position: fixed;
  left: 42%;
  top: 61%;
  z-index: 999;
}

.el-dropdown-link {
  display: block;
  margin-left: 16px;
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.CloseLiveBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .close {
    display: flex;
    flex-wrap: wrap;
    background: #ff6c67;
    color: #fff;
    justify-content: center;
    align-items: center;
    width: 250px;
    border-radius: 10px;
    padding: 10px 0;
    margin-top: 20px;
  }

  .goback {
    display: flex;
    flex-wrap: wrap;
    color: #ff6c67;
    justify-content: center;
    align-items: center;
    width: 250px;
    border-radius: 10px;
    padding: 10px 0;
    border: 1px solid #ff6c67;
  }

  p {
    font-size: 16px;
    width: 100%;
    margin: 0;
  }

  span {
    font-size: 12px;
  }
}

.home {
  background: #f9f9f9;
  display: flex;
  flex-wrap:nowrap;
  overflow: hidden;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;


}

.fastboard-page-control {
  color: #5d6066;
  background-color: rgba(255, 255, 255, 0.9);
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px;
  border: 1px solid #e5e8f0;
  border-radius: 4px;
  font-size: 14px;
  font-family: system-ui;
  pointer-events: auto;
  backdrop-filter: blur(5px);
  // .el-input{
  //   width: 20px;
  // }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  ::v-deep input[type="number"]{
      -moz-appearance: textfield;
  }

  ::v-deep .el-input__inner{
    height: 20px;
    // line-height: 20px;
    padding: 0 2px;
    width: 30px;
  }
}

.fastboard-bottom-right {
  position: absolute;
  // bottom: 14vh !important;
  // top: 45vw !important;
  bottom: -5vh;
  left: 35vw;
  z-index: 899;
}

.fastboard-icon {
  width: 30px;
  height: 30px;
}

.fastboard-page-control-btn {
  appearance: none;
  cursor: pointer;
  margin: 0;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: #0000;
  border-radius: 4px;
  font-size: 0;
  line-height: 1;
  flex-shrink: 0;
}

.fastboard-page-control-text {
  font-variant-numeric: tabular-nums;
}

.fastboard-page-control * {
  box-sizing: inherit;
}

.title_box {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 60px;
  background: #fff;
  justify-content: center;
  position: relative;

  .fnc_box {
    position: absolute;
    right: 2%;
    display: flex;
    align-items: center;

    .timeDifference {
      color: #ff4d4d;
      margin-right: 4px;
    }

    .setting {
      width: 24px;
      height: 24px;
      margin-left: 18px;
    }
  }

  .name_box {
    display: flex;
    width: 200px;
    color: #000;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -80px;

    p {
      width: 100%;
      display: block;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 800;
      margin-bottom: 4px;
    }

    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 17px;
      color: #999999;
    }
  }

  .number {
    width: 16px;
    height: 16px;
  }
}

.Live-menu {
  display: flex;
  flex-wrap: wrap;
  // width: 80px;
  width: 5vw;
  background: red;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #30323d;
  // border-right: 1px solid #4c4c4c;
  height: 100vh;

  .menuLi {
    width: 100%;
    height: 70px;
    padding: 8px 0;
    color: #999;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    svg {
      color: #999;
      width: 70%;
    }

    p {
      width: 100%;
      display: block;
      // color: #999;
    }
  }
}

.checkMenu {
  // background: #000 !important;
  color: #0066ff !important;
}

.topbar-right-btn {
  display: block;
  // margin-right:21.4% ;
  display: block;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 4px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;

  overflow: hidden;
  // border-radius: 8px;
}

.ChatRoom {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  .title {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #e5e8f0;
    border-top: 2px solid #e5e8f0;
  }

  .text {
    color: #383b42;
    font-size: 14px;
    font-weight: 600;
  }

  .number {
    font-size: 12px;
    font-weight: 400;
    color: #5d6066;
  }

  .ChatMessage {
    display: flex;
    height: 600px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.video_box {
  position: relative;
  width: 100%;
  height: 150px;

  .Blur {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .closeCamera {
    position: absolute;
    right: 14%;
    bottom: 4px;
  }

  .closeSound {
    position: absolute;
    right: 4%;
    bottom: 4px;
  }

  .Camera {
    position: absolute;
    right: 14%;
    bottom: 4px;
    background: #efefef !important;
  }

  .Sound {
    position: absolute;
    right: 4%;
    bottom: 4px;
    background: rgba(0, 0, 0, 0.2);
  }

  .flat-icon {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
  }

  .flat-icon-c {
    border-radius: 50%;
  }
}

.videoArea {
  display: flex;
  flex: 1;
  // height: 85vh;
  // width: 1160px;
  width: 100%;
  height: 85vh;
  position: relative;
  justify-content: space-between;
  #CameraOff {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30vh;
    left: 26vw;

    svg {
      width: 60px;
      height: 100px;
    }

    p {
      margin: 0;
      width: 100%;
    }
  }

 
  .z2{
    z-index: 2 !important;
  }
  .z4{
    z-index: 4 !important;
  }
  #ShareHome {
    .el-empty {
      justify-content: flex-start !important;
      margin-left: 30vw;
    }
  }

  #CameraHome {
    background: #f2f2f2;
    display: block;

    img {
      width: 80%;
      object-fit: contain;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;

  }

 
}
.right {
    // width: 264px;
    width: 15vw;
    // border-left: 1px solid #e5e8f0;
    position: relative;
    z-index: 2;
    overflow-y: hidden;
    padding-bottom: 6px;
    // height: 774px;
    .closeLianmai{
      position: absolute;
      top: calc(79vh - 16px);
      z-index: 333;
      right: 14%;
    }
  }
.shareDP {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.dpname {
  display: block;
  margin-top: 5px;
  padding: 0 4px;
  font-size: 14px;
  text-align: center;
  color: #c1bcbc;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}

.showDP_dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;

  ::v-deep .el-dialog {
    margin: 0 auto !important;
    height: 80%;
    overflow: hidden;
    background: #30323d;
    border-radius: 20px;

    .el-dialog__header {
      background: #30323d;
    }

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      color: #ffffff;
      background: #30323d;

      line-height: 30px;
      padding: 0 0;
      margin-bottom: 100px;
    }

    .el-dialog__footer {
      position: absolute;
      border-top: 1px solid #e5e5e5cc;
      // padding: 10px 0px 20px;
      padding-top: 10px;
      height: 60px;
      display: flex;
      align-items: center;
      bottom: 10px;
      width: 100%;

      .el-checkbox__label {
        color: #c1bcbc !important;
      }
    }

    .dialog-footer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding-top: 16px;

      .shareJudge {
        display: flex;
        align-items: center;
        margin-left: 5%;

        div {
          margin-right: 20px;
        }
      }

      button {
        margin-right: 5%;
      }
    }

    .el-dialog__title {
      color: #c1bcbc;
    }
  }
}

.dpshow {
  display: flex;
  flex-wrap: wrap;
}

.shareDPLi {
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid #fff;
  cursor: pointer;
  width: 240px;
  height: 192px;
  display: inline-flex;
  margin-right: 16px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  color: #fff;
  background: #3e414a;

  img {
    width: 200px;
    height: 125px;
  }
}

.DPactive {
  border: 2px solid #2e76ee;
}
.canvas-wraper {
   z-index: 3;
   width: 100% !important;
   height: 100% !important;
  //  width: 1160px;
  //  height: 622.5px;
}
.showTips {
    color: #000;
    // width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    left: 1vw;
    // top: 45.5vw;
    bottom: -4vh;

    img {
      margin-right: 4px;
      width: 14px;
    }
  }
.wraper {
  position: absolute;
  // min-width: 1106px;
  // width: 80vw;
  width: 100%;
  display: flex;
  background: #fff;
  // left: 80vw;
  flex: 1;
  height: 100%;
  justify-content: flex-start;
  .controlPanel {
    // width: 880px;
    height: 62px;
    // background: #ddd;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: absolute;
    z-index: 4;
    right: 16px;
    top: 10px;
    background: rgba(62, 65, 74, 0.39);
    opacity: 0.7;

    .contro-item {
      // flex-basis: 100px;
      width: 40px;
      height: 40px;
      border-right: 1px solid #dad7d9;
      text-align: center;
      cursor: pointer;
      background: #fefefe;
      align-items: center;
      justify-content: center;
      display: flex;

      i,
      img {
        font-size: 18px;
        line-height: 62px;
        width: 14px;
        padding: 14px 0;
      }

      &.active {
        background: #e34f51;
        color: #fff;
        // border-radius: 3px;
        align-items: center;
        justify-content: center;

        i {
          font-size: 18px;
        }
      }
    }
  }
  .controlPanelSize{
    position: absolute;
    width: 30px;
    background: rgba(62, 65, 74, 0.39);
    opacity: 0.7;
    left: -30px;
    .SizeBox{
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 4px;
    }
    .size{
      background: #000;
      border-radius: 50%;
      margin: 0 auto;
    }
    .s10{
      width: 4px;
      height: 4px;
    }
    .s16{
      width: 6px;
      height: 6px;
    }
    .s20{
      width: 8px;
      height: 8px;
    }
  }
  .download {
    img {
      width: 100%;
    }
  }
}

#playerBox {
  position: absolute;
  z-index: 1;
  top: -300vh !important;
  // width: 1920px;
  // height: 1080px;
}

// .location1 {
//   left: 0;
//   top: -100vh;
// }

// .location2 {
//   left: 10vw !important;
//   top: -100vh;
// }

// .location3 {
//   // left: 68px !important;
//   // top: 27px !important;
//   left: 0px !important;
//   top: -76px;
//   width: 78%;
// }

::v-deep .ant-btn-primary,
.el-button--primary {
  background-color: #0076ff;
  border-color: #0076ff;
}

#canvas {
  // width: 1160px  !important;
  // width: 80vw !important;
  //  height: 45vw !important;
  // height: 622.5px !important;
  width: 100% !important;
  height: 100% !important;
}

::v-deep .canvas-container {
  // width: 1106px  !important;
  // height: 622.5px !important;
  width: 100% !important;
  height: 100% !important;
}

::v-deep .upper-canvas {
    // width: 1106px !important;
    // height: 622.5px !important;
    width: 100% !important;
    height: 100% !important;
}
</style>

