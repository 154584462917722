<template>
  <div class="box">
    <!-- @open="openDialog" -->
    <el-dialog title="设置" :modal="false" :visible.sync="dialogVisible" :closeOnClickModal="false" width="780px"
      height="500px">
      <div class="xuanxiang">
        <div class="option left">
          <ul>
            <li v-for="(item, index) in options" :key="index" @click="selectOption(index)"
              @mouseover="hoverOption(index)" @mouseleave="hoverOption(null)" :class="{
                selected: selectedIndex === index,
                hover: hoveredIndex === index && selectedIndex !== index,
              }">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="right">

          <div class="clarity" v-if="selectedIndex == 0">
            <div>
              <span>请选择清晰度:</span>
              <el-dropdown :hide-on-click="true" :disabled="liveStatus">
                <span class="el-dropdown-link">
                  {{ profileNmae[profileIndex] }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="checkLiveprofile(0)">标清</el-dropdown-item>
                  <el-dropdown-item @click.native="checkLiveprofile(1)">高清</el-dropdown-item>
                  <el-dropdown-item @click.native="checkLiveprofile(2)">超清</el-dropdown-item>
                  <el-dropdown-item @click.native="checkLiveprofile(3)">蓝光</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <p style="margin-top: 10px;text-align: left;color: red;font-size: 14px;">{{ textList[profileIndex] }}</p>
              <span style="text-align: left;color: red;font-size: 12px;"
                v-if="profileIndex == 2">超清直播对网络流畅度、稳定性要求较高，如果推流出现卡顿、黑屏等现象，请降低清晰度！</span>
              <span style="text-align: left;color: red;font-size: 12px;"
                v-if="profileIndex == 3">对网络流畅度、稳定性要求较高，如果推流出现卡顿、黑屏等现象，请降低清晰度！</span>
            </div>
            <el-divider content-position="left">推流设置</el-divider>
            <div style="display: flex;flex-wrap: wrap;margin-bottom: 18px;">
              <span style="width: 100%;">请选择推流方式:</span>

              <el-radio-group v-model="PushType" style="margin-top: 20px;" @change="PushTypeChange()">
                <el-radio :label="0" fill="ffffff">TCP</el-radio>
                <el-radio :label="1">UDP</el-radio>
              </el-radio-group>
              <p v-if="platform !== 'darwin'" style="margin-top: 10px;text-align: left;color: red;font-size: 14px;
              width: 100%;">{{ PushTypeTips[PushType] }}</p>

            </div>


          </div>

          <div class="camera" v-if="selectedIndex == 1">
            <div class="camera-check-info">
              <div style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <video v-if="VideoOptions.length > 0" width="240" height="135" style="object-fit: cover"
                  ref="cameraStream" id="cameraStream" autoplay></video>
                <div style="font-size: 16px; letter-spacing: 1px" v-else>
                  未检测到摄像头
                </div>
              </div>
            </div>
            <span style="margin-right: 4px;font-size: 16px;">摄像头:</span>
            <el-select v-model="VideoValue" placeholder="请选择" class="VideoSelect" @change="VideoChange">
              <el-option v-for="item in VideoOptions" :key="item.deviceId" :label="item.label" :value="item.deviceId">
              </el-option>
            </el-select>
            <br>
            <el-button @click="ChangeHomeCamera()" style="margin-top: 16px;float: left;" type="primary"
              plain>切换摄像头</el-button>
          </div>
          <div class="audio" v-if="selectedIndex == 2">
            <div class="select_box">
              <div class="text">麦克风</div>
              <div style="text-align: left;margin-bottom: 26px;display: flex;margin-top: 22px;">
                <span style="margin-right: 10px;color: #000;">麦克风音量</span>

                <el-slider style="width: 240px;margin-top: -10px;" v-model="MicrophoneVolume" :clickable="true"
                  tooltip="none" :min="0" :max="200" @change="MicrophoneVolumeChange"></el-slider>
              </div>
              <el-divider content-position="left">音频降噪</el-divider>
              <el-select v-model="AudioValue" placeholder="请选择" @change="AudioChange" class="AudioSelect">
                <el-option v-for="item in AudioOptions" :key="item.deviceId" :label="item.label" :value="item.deviceId">
                </el-option>
              </el-select>
              <div style="text-align: left; margin-top: 20px">
                <el-switch v-model="RNNoiseSwitch" @change="RNNoiseChange">
                </el-switch>
                <span style="margin-left: 6px">开启音频降噪</span>
                <div style="width: 400px;">


                  <p style="font-size: 14px; margin: 8px 0 0 0; color: #f70000">
                    请勿频繁开关降噪功能
                  </p>
                  <p style="font-size: 14px; margin: 2px 0 0 0; color: #f70000">
                    降噪功能输出为单声道，取样率48000Hz，如您的设备不满足要求或输出声道数量不符合要求，请勿开启此功能
                  </p>
                  <p style="font-size: 14px; margin-top: 2px; color: #f70000">
                    针对背景噪声进行降噪，如果使用外放设备，请关闭降噪功能
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="beauty" v-show="selectedIndex == 3">
            <div class="beauty-portrait">
              <span v-for="(item, index) in beautyOptions" :key="index" @click="selectBeautyOption(index)"
                :class="{ selected: selectedBeautyIndex === index }">
                {{ item }}
              </span>
            </div>
            <div class="portrait-box" v-if="selectedBeautyIndex == 0">
              <div class="switch-box">
                <el-switch v-model="switchBeautyPortrait" @change="BeautyPortraitChange" :disabled="RandomMemory < 9">
                </el-switch>
                <span style="margin-left: 4px">开启美颜</span>
                <!-- <span style="margin-left: 8px;color: #f70000;">美颜功能暂时下线修复...</span> -->
                <span style="margin-left: 8px;color: #f70000;" v-if="RandomMemory < 5">运行内存过低，暂不支持使用美颜功能</span>
              </div>
              <div class="portrait-list">
                <div class="portraitOption" v-for="(item, index) in portraitOptions" :key="index"
                  @click="selectPortraitOption(index)" :class="{ selected: portraitIndex === index }">
                  <img :src="getImageSrc(index)" alt="" />
                  <p>{{ item }}</p>
                </div>
                <div class="tips">更多特效开发中...</div>
              </div>
              <div class="slider-box" v-if="portraitIndex !== null">
                <el-slider
                :show-tooltip="false"
                 v-model="sliderValue" :min="0" :max="100" :format-tooltip="calculateOutputValue" @change="sliderChange"></el-slider>
              </div>
              <!-- <div style="position: relative;"> -->

              <!-- </div> -->
            </div>
            <div style="margin-top: 14px" v-if="selectedBeautyIndex == 1">
              功能暂未上线
            </div>
            <div style="margin-top: 14px" v-if="selectedBeautyIndex == 2">
              功能暂未上线
            </div>
            <video id="webcam" style="position: absolute; opacity: 0" autoplay playsinline></video>
            <!-- <canvas class="output_canvas" ref="output_canvas" id="output_canvas" style="position: absolute; left: 206px; top: 242px;"></canvas> -->
            <div id="canvas-container" v-show="BeautyRunning" ref="canvasContainer"></div>
          </div>

          <div class="about" v-if="selectedIndex == 4">
            <div class="logo">
              <div>
                <img src="../assets/img/logo.png" alt="" />
              </div>
              <div class="version">
                <span>讯课直播助手</span>
                <span>版本号：{{ VersionName }}</span>
              </div>

            </div>
            <div class="feedback">
              <!-- <span>检查更新</span> -->
              <span @click="goIxunke()">进入官网</span>
              <span @click="goWiki()">帮助中心</span>
            </div>
            <div class="copyright">Copyright @2016-2023 迅课科技</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'

const Store = window.require("electron-store");
const store = new Store();
// import vision from "../assets/tasks-vision@0.10.3";
var FaceLandmarker, FilesetResolver, DrawingUtils
// -------------------------------------------------------------
var scene, camera, renderer, geometry, material, plane, video;
var videoTexture;
var PI172 = { x: 0, y: 0 };
var PI4 = { x: 0, y: 0 };
var PI397 = { x: 0, y: 0 };
var PI132 = { x: 0, y: 0 };
var PI361 = { x: 0, y: 0 };
var PI197 = { x: 0, y: 0 };
var PI234 = { x: 0, y: 0 };
var PI454 = { x: 0, y: 0 };
// 定义顶点着色器
var vertexShader = `
      precision highp float; 
      attribute vec2 uv;
      uniform mat4 projectionMatrix;
      uniform mat4 modelViewMatrix;
      attribute vec3 position;
      varying vec2 vUv; // 声明一个输出的varying变量

      void main() {
          vUv = uv; // 将顶点的uv值赋值给vUv

          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
  `;
// 定义片元着色器
var fragmentShader = `
    precision highp float; 
    uniform sampler2D map;
    uniform vec2 PI172;
    uniform vec2 PI4;
    uniform vec2 PI397;
    uniform vec2 PI132;
    uniform vec2 PI361;
    uniform vec2 PI197;
    uniform vec2 PI234;
    uniform vec2 PI454;
    uniform vec2 resolution; // 图像分辨率
    uniform float sigmaSpace; // 空间高斯标准差
    uniform float sigmaColor; // 色彩高斯标准差
    uniform float whitening;
    uniform float thinfaceValue;
    varying vec2 vUv;
    precision highp float;
    vec2 curveWarp(vec2 currentCoordinate, vec2 originPosition, vec2 targetPosition, float scaleRatio) {
        // 形变需要添加的平移向量
        vec2 direction = targetPosition - originPosition;
        // 计算原始纹理坐标到目标点的距离, 平移作用圆半径rMax(这里使用的是原始圆心和目标圆心的距离作为半径)
        float rMax = distance(targetPosition, originPosition);
        // 计算当前纹理坐标到眼睛中心的距离，对应 r
        float r = distance(currentCoordinate, originPosition);
        // 缩放比例
        float ratio = (1.0 - r / rMax) * scaleRatio * 0.2;
        // float ratio = pow(1.0 - r / rMax, 2.0);
        // 仅对半径作用范围内的像素点生效
        if (r < rMax){
            //    ratio = clamp(ratio, 0.0, 1.0);
            // 变形前点 + 缩放系数 * direction = 变形后的点
            // 新采样点 = 旧采样点 - 缩放系数 * direction
            currentCoordinate = currentCoordinate - direction * ratio;
        }
        return currentCoordinate;
    }
    vec2 thin_face(vec2 currentCoordinate){
        lowp float intensity_12_28 = 0.7;  
        float liftFaceIntensity = thinfaceValue; 
        vec2 targetPoint = PI172 + (PI4 - PI172) * intensity_12_28;
        currentCoordinate = curveWarp(currentCoordinate, PI172, targetPoint, liftFaceIntensity);
        targetPoint = PI397 + (PI4 - PI397) * intensity_12_28;
        currentCoordinate = curveWarp(currentCoordinate, PI397, targetPoint, liftFaceIntensity);
        
        lowp float intensity_15_31 = 0.5;
        targetPoint = PI132 + (PI197 - PI132) * intensity_15_31;
        currentCoordinate = curveWarp(currentCoordinate, PI132, targetPoint, liftFaceIntensity);
        targetPoint = PI361 + (PI197 - PI361) * intensity_15_31;
        currentCoordinate = curveWarp(currentCoordinate, PI361, targetPoint, liftFaceIntensity);
        targetPoint = PI234 + (PI197 - PI234) * intensity_15_31;
        currentCoordinate = curveWarp(currentCoordinate, PI234, targetPoint, liftFaceIntensity);
        targetPoint = PI454 + (PI197 - PI454) * intensity_15_31;
        currentCoordinate = curveWarp(currentCoordinate, PI454, targetPoint, liftFaceIntensity);
        
        return currentCoordinate;
    }
    // 双边滤波高斯函数
    float gaussian(float x, float sigma) {
        return exp(-(x * x) / (2.0 * sigma * sigma));
    }
    void main() {
        vec2 uv = thin_face(vUv);
        vec4 texColor = texture2D(map, uv); 
        vec3 cColor = texture2D(map, uv).rgb; // 使用调整后的uv坐标获取中心颜色
        vec3 finalColor = vec3(0.0);  
        float totalWeight = 0.0;

        const int kernelWidth = 11;
        const float kernelRadius = float(kernelWidth) / 2.0;

        for (int i = -kernelWidth / 2; i <= kernelWidth / 2; i++) {
            for (int j = -kernelWidth / 2; j <= kernelWidth / 2; j++) {
                vec2 offset = vec2(float(i), float(j)) / resolution;
                vec3 sampleColor = texture2D(map, uv + offset).rgb;
                float spaceWeight = gaussian(distance(vec2(float(i), float(j)), vec2(0.0, 0.0)), sigmaSpace);
                float colorWeight = gaussian(distance(sampleColor, cColor), sigmaColor);
                float weight = spaceWeight * colorWeight;
                finalColor += sampleColor * weight;
                totalWeight += weight;
            }
        }
        float whiteningIntensity = whitening; // 可以调整这个值以控制美白的程度
        vec3 finalWhitenedColor = min(finalColor / totalWeight + vec3(whiteningIntensity), vec3(1.0)); // 应用美白效果
        gl_FragColor = vec4(finalWhitenedColor, 1.0); // 使用调整后的颜色值
        
        
        
        // gl_FragColor = vec4(finalColor / totalWeight, 1.0);
      }
    `;
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
const { ipcRenderer } = window.require("electron");

const { shell } = window.require('electron')
export default {
  data() {
    return {

      platform: null,
      PushType: 0,
      PushTypeTips: ['更稳定', '低延迟，对网络要求高'],
      liveStatus: false, //直播状态
      dialogVisible: false,
      options: [
        "常规设置",
        // "讲师窗口设置",
        "视频",
        "音频",
        "虚拟背景和美颜",
        // "网络检测",
        "关于我们",
      ],
      beautyOptions: ["美颜", "滤镜", "虚拟背景"],
      portraitOptions: ["磨皮", "美白", "瘦脸"],
      portraitValue: {
        dermabrasion: 42,
        whitening: 50,
        thinface: 63,
      },

      whiteningValue: 0.1, //美白参数
      sigmaColorValue: 0.05, //磨皮参数
      thinfaceValue: 0.5, //瘦脸参数

      portraitIndex: null,
      selectedBeautyIndex: 0,
      switchBeautyPortrait: false, //开启美颜
      selectedIndex: 0,
      hoveredIndex: null,
      profileNmae: ["标清", "高清", "超清", "蓝光"],
      textList: ['960x540分辨率', '1280x720分辨率', '1920x1080分辨率', '视频8000码率'],
      profileIndex: 1, //默认视频输出配置

      VideoOptions: [],
      VideoValue: "",
      AudioOptions: [],
      AudioValue: "",
      audiooutput: [], //麦克风列表
      AudioOutputValue: "",
      volumeInterval: null,
      sliderValue: 0,
      currentList: ["dermabrasion", "whitening", "thinface"],
      BeautyRunning: false, //美颜实例运行状态
      RNNoiseSwitch: false, //降噪开关

      dialogVisible: false,
      modeCheck: 1,
      widthPercentage: 0.22,
      HeightPercentage: 0.367,

      width: 140,
      height: null,
      top: 0,
      left: 0,
      x: 560 - 140,
      y: null,
      DragKey: 0,
      flag: false,
      hiddenFlag: false,

      SizeTimeoutId: null,
      RandomMemory: 0,         //运行内存 G
      loadRNNoiseStatus: false,  //是否加载降噪文件

      RNNContext: null,      //降噪音频上下文\

      MicrophoneVolume: 200, //麦克风音量大小
    };
  },
  props: ["AudioStream"],
  components: {
    // VueSlider,
  },
  computed: {
    getImageSrc() {
      return (index) => {
        let imageName = this.currentList[index];
        return require(`@/assets/img/${imageName}.png`);
      };
    },
  },
  methods: {
    async getUpdateData(flag) {
      try {
        await axios.get('https://cloud.ixunke.com/api/config/live_push_client')
          .then(response => {
            // console.log(response.data)
            this.UpData = response.data;
            console.log(this.UpData);
          })
          .catch(error => {
            console.log(error)
          })
        let LocalBuildID = store.get("buildID");

        let CloudBuildID = Number(this.UpData.buildId);

        if (LocalBuildID < CloudBuildID && this.UpData.updateType == "force") {
          console.log('客户端强制需要升级');

          this.$notify({
            title: '检测到新版本',
            type: 'warning',
            message: '请返回首页升级客户端',
            position: 'bottom-right',
            showClose: false,
            duration: 0,
          });
        } else {
          console.log("不需要升级");
        }
        // console.log(this.UpData);
      } catch (error) {
        console.log("获取升级接口出错", error);
      }
    },
    MicrophoneVolumeChange(v) {
      let Volume = v;
      console.log(Volume);
      store.set('MicrophoneVolume', Volume);
      this.$emit('changeMicrophoneVolume', Volume)
    },
    ChangeHomeCamera() {
      this.$emit('ChangeHomeCamera', this.VideoValue)
    },

    PushTypeChange() {

      console.log(this.PushType);
      store.set('PushType', this.PushType);
      this.$message.success('切换成功');
      this.$emit('ChangePushType', this.PushType)
    },
    async loadVisionModule() {

      try {
        const vision = await import("../assets/tasks-vision@0.10.3");
        FaceLandmarker = vision.FaceLandmarker;
        FilesetResolver = vision.FilesetResolver;
        DrawingUtils = vision.DrawingUtils;
        // 使用模块进行其他操作
      } catch (error) {
        console.error("Failed to load the vision module", error);
      }

    },
    loadRNNoise() {
      // 定义基础路径和编译WebAssembly模块的异步函数
      // 定义基础路径
      const compilation = (WebAssembly.compileStreaming || (async f => await WebAssembly.compile(await (await f).arrayBuffer())))(fetch("https://cdn.ixunke.cn/client/wasm/rnnoise-processor.wasm"));

      // const compilation = (async () => {
      //   const buffer = await fs.readFile(wasmFilePath);
      //   return WebAssembly.compile(buffer);
      // })();
      console.log(compilation);
      console.log("-------compilation-----------");
      let module, instance, heapFloat32;
      window.RNNoiseNode =
        ((window.AudioWorkletNode ||
          (window.AudioWorkletNode = window.webkitAudioWorkletNode)) &&
          class extends AudioWorkletNode {
            static async register(context) {
              module = await compilation;
              try {
                console.log('加载processor');
                await context.audioWorklet.addModule(
                  "http://localhost:10818/rnnoise-processor.js"
                );
              } catch (error) {
                console.error(error)
              }

            }

            constructor(context) {
              super(context, "rnnoise", {
                channelCountMode: "explicit",
                channelCount: 1,
                channelInterpretation: "speakers",
                numberOfInputs: 1,
                numberOfOutputs: 1,
                outputChannelCount: [1],
                processorOptions: {
                  module: module,
                },
              });
              this.port.onmessage = ({ data }) => {
                const e = Object.assign(new Event("status"), data);
                this.dispatchEvent(e);
                if (this.onstatus) this.onstatus(e);
              };
            }

            update(keepalive) {
              this.port.postMessage(keepalive);
            }
          }) ||
        ((window.ScriptProcessorNode ||
          (window.ScriptProcessorNode = window.webkitScriptProcessorNode)) &&
          Object.assign(
            function (context) {
              const processor = context.createScriptProcessor(512, 1, 1),
                state = instance.newState();
              let alive = true;
              processor.onaudioprocess = ({ inputBuffer, outputBuffer }) => {
                if (alive) {
                  heapFloat32.set(
                    inputBuffer.getChannelData(0),
                    instance.getInput(state) / 4
                  );
                  const o = outputBuffer.getChannelData(0),
                    ptr4 = instance.pipe(state, o.length) / 4;
                  if (ptr4) o.set(heapFloat32.subarray(ptr4, ptr4 + o.length));
                }
              };
              processor.update = (keepalive) => {
                if (alive) {
                  if (keepalive) {
                    const e = Object.assign(new Event("status"), {
                      vadProb: instance.getVadProb(state),
                    });
                    processor.dispatchEvent(e);
                    if (processor.onstatus) processor.onstatus(e);
                  } else {
                    alive = false;
                    instance.deleteState(state);
                  }
                }
              };
              return processor;
            },
            {
              register: async () => {
                if (!instance)
                  heapFloat32 = new Float32Array(
                    (instance = (
                      await WebAssembly.instantiate(await compilation)
                    ).exports).memory.buffer
                  );
              },
            }
          ));

      this.loadRNNoiseStatus = true;
    },
    async RNNoiseChange() {
      console.log(this.RNNoiseSwitch);
      if (this.RNNoiseSwitch) {
        this.rnnoiseStart();
      } else {
        this.rnnoiseStop();
      }
    },
    async rnnoiseStop() {
      this.RNNContext.close();
      this.$emit('StopRNNoise')
      this.RNNoiseSwitch = false;
    },
    async rnnoiseStart() {
      const _this = this;
      if (!this.loadRNNoiseStatus) {
        await this.loadRNNoise();
      }

      // 检查浏览器是否支持必要的音频处理功能
      const sink = Audio.prototype.setSinkId;
      console.log(sink, 'sink');
      // 创建一个新的音频上下文
      this.RNNContext = new AudioContext({ sampleRate: 48000 });
      try {
        // 创建一个新的音频目标节点
        const destination = sink
          ? new MediaStreamAudioDestinationNode(this.RNNContext, {
            channelCountMode: "explicit",
            channelCount: 1,
            channelInterpretation: "speakers",
          })
          : context.destination;
        if (sink) {
          setTimeout(() => {
            console.log(destination.stream);
            this.$emit("RNNoiseStream", destination?.stream);
          }, 100);

        }

        // return
        const stream = this.AudioStream.clone();
        console.log(stream);
        await RNNoiseNode.register(this.RNNContext);

        const source = this.RNNContext.createMediaStreamSource(stream);
        const rnnoise = new RNNoiseNode(this.RNNContext);
        // 将噪声抑制节点连接到目标节点，并将源节点连接到噪声抑制节点
        rnnoise.connect(destination);
        source.connect(rnnoise);

        // 添加噪声抑制节点的状态更新事件处理函数
        rnnoise.onstatus = (data) => {
          console.log(1);
          // console.log(data.vadProb * 100);
          // vadProb.style.width = data.vadProb * 100 + "%";
        };
        // 创建一个动画帧回调函数，用于更新噪声抑制节点的状态
        function a() {
          if (_this.RNNoiseSwitch) {
            requestAnimationFrame(() => {
              rnnoise.update(true);
              a();
            });
          }
        }
        a()
      } catch (e) {
        // 如果出现错误，则关闭音频上下文，并在控制台输出错误信息
        this.RNNContext.close();
        console.error(e);
      }

      // 启用开始按钮
    },
    BeautyPortraitChange(newVal) {
      // console.log(newVal);
      if (!newVal) {
        this.$emit("CloseBeauty");
        this.BeautyRunning = false
      } else {
        if (!this.BeautyRunning) {
          this.$message("正在开启美颜");
          this.startBeauty();
        } else {
          return false;
        }
      }
    },
    changeModeCheck() {
      this.dialogVisible = true;
    },
    selectOption(index) {
      this.selectedIndex = index;
    },
    hoverOption(index) {
      this.hoveredIndex = index;
    },
    checkLiveprofile(index) {
      console.log(this.profileNmae);
      let t = this.profileNmae[index];

      this.profileIndex = index;
      store.set('profileIndex',index)
      this.$emit('checkLiveprofile', index)
    },
    VideoChange(e) {
      // console.log(e);
      this.getCamera(e);
    },
    async getCamera(id) {
      try {
        this._cameraStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: id,
            facingMode: "environment",
            width: {
              min: 1280,
              ideal: 1920,
              max: 1920
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1080
            }
          },
        });
        this.$message.closeAll()
        try {
          this.$refs.cameraStream.srcObject = this._cameraStream;
          this.$refs.cameraStream.play();
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        this.$message.error('获取摄像头失败,请检查摄像头是否被占用')
      }
    },
    async AudioChange(id) {
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
      try {
        this._cameraStream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.log(error);
      }
      this.detect = 2;
      const audioCtx = new AudioContext();
      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 2048;
      this._audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: id,
          channelCount: { ideal: 1 },
          noiseSuppression: { ideal: false },
          echoCancellation: { ideal: true },
          autoGainControl: { ideal: false },
          sampleRate: { ideal: 48000 },
        },
      });
      const sourceNode = audioCtx.createMediaStreamSource(this._audioStream);
      sourceNode.connect(analyserNode);

      this.volumeInterval = setInterval(() => {
        // 获取频谱数据
        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserNode.getByteFrequencyData(dataArray);
        // 计算声贝值
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }
        this.volume = sum / bufferLength;
        // console.log( this.volume/50);
        try {
          this.$refs.audioVolume.style.width = (this.volume / 50) * 100 + "%";
        } catch (error) {
          clearInterval(this.volumeInterval);
        }
      }, 300);
    },
    sliderChange(value, index) {
      console.log(value);
    },
    calculateOutputValue(inputValue) {
      // 检查输入值是否在 0-100 的区间内
      if (inputValue < 0 || inputValue > 100) {
        throw new Error("输入值必须在 0 到 100 之间");
      }

      // 计算输入值在输入区间内的比例
      const inputRange = 100;
      const inputRatio = inputValue / inputRange;

      // 计算输出区间的范围
      const outputRangeMin = 0.001;
      const outputRangeMax = 0.2;
      const outputRange = outputRangeMax - outputRangeMin;

      // 根据输入比例计算输出值
      const outputValue = outputRangeMin + inputRatio * outputRange;

      // 返回计算结果，保留 3 位小数
      return Number(outputValue.toFixed(3));
    },
    calculate2(inputValue) {
      // 计算输入值在输入区间内的比例
      const inputRange = 100;
      const inputRatio = inputValue / inputRange;

      // 计算输出区间的范围
      const outputRangeMin = 0.001;
      const outputRangeMax = 0.12;
      const outputRange = outputRangeMax - outputRangeMin;

      // 根据输入比例计算输出值
      const outputValue = outputRangeMin + inputRatio * outputRange;

      // 返回计算结果，保留 3 位小数
      return Number(outputValue.toFixed(3));
    },
    calculate3(inputValue) {
      // 计算输入值在输入区间内的比例
      const inputRange = 100;
      const inputRatio = inputValue / inputRange;

      // 计算输出区间的范围
      const outputRangeMin = 0.001;
      const outputRangeMax = 0.8;
      const outputRange = outputRangeMax - outputRangeMin;

      // 根据输入比例计算输出值
      const outputValue = outputRangeMin + inputRatio * outputRange;

      // 返回计算结果，保留 3 位小数
      return Number(outputValue.toFixed(3));
    },
    selectPortraitOption(index) {
      console.log(index);
      if (!this.switchBeautyPortrait) {
        return false;
      }
      this.portraitIndex = index;
      let currentList = ["dermabrasion", "whitening", "thinface"];
      // 记录当前选中的选项
      const currentKey = currentList[index];

      this.sliderValue = this.portraitValue[currentKey];
    },
    selectBeautyOption(index) {
      this.selectedBeautyIndex = index;
      //   if (index == 0 && this.switchBeautyPortrait) {
      //     console.log("开始美颜");
      //     this.startBeauty();
      //   }
    },
    async startBeauty() {
      if (this.BeautyRunning) {
        return false;
      }
      const _this = this;

      let faceLandmarker;
      let runningMode = "VIDEO"; // 运行模式，可以是"IMAGE"或"VIDEO"
      let enableWebcamButton;
      let webcamRunning = false;
      const videoWidth = 480;
      const videoBlendShapes = document.getElementById("video-blend-shapes");
      // 创建面部标记器实例
      //   "https://cdn.ixunke.cn/client/wasm"
      async function createFaceLandmarker() {
        const filesetResolver = await FilesetResolver.forVisionTasks(
          "http://localhost:10818"
        );
        faceLandmarker = await FaceLandmarker.createFromOptions(
          filesetResolver,
          {
            baseOptions: {
              modelAssetPath: "http://localhost:10818/face_landmarker.task",
              delegate: "GPU",
            },
            outputFaceBlendshapes: true,
            runningMode,
            numFaces: 1,
          }
        );
      }
      await createFaceLandmarker();
      setTimeout(() => {
        enableCam();
        console.log("打开摄像头");
      }, 100);
      // 获取视频和画布元素
      video = document.getElementById("webcam");

      // 检查浏览器是否支持getUserMedia
      function hasGetUserMedia() {
        return !!(
          navigator.mediaDevices && navigator.mediaDevices.getUserMedia
        );
      }

      // 启用网络摄像头并开始检测
      function enableCam(event) {
        if (!faceLandmarker) {
          console.log("等待加载faceLandmarker...");
          return;
        }

        // if (webcamRunning === true) {
        // webcamRunning = false;
        // enableWebcamButton.innerText = "打开摄像头";
        // } else {
        // webcamRunning = true;
        // enableWebcamButton.innerText = "关闭摄像头";
        // }

        // getUserMedia参数
        let device;
        try {
          device = store.get('device')[0]
        } catch (error) {
          device = 'default'
        }

        console.log(device);
        const constraints = {
          video: {
            deviceId: device,
            facingMode: "environment",
            width: {
              min: 1280,
              ideal: 1920,
              max: 1920
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1080
            }
          }
        };

        // 激活网络摄像头流
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          video.srcObject = stream;
          //   console.log(video);
          video.addEventListener("loadeddata", () => {
            _this.initThreeJS(video.videoWidth, video.videoHeight);
            predictWebcam();
          });
        });
      }
      //   setTimeout(() => {
      //     enableCam();
      //     console.log("打开摄像头");
      //   }, 2000);
      let lastVideoTime = -1;
      let results = undefined;
      // const drawingUtils = new DrawingUtils(canvasCtx);
      async function predictWebcam() {
        // console.log(video.videoHeight);
        const radio = video.videoHeight / video.videoWidth;
        video.style.width = videoWidth + "px";
        video.style.height = videoWidth * radio + "px";
        // canvasElement.style.width = videoWidth + "px";
        // canvasElement.style.height = videoWidth * radio + "px";
        // canvasElement.width = video.videoWidth;
        // canvasElement.height = video.videoHeight;

        // 开始检测视频流
        if (runningMode === "IMAGE") {
          runningMode = "VIDEO";
          await faceLandmarker.setOptions({ runningMode: runningMode });
        }
        let startTimeMs = performance.now();
        if (lastVideoTime !== video.currentTime) {
          lastVideoTime = video.currentTime;
          results = faceLandmarker.detectForVideo(video, startTimeMs);
        }
        let tmpCanvas = document.getElementById("BeautyCanvas");
        // 设置canvas的长宽
        tmpCanvas.classList.add("BeautyCanvasStyle");

        if (results.faceLandmarks) {
          _this.BeautyRunning = true;
          for (const landmarks of results.faceLandmarks) {
            let list = [PI4, PI132, PI172, PI197, PI234, PI361, PI397, PI454];
            let key = [4, 132, 172, 197, 234, 361, 397, 454];
            for (let index = 0; index < list.length; index++) {
              let x1 = landmarks[key[index]].x;
              let y1 = 1 - landmarks[key[index]].y;
              // console.log(list[index]);
              list[index].x = x1;
              list[index].y = y1;
            }
          }
        }
        // drawBlendShapes(videoBlendShapes, results.faceBlendshapes);

        // 如果网络摄像头正在运行，则继续预测
        if (webcamRunning === true) {
          window.requestAnimationFrame(predictWebcam);
        }
      }

      function drawBlendShapes(el, blendShapes) {
        if (!blendShapes.length) {
          return;
        }
      }
    },
    initThreeJS(videoWidth, videoHeight) {
      const _this = this;
      // 创建Three.js场景
      scene = new THREE.Scene();

      // 根据视频尺寸调整相机参数
      const aspectRatio = videoWidth / videoHeight;
      const frustumSize = videoHeight;
      const frustumHalfWidth = (frustumSize * aspectRatio) / 2;

      camera = new THREE.OrthographicCamera(
        -frustumHalfWidth,
        frustumHalfWidth,
        frustumSize / 2,
        -frustumSize / 2,
        1,
        1000
      );

      renderer = new THREE.WebGLRenderer();
      renderer.setSize(1920, 1080);
      renderer.domElement.id = "BeautyCanvas"; // 设置id
      renderer.domElement.ref = "BeautyCanvas";
      document.getElementById("canvas-container").innerHTML = "";
      document
        .getElementById("canvas-container")
        .appendChild(renderer.domElement);

      videoTexture = new THREE.VideoTexture(video);
      videoTexture.minFilter = THREE.LinearFilter;
      videoTexture.magFilter = THREE.LinearFilter;

      // 创建平面几何体,使用视频的宽高作为尺寸
      geometry = new THREE.PlaneGeometry(videoWidth, videoHeight);
      // material = new THREE.MeshBasicMaterial({ map: videoTexture });
      this.whiteningValue = _this.calculateOutputValue(
        _this.portraitValue.whitening || 50
      );
      this.sigmaColorValue = _this.calculate2(
        _this.portraitValue.dermabrasion || 50
      );
      this.thinFaceValue = _this.calculate3(_this.portraitValue.thinface || 50);
      material = new THREE.RawShaderMaterial({
        uniforms: {
          map: { value: videoTexture },
          PI172: { value: new THREE.Vector2(PI172.x, PI172.y) },
          PI4: { value: new THREE.Vector2(PI4.x, PI4.y) },
          PI397: { value: new THREE.Vector2(PI397.x, PI397.y) },
          PI132: { value: new THREE.Vector2(PI132.x, PI132.y) },
          PI361: { value: new THREE.Vector2(PI361.x, PI361.y) },
          PI197: { value: new THREE.Vector2(PI197.x, PI197.y) },
          PI234: { value: new THREE.Vector2(PI234.x, PI234.y) },
          PI454: { value: new THREE.Vector2(PI454.x, PI454.y) },
          resolution: { value: new THREE.Vector2(videoWidth, videoHeight) },
          sigmaSpace: { value: 30.0 },
          sigmaColor: { value: this.sigmaColorValue },
          whitening: { value: this.whiteningValue },
          thinfaceValue: { value: this.thinfaceValue },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
      });

      plane = new THREE.Mesh(geometry, material);
      // plane.scale.x = -1; // 将网格沿x轴缩放为负值即可实现水平翻转

      scene.add(plane);

      // 设置相机的视锥体
      camera.left = -videoWidth / 2;
      camera.right = videoWidth / 2;
      camera.top = videoHeight / 2;
      camera.bottom = -videoHeight / 2;
      camera.updateProjectionMatrix();

      camera.position.z = 100;

      // 开始渲染循环
      this.animate();
      this.$emit("BeautyDone");
    },
    animate() {
      requestAnimationFrame(this.animate);
      material.uniforms.PI172.value.set(PI172.x, PI172.y);
      material.uniforms.PI4.value.set(PI4.x, PI4.y);
      material.uniforms.PI397.value.set(PI397.x, PI397.y);
      material.uniforms.PI132.value.set(PI132.x, PI132.y);
      material.uniforms.PI361.value.set(PI361.x, PI361.y);
      material.uniforms.PI197.value.set(PI197.x, PI197.y);
      material.uniforms.PI234.value.set(PI234.x, PI234.y);
      material.uniforms.PI454.value.set(PI454.x, PI454.y);
      material.uniforms.whitening.value = this.whiteningValue;
      material.uniforms.sigmaColor.value = this.sigmaColorValue;
      material.uniforms.thinfaceValue.value = this.thinfaceValue;
      videoTexture.needsUpdate = true;

      renderer.render(scene, camera);
    },
    async openDialog() {
      if (this.DragKey !== 0) {
        return;
      }
      this.lengthWidthRatio = await Number(store.get("lengthWidthRatio"));
      // console.log(this.lengthWidthRatio, "this.lengthWidthRatio");

      this.$nextTick(() => {
        this.height = 140 / this.lengthWidthRatio;
        // console.log(this.height);
        this.y = 315 - this.height;
        this.DragKey++;
      });
    },
    changeModeCheck() {
      this.dialogVisible = true;
    },

    goIxunke() {
      shell.openExternal('https://www.ixunke.com')
        .then(() => console.log('打开链接成功'))
        .catch((err) => console.error('打开链接失败', err));
    },
    goWiki() {
      shell.openExternal('https://www.ixunke.com/wiki')
        .then(() => console.log('打开链接成功'))
        .catch((err) => console.error('打开链接失败', err));
    }
  },
  async created() {

    this.platform = store.get('platform');
    try {
      this.profileIndex = store.get('profileIndex')||1;
    } catch (error) {
      this.profileIndex = 1
    }
    store.set("modeCheck", 1);
    this.VersionName = store.get('VersionName')
    // console.log(this.VersionName);
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.VideoOptions = devices.filter(
        (device) => device.kind === "videoinput"
      );
      // this.getCamera();
      if (this.VideoOptions.length > 0) {
        this.DefaultvideoDevices = this.VideoOptions[0].deviceId;
        this.VideoValue = this.VideoOptions[0].deviceId;
      }

      // 麦克风
      this.audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      this.audioDevices = Object.values(
        this.audioDevices.reduce((acc, obj) => {
          if (!acc[obj.groupId]) {
            acc[obj.groupId] = obj;
          }
          return acc;
        }, {})
      );
      this.AudioOptions = this.audioDevices;
      store.set("AudioDeviceNumber", this.AudioOptions.length);
      this.AudioValue = this.AudioOptions[0].deviceId;

      this.audiooutput = devices.filter(
        (device) => device.kind === "audiooutput"
      );
      // console.log(devices);
      // console.log(this.audiooutput);
      this.audiooutput = Object.values(
        this.audiooutput.reduce((acc, obj) => {
          if (!acc[obj.groupId]) {
            acc[obj.groupId] = obj;
          }
          return acc;
        }, {})
      );
      this.AudioOutputValue = this.audiooutput[0].deviceId;
    });
  },
  watch: {
    modeCheck(newVal, oldVal) {
      // console.log(newVal);
      this.width = 200;
      this.height = 116;
      if (newVal == 1) {
        this.x = 560 - 220 + 20;
        this.y = 315 - 116;
      } else if (newVal == 2) {
        this.x = 0;
        this.y = 315 - 116;
      } else if (newVal == 3) {
        this.x = 0;
        this.y = 0;
      } else if (newVal == 4) {
        this.x = 560 - 220 + 20;
        this.y = 0;
      }
      store.set("modeCheck", newVal);
      // console.log(store.get());
      this.flag = true;
    },
    sliderValue(newVal, oldVal) {
      let currentList = ["dermabrasion", "whitening", "thinface"];
      // 记录当前选中的选项
      const currentKey = currentList[this.portraitIndex];
      this.portraitValue[currentKey] = newVal;
      if (currentKey == "whitening") {
        this.whiteningValue = this.calculateOutputValue(newVal);
      } else if (currentKey == "dermabrasion") {
        this.sigmaColorValue = this.calculate2(newVal);
      } else if (currentKey == "thinface") {
        this.thinfaceValue = this.calculate3(newVal);
      }
    },
  },
  async mounted() {
    try {
      this.MicrophoneVolume = store.get('MicrophoneVolume') || 200
    } catch (error) {
      console.log('获取默认麦克风音量失败');
    }
    // 在组件挂载后的生命周期钩子中动态加载 Three.js 库
    let totalmem = await window?.OS?.totalmem()
    if (totalmem > 0) {
      const RandomMemory = (totalmem / 1024 / 1024 / 1024).toFixed(1)
      this.RandomMemory = RandomMemory
      // console.log(RandomMemory,'------RandomMemory-------');
      if (RandomMemory > 7) {
        const script = document.createElement("script");
        script.src = "https://cdn.ixunke.cn/client/js/three.min.js";
        script.onload = () => {
          // Three.js 库加载完成后的回调函数
          // this.initThree();
        };
        document.head.appendChild(script);
        // console.log('WASMServer');
        await ipcRenderer.send('WASMServer')
        this.loadVisionModule()


      }
    }

    this.PushType = store.get('PushType') || 0;

    this.lengthWidthRatio = await Number(store.get("lengthWidthRatio"));
    // console.log(this.lengthWidthRatio, "this.lengthWidthRatio");

    this.$nextTick(() => {
      this.height = 140 / this.lengthWidthRatio;
      // console.log(this.height);
      this.y = 315 - this.height;
      this.DragKey++;
    });
    try {
      if (this.platform == 'win32') {
        this.showSetting = false;
        let OSVersion = store.get('OSVersion') || '10';
        function getNumberBeforeDecimal(str) {
          const match = str.match(/^\d+/);
          return match ? match[0] : null;
        }
        OSVersion = Number(getNumberBeforeDecimal(OSVersion))
        if (OSVersion < 10) {
          console.log('WIN7/8 不需要升级');
          return
        }
        this.getUpdateData()
      }
    } catch (error) {
      console.log('检测升级失败');
    }
  },
  beforeDestroy() {
    this.$notify.closeAll()
  }
};
</script>

<style lang='scss' scoped>
.setting_box {
  display: flex;
  flex-wrap: wrap;
  color: #606266;
  align-content: flex-start;

  .text1 {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    width: 100%;
    height: 24px;
    text-align: left;
  }

  ::v-deep .vdr.active {
    background: #777d97;
    border: 2px solid #0076ff;
    z-index: 2;
  }

  ::v-deep .vdr.active:before {
    outline: none !important;
  }

  ::v-deep .el-dialog {
    background: #414351;
    opacity: 1;
    border-radius: 20px;
    overflow: hidden;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .el-dialog__footer {
    display: flex;
    justify-content: center;
  }

  ::v-deep .el-dialog__body {
    padding: 30px 50px;
  }

  .controlSpace {
    width: 50%;
  }

  .background {
    background: #55586a;
    width: 560px;
    height: 315px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .rotate180 {
    padding-top: 15%;
  }

  #rotate270 {
    transform: rotate(270deg);
  }

  #rotate90 {
    transform: rotate(90deg);
  }

  #rotate0 {
    // transform: rotate(90deg);
  }

  .c1 {
    bottom: 0 !important;
    right: 0 !important;
  }

  .c2 {
    bottom: 0 !important;
    left: 0 !important;
  }

  .c3 {
    top: 0 !important;
    left: 0 !important;
  }

  .c4 {
    right: 0 !important;
    top: 0 !important;
  }

  .CameraArea {
    background: #777d97;
    overflow: hidden;
    resize: both;
    position: absolute;

    width: 90px;
    height: 90px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 2px solid #0076ff;

    p {
      width: 100%;
      margin-bottom: 0;
    }

    span {
      font-size: 10px;
    }
  }

  .modeCheckClass {
    border: 4px solid #004edd;
  }

  .mode_box {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: space-around;
  }
}

.about {
  width: 100%;

  .copyright {
    font-size: 12px;
    margin-top: 110px;
  }

  .feedback {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 120px;
    margin: 50px auto 0 auto;

    span {
      width: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #0076ff;
      color: #0076ff;
      font-size: 14px;
      margin-top: 16px;
    }
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    flex-wrap: wrap;
    margin-left: 46px;

    img {
      width: 60px;
    }

    .version {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      /* justify-content: center; */
      align-items: center;
      margin-left: 8px;

      span {
        width: 100%;
      }
    }

    p {
      margin-top: 8px;
      font-style: 14px;
      width: 100%;
    }
  }
}

::v-deep .BeautyCanvasStyle {
  width: 360px !important;
  height: 202.5px !important;
  margin-top: 10px;
}

.AudioSelect {
  display: flex;
}

.box {
  color: #fff;
  font-size: 14px;
}

#canvas-container {
  canvas {
    width: 320px !important;
    height: 240px !important;
  }
}

.beauty {
  .beauty-portrait {
    display: flex;

    span {
      display: inline-block;
      padding: 8px 16px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
    }

    span.selected {
      background-color: #ecf3ff;
      border-color: #1472ff;
    }
  }

  .portrait-box {
    .switch-box {
      margin-top: 20px;
      text-align: left;
    }

    .slider-box {
      margin-top: 20px;

      ::v-deep .vue-slider-process {
        background-color: #147bff;
      }
    }

    .portrait-list {
      display: flex;
      margin-top: 26px;

      .tips {
        margin-left: 20px;
        display: flex;
        align-items: center;
      }

      .portraitOption {
        display: flex;
        flex-wrap: wrap;
        width: 54px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        margin-right: 10px;
        border: 2px solid #f5f7fa;
        background: #f5f7fa;
        border-radius: 6px;
        justify-content: center;

        img {
          width: 20px;
          margin-top: 6px;
        }

        p {
          margin-top: -12px;
          width: 100%;
        }
      }

      .selected {
        border: 2px solid #147bff !important;
      }
    }
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #ebebf2;

  .el-dialog__title {
    font-size: 14px;
  }
}

::v-deep .el-dialog__body {
  padding: 0;
}

.xuanxiang {
  width: 100%;
  display: flex;
  height: 470px;
}

.right {
  padding: 8px 6px 0 16px;
  display: flex;
  flex: 1;

  .clarity {
    font-size: 16px;
    text-align: left;
  }

  .camera {
    .camera-check-info {
      width: 230px;
      height: 136px;
      margin-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #fafafa;
    }
  }

  .audio {
    .select_box {

      // display: flex;
      // align-items: center;
      // margin: 30px 0;
      .text {
        color: #000;
        font-size: 14px;
        margin-right: 8px;
        margin-bottom: 8px;
        text-align: left;
      }
    }
  }
}

.el-dropdown-link {
  display: block;
  margin-left: 16px;
  cursor: pointer;
  color: #999;
  border: 1px solid #d9d9d9;
  padding: 2px 6px;
}

.left {
  width: 190px;
  height: 100%;
  border-right: 1px solid #ebebf2;
}

.option {
  ul {
    padding: 8px 6px 0 6px;
  }

  .selected {
    background-color: #1177ff;
    color: #fff;
  }

  .hover {
    background-color: #eef2f8;
  }

  li {
    font-size: 16px;
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin: 0 0 2px 0;
    border-radius: 10px;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>